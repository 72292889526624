import { Component, OnInit, HostListener, ChangeDetectionStrategy, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DndDropEvent, DropEffect } from 'ngx-drag-drop';
import { DatacatchingService } from "../../services/datacatching.service";
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import Swal from 'ngx-angular8-sweetalert2';
import { THEME, StepChangedArgs, NgWizardService } from 'ng-wizard';
import { UntypedFormGroup,  UntypedFormBuilder, UntypedFormArray, Validators, FormArray } from '@angular/forms';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { leadsInputsList } from '../../data/leads';
import { fieldModels, eventInfo, destinationOptions, listOptions, listRegex, listNormalizator, listDealers, oneDealer, temporisationTimesList,smsEmailOptions,notifcenterOptions,notifEmailTemplateOptions } from '../../data/eventfields';
import { configForm, attributeForm } from '../../data/forms';
import { ENV } from 'src/app/core/env.config';
import { decode } from 'html-entities';
declare var $: any;
interface Logo {
  name: string;
  value: string;
  checked: boolean;
}

@Component({
  selector: 'app-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventComponent implements OnInit, OnDestroy,AfterViewInit {
  
  Autre='Autre'
  @ViewChild('inputFile') fileInput: ElementRef;
  @ViewChild('bannerFile') bannerInput: ElementRef;
  @ViewChild('legalesFile') legalesFile: ElementRef;
  isDisplayVendeur: boolean = false;
  isProcessingFile: boolean = false;
  isDisplayCampagne: boolean = false;
  models: any;
  base64Image: any;
  selectedConfig: Array<any> = [];
  value: any = {
    label: "",
    value: ""
  };
  success = false;
  // isMobile = false;
  excelfileToUpload: File = null;
  affaireExcelFileName: string = null;
  public isExistDr: boolean = true;
  public selectedRegex: any;
  public base64String;
  public listCarsModeleWish: any = [];
  public listCarsWish: any = [];
  public listModeleWish: any = [];
  public listBrandWishSort: any = [];
  public listCarsModeleEner: any = [];
  public listCarsInterest: any = [];
  public listModelInterest: any = [];
  public listEner: any = [];
  public listEnerWish: any = [];
  public listBrandIntSort: any = [];
  public listModelIntSort: any = [];
  public pdf_file_path: string = null;
  public isSaveorEdit: boolean = false;
  public isActionLogo: boolean = false;
  public isEditBannerImg: boolean = false;
  public invalidDateTimeTempo = false;
  public myForm: UntypedFormGroup;
  public extraFiltersUrl: string = "";
  public isExtraFilters: boolean = this.extraFiltersUrl.length ? true : false;
  public dealerUrl: String = "";
  public isAccordToggle: boolean = false;
  public filtersList = [];
  public files: any[];
  public isHours: boolean = false;
  public isHourMinute: boolean = false;
  public isDateHour: boolean = false;
  public hoursMinutesVal;
  public isValidLogoWidth: boolean = true;
  public isValidBannerImg: boolean = false;
  public selectedBannerFile: File;
  public logoUrl: SafeStyle;
  public originalPdfFileName: string = "";
  public fileNameToDownload = '';
  public logosGlobaList = [];
  public exisitngLogoNames = [];
  public tempLogoListtoDelete = [];
  public temporisationTimesList: any = temporisationTimesList;
  public minDateTempo;

  public leadsInputsList: any = leadsInputsList;
  regexTransaltions: any = [];
  regexCounties: any = [];
  chartesList:any = [];
  chartesListValues = [];
  destinationOptions = destinationOptions;
  listOptions = listOptions;
  smsEmailOptions = smsEmailOptions;
  notifcenterOptions=notifcenterOptions;
  listRegex = listRegex; 
  listNormalizator = listNormalizator;
  notifEmailTemplateOptions= notifEmailTemplateOptions;
  listDealers = listDealers;
  oneDealer = oneDealer;
  fieldModels = fieldModels;
  eventInfo = eventInfo; 
  modelFields: Array<any> = [];
  model: any = {

    name: "",
    description: "",
    isMasterEvent: false,
    logo: "",
    eventInfo: [],
    theme: {
      bgColor: "ffffff",
      textColor: "555555",
      bannerImage: null
    },
    attributes: this.modelFields

  }
  public tempName = this.model.name;
  public tempDescription = this.model.description;
  public dealer = 'Choisissez un dealer';
  public report = false;
  public reports: any = [];
  public dealerConfigArr = [];
  public userName: any;
  public isEdit: boolean = false;
  public isUnDealer: boolean = false;
  public isFichierExcel: boolean = false;
  public isFichierExcelOffline: boolean = false;
  public isListdealers: boolean = false;
  public isApiwired: boolean = false;
  public isNodealer: boolean = false;
  public myTitle = this.isEdit ? 'Modifier un évènement ' : 'Créer un évènement';
  public configForm: UntypedFormGroup;
  public masterEvents: any;
  public selectedArray: any;
  public configVal1;
  public configVal2;
  public configVal3;
  public objAttributeForm = {};
  public isConfig: boolean = false;
  public logoForm: UntypedFormGroup;
  public attributeForm: UntypedFormGroup;
  public eventInfoForm: UntypedFormGroup;
  public leadList: any = [];
  public leadListForm: any = [];
  public bool: boolean = false;
  public fileDealer: any;
  public fileCars: any;
  public fileCarsWish: any;
  public uploaded: boolean = false;
  public submitted: boolean = false;
  public submittedAttr: boolean = false
  public previewLogo: any = [];
  public imgURL: SafeStyle;
  public errorBanner: boolean = false;
  public minDate = undefined;
  public mindateEnd = undefined;
  public isDisabledEnd: boolean = true;
  public editmode: any;
  public dateTempo: Date;
  public hourMinute = "";
  public tempoCheck: Boolean = false
  public submittedConfig: boolean = false
  public screenHeight;
  public screenWidth;
  public isTablet: boolean = false;
  public selectedFile: File;
  public selectedLegalesFile: File;
  public legalesFilesPath: string = '';
  public testUrl = "";
  public isLogoSubmit: boolean = true;
  public islogoNewSubmit: boolean = false;
  public listLogo = [];
  public islogoFileSelected: boolean = false;
  public isFormLoaded: boolean = false;
  public pdfFile: any;
  public tempDrs: any = [];
  public isMultidealers: boolean = false;
  public isPhoneAndEmailExist: boolean = false;
  public globalLogoNames = [];
  translationsLanguages = [];
  dropdownList = [];
  dropdownListTemplates = []
  selectedItems = [];
  selectedTemplateItems = [];
  dropdownSettingsTemplate:any = {};
  dropdownSettings: any = {};
  reseau: any = [];
  isProcessing:boolean = false;
  is_dealer_event: boolean = false;
  isNotificationChecked:boolean = false;
  isNotifSmsOrEmail:boolean = false;

  is_dealer_event_show_lead:boolean = false;
  constructor(private datacatchingService: DatacatchingService,
    private toastr: ToastrService, private ref: ChangeDetectorRef,
    private router: Router, private ngWizardService: NgWizardService,
    private formBuilder: UntypedFormBuilder,
    private sanitizer: DomSanitizer, private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute
  ) {
    this.getTranlstions();
  
    const current = new Date();
    this.transfromDate(new Date().toLocaleString())
    this.minDate = this.datePipe.transform(current, "yyyy-MM-dd")
    this.mindateEnd = this.datePipe.transform(current, "yyyy-MM-dd")
    this.screenHeight = window.screen.height;
    this.screenWidth = window.screen.width;
    this.minDateTempo = new Date().toISOString().substring(0, 16);
    this.activatedRoute.queryParams.subscribe(async params => {
      let isTokenNotExpired = await this.datacatchingService.TokenValid();
      console.log("params", params )
      if (params['id']) {
        let id = params['id'];
        this.onEdit(id);
        sessionStorage.setItem("redirectToTemplate", JSON.stringify('false'))
      }
      if (params['templateId']) {
        sessionStorage.setItem('id', JSON.stringify(params['templateId']));
        sessionStorage.setItem("redirectToTemplate", JSON.stringify('true'))
      }
      this.logosGlobaList = [];

      this.datacatchingService.getApi(`${ENV.API_URL}all_logos`, isTokenNotExpired, 'logo').subscribe(res => {
        console.log('res', res);
        console.log('logolist', res['logoNamesList'])
        if(res['logoNamesList']){
          for (let logoUrl of res['logoNamesList']) {
            let obj = {
              name: logoUrl['name'],
              value: logoUrl['value'],
              checked: this.bool
            }
            this.listLogo.push(obj);
            this.logosGlobaList.push(obj);
          } 
        }
               
        this.logoForm = this.formBuilder.group({
          selectedLogo: this.formBuilder.array(
            this.listLogo.map(logo => this.createLogoGroup(logo)))
        })
        setTimeout(()=>{
          this.datacatchingService.setLogosEvent(true)
        },1000)
     
        this.isFormLoaded = true;
      });
    
    });
  }
  get logos():FormArray {
    if (this.logoForm) {
      return this.logoForm.get("selectedLogo") as FormArray
    }  
  }

 
  ngOnDestroy(): void {
    console.log("component destroyed", this.attributeForm.reset())
    this.attributeForm.reset();
    this.ref.detectChanges();
  }

  optionsWysywig = {
    height: "300px"
  }
  getISOStringWithoutSecsAndMillisecs(date) {
    const dateAndTime = date.toISOString().split('T');
    const time = dateAndTime[1].split(':');
    return dateAndTime[0] + 'T' + time[0] + ':' + time[1]
  }
  async getRegexTranlsations() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/regex/translations`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      this.regexTransaltions = res['translations'];
      if (this.regexTransaltions.length > 0) {
        for (let re of this.regexTransaltions) {
          this.regexCounties.push({ 'label': re.country, 'value': re.country })
        }
        for (let f of this.fieldModels) {
          if (f.label === 'Traduction regex') {
            f.values = this.regexCounties; 
          }
        }
      }
    }) 
  }
  async getChartes(){
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/charte`, isTokenNotExpired, 'normal').subscribe(res => {
      console.log(' getChartes()',  res)
    if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['result'].length > 0) {
        for (let re of res['result']) {
          this.chartesList.push({ 'label': re.brand, 'value': re.btn_bg_color })
        }
        for (let f of this.fieldModels) {
          if (f.label === 'Charte') {
            f.values =  this.chartesList; 
          }
        }
      }
    })  
  }

  ngAfterViewInit(){
    console.log('The DOM is fully loaded and view is initialized');
    this.datacatchingService.logos$.subscribe(condition=>{
      if(condition===true){
      
      }
    })
  }

  async ngOnInit() {
    this.isProcessing = true;

 

    this.dropdownListTemplates = [
      { item_id: 1, item_text: 'Renault' },
      { item_id: 2, item_text: 'Dacia' },
      { item_id: 3, item_text: 'Alpine' },
      { item_id: 4, item_text: 'MOBILIZE' },
      { item_id: 5, item_text: 'Renault PMS 2024' },
      { item_id: 6, item_text: 'Dacia PMS 2024' },
    ];
    this.selectedTemplateItems = [
    ];

    this.dropdownSettingsTemplate = {
      "singleSelection": true,
      "closeDropDownOnSelection": true,
      idField: 'item_id',
      textField: 'item_text',
      "selectAllText": 'Tout sélectionner',
      "unSelectAllText": 'Tout déselectionner',
      "itemsShowLimit": 3,
      "allowSearchFilter": false,
    };
    this.getRegexTranlsations();
    this.getChartes()
    this.dropdownList = [
      { id: 1, reseau: 'R1' },
      { id: 2, reseau: 'R2' }

    ];
    this.selectedItems = []
    this.dropdownSettings = {
      "singleSelection": false,
      "closeDropDownOnSelection": true,
      "idField": 'id',
      "defaultOpen": true,
      "textField": 'reseau',
      "selectAllText": 'Tout sélectionner',
      "unSelectAllText": 'Tout déselectionner',
      "itemsShowLimit": 3,
      "allowSearchFilter": false,
      
  
      
    };
    this.tempLogoListtoDelete = [];
    this.exisitngLogoNames = [];
    this.globalLogoNames = [];
    let btnObj = {
      "type": "button",
      "icon": "fa-paper-plane",
      "specific": false,
      "hide": false,
      "subtype": "submit",
      "label": "Enregistrer"
    };
    this.model.attributes.unshift(btnObj);
    if (this.screenWidth < 769) {
      this.isTablet = true;
    }
    this.configVal1 = "";
    this.configVal2 = "";
    this.configVal3 = "";
    this.dateTempo = new Date();
    this.hoursMinutesVal = null;
    //set thecofig inital values 
    this.configForm = this.formBuilder.group(configForm);
    this.attributeForm = this.formBuilder.group(attributeForm);
    this.attributeForm.reset();
    this.eventInfoForm = this.formBuilder.group({
      dateStart: [null],
      dateEnd: [null],
      type: [null],
      subType: [null],
      perimetre: [null],
      subPerimetre: [null],
      responsible: this.formBuilder.group({
        lastname: [null],
        firstname: [null],
        phone: [null],
        email: [null, [Validators.pattern("^([a-zA-Z0-9_.-]+)@([a-zA-Z0-9_.-]+)\.([a-zA-Z]{2,5})$")]],
        function: [null],
        entity: [null]
      }),
      context: [null],
    })


    let _id = JSON.parse(sessionStorage.getItem('id'));
    let master_id = JSON.parse(sessionStorage.getItem('masterId'));


    if (_id !== null && _id !== undefined && _id != "undefined") {
      let isTokenNotExpired = await this.datacatchingService.TokenValid();
      this.isEdit = true;
      this.myTitle = this.isEdit ? 'Modifier un évènement ' : 'Créer un évènement';
      let url = `${ENV.API_URL}v2/event/${_id}`;
      this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
        let data = res['result'];
        console.log('event test', data);
        this.reseau = data[0].reseau;
        this.is_dealer_event = data[0].is_dealer_event;
        this.is_dealer_event_show_lead =data[0].is_dealer_event_show_lead;
        this.model.name = data[0].event_obj.name;
        this.model.description = data[0].event_obj.description;
        this.leadsInputsList = data[0].event_obj.attributes.filter(attr => attr.isLead);
        if (!this.leadsInputsList.length) {
          for (let attr of data[0].event_obj.attributes) {
            if (attr.type == 'wiredKeyLead') {
              for (let leadObj of attr.leadsComposeList) {
                leadObj.isLead = true;
                leadObj.hide = true;
              }
              this.leadsInputsList = attr.leadsComposeList;
            }
            if (attr.type == 'dealer') {
              let index = attr.values.indexOf(val => { val.name == "Multi dealers" });
              if (index == -1) {
                let obj = {
                  "name": "Multi dealers",
                  "value": "Multi dealers"
                };
                attr.values.push(obj)
              }
            }
          }
        }

        //add multi dealers to the dealers 
        for (let attr of data[0].event_obj.attributes) {
          if (attr.type == 'dealer') {
            if (!attr.values.some(v => v.name == 'Multi dealers')) {
              let obj = {
                "name": "Multi dealers",
                "value": "Multi dealers"
              };
              attr.values.push(obj);
            }
          }
        }
        //data[0].event_obj.attributes = [...new Map(data[0].event_obj.attributes.map(item => [(item['formControlName'] || item['type']  ), item])).values()];
        data[0].event_obj.attributes.map(attr => attr.toggle = false && attr.type !== 'dealerConfig');
        this.model = data[0].event_obj;
       
        
        this.emailAndPhoneInEvent();
        this.ref.detectChanges();
        this.isHourMinute = this.model.isHourMinute;
        this.isHours = this.model.isHours;
        this.isDateHour = this.model.isDateHour;
        this.notifcenterOptions = this.model?.notifcenterOptions?   this.model?.notifcenterOptions :notifcenterOptions;
        this.smsEmailOptions = this.model?.smsEmailOptions ? this.model?.smsEmailOptions : smsEmailOptions ;
        this.notifEmailTemplateOptions = this.model?.templates;
        this.isNotificationChecked = this.model?.isNotificationChecked;
        this.isNotifSmsOrEmail =this.model?.isNotifSmsOrEmail;
        this.selectedTemplateItems = this.model?.selectedTemplateItems
        if (this.model.eventInfo[0].dateEnd !== null && this.model.eventInfo[0].dateEnd !== '') {
          let date = new Date(this.model.eventInfo[0].dateEnd);
          date.setDate(date.getDate() + 1);
          this.minDateTempo = new Date(date).toISOString().substring(0, 16);
        }
        if (this.model.hasOwnProperty('dateHour') && this.model.dateHour !== null) {
          this.configForm.controls['dateHour'].setValue(this.model.dateHour);

          if (this.model.eventInfo[0].dateEnd !== null && this.model.eventInfo[0].dateEnd !== '' && (new Date(this.model.dateHour.split("T")[0]).getTime() < new Date(this.minDateTempo).getTime())) {
            this.invalidDateTimeTempo = true;
          }
        }
         
        
        this.configForm.controls['dateTempo'].setValue(data[0].hours_tempo);
        this.configForm.controls['hourMinute'].setValue(data[0].hour_minute_tempo);
    
        this.temporisationTimesList = this.model.temporisationTimesList ? this.model.temporisationTimesList : this.temporisationTimesList;
        let obj = {
          label: 'Génération des leads le jj-mm-aaaa à hh:mm',
          value: 'dateHour',
          selected: false
        }
        if (this.model.temporisationTimesList) {
          if (this.model.temporisationTimesList.filter(e => e.value === 'dateHour').length <= 0) {
            this.model.temporisationTimesList.push(obj);
          }
        }
        this.dealerUrl = data[0].event_obj.dealerUrl;

        this.model.eventInfo.forEach(element => {
          this.eventInfoForm.patchValue({
            dateStart: element.dateStart,
            dateEnd: element.dateEnd,
            type: element.type,
            subType: element.subType,
            perimetre: element.perimetre,
            subPerimetre: element.subPerimetre,
            responsible: {
              lastname: element.responsible.lastname,
              firstname: element.responsible.firstname,
              phone: element.responsible.phone,
              email: element.responsible.email,
              function: element.responsible.function,
              entity: element.responsible.entity
            },
            context: element.context
          })
        });
        this.model.attributes = this.model.attributes.filter(attr => attr.type !== 'Contact');
        //set the form values to modify
        this.model.attributes.forEach(attribute => {
          if (attribute.label == 'Traduction') {     
            for (let l of this.translationsLanguages) {
              attribute.values.push({
                'label': l,
                'value':l
              })
            }
            attribute.values = attribute.values.filter(atL => this.translationsLanguages.includes(atL.value))
            attribute.values = [...new Map(attribute.values.map(item => [item['value'], item])).values()]
          }

          if(attribute.label == 'Charte'){
            attribute.values=[];
            for (let l of this.chartesList) {
            
              attribute.values.push({
                'label': l.label,
                'value':l.value
              })
            }
             attribute.values = [...new Map(attribute.values.map(item => [item['value'], item])).values()];
            console.log('chartes  in edit', attribute.values)
          }
         


          if (attribute.label == 'Traduction regex') {
            if (attribute.valueSelected !== null && attribute.valueSelected !== undefined && attribute.valueSelected !== "") {
              // this.getRegexTranlsations();
              console.log('edit this.regexCounties', this.regexCounties)
              for (let l of this.regexCounties) {
                attribute.values.push({
                  'label': l.label,
                  'value': l.value
                })
              }
              attribute.values = [...new Map(attribute.values.map(item => [item['value'], item])).values()]
              this.setRegexCountry(attribute.valueSelected)
            }
          }
         
          if (attribute.type === 'config') {
            attribute.values.forEach(val => {
              if (val.selectedDealer) {
                this.dealer = val.selectedDealer;
              }
              this.configForm.patchValue({
                optTempo: val.optTempo,
                notifCenterValue: val?.notifCenterValue ? val.notifCenterValue:"",
                notifCenterTemplateValue: val?.notifCenterTemplateValue ? val.notifCenterTemplateValue:"",
                smsorEMailOption:val?.smsorEMailOption ? val?.smsorEMailOption:"",
                dateTempo: val.dateTempo,
                redirection: val.redirection
              });
              this.configVal1 = val.selectedDealer;
            })
          }
          if (attribute.type == "mentions") {
            // if (attribute.pdf_file_path !== null) {
            //   this.originalPdfFileName = attribute.label;
            //   this.datacatchingService.getApi(`${ENV.API_URL}legals/${attribute.pdf_file_path}`, isTokenNotExpired, 'logo').subscribe(resPdf => {
            //     this.base64String = resPdf['pdf'];
            //     this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64, ' + resPdf['pdf']);
            //   })
            //   this.pdf_file_path = attribute.pdf_file_path;
            //   this.base64String = attribute.pdf_file_path;
            //   this.fileNameToDownload = attribute.fileNameWithOutPdf;
            //   this.selectedLegalesFile = attribute.pdf_file_path;
            // }

          }

          if (attribute.type === 'wiredKeyLead') {
            
            for (let field of this.fieldModels) {
              if (field.type === 'LeadCompose') {

                if (attribute.hasOwnProperty('leadsComposeList')) {
                  field.values = attribute.leadsComposeList;
                } else {
                  attribute.leadsComposeList = field.values
                }
              }
            }
            let formKeysOfEvent = Object.keys(attribute.listForm[0]);
            let formKeysLead = Object.keys(this.attributeForm.controls);
            let differnceInKeys = formKeysLead.filter(x => !formKeysOfEvent.includes(x));
            for (let key of differnceInKeys) {
              this.attributeForm.removeControl(key);
            }
            this.attributeForm.setValue(attribute.listForm[0]);
          }


          if (attribute.type === "composeType") {
            this.listCarsModeleEner = attribute.values;
            for (let key of Object.keys(this.listCarsModeleEner)) {
              if (this.listCarsModeleEner[key].MARQUE !== undefined && this.listCarsModeleEner[key].MARQUE !== null && this.listCarsModeleEner[key].MARQUE !== '') {
                this.listCarsInterest.push(this.listCarsModeleEner[key].MARQUE);  
              }
              if (this.listCarsModeleEner[key].MODELE !== undefined && this.listCarsModeleEner[key].MODELE !== null && this.listCarsModeleEner[key].MODELE !== '') {
                this.listModelInterest.push(this.listCarsModeleEner[key].MODELE);
              }
              if (this.listCarsModeleEner[key].ENERGIE !== undefined && this.listCarsModeleEner[key].ENERGIE !== null && this.listCarsModeleEner[key].ENERGIE != '') {
                this.listEner.push(this.listCarsModeleEner[key].ENERGIE);
              }
            }
            this.listCarsInterest = this.listCarsInterest.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
            this.listModelInterest = this.listModelInterest.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
            this.listEner = this.listEner.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });

          }

          if (attribute.type === "carsWish") {
            this.listCarsModeleWish = attribute.values
            for (let key of Object.keys(this.listCarsModeleWish)) {
              if (this.listCarsModeleWish[key].MARQUE != undefined && this.listCarsModeleWish[key].MARQUE != null && this.listCarsModeleWish[key].MARQUE != '') {
                this.listCarsWish.push(this.listCarsModeleWish[key].MARQUE);
              }
              if (this.listCarsModeleWish[key].MODELE !== undefined && this.listCarsModeleWish[key].MODELE != null && this.listCarsModeleWish[key].MODELE != '') {
                this.listModeleWish.push(this.listCarsModeleWish[key].MODELE);
              }
             
              if (this.listCarsModeleWish[key].ENERGIE !== undefined && this.listCarsModeleWish[key].ENERGIE !== null && this.listCarsModeleWish[key].ENERGIE != '') {
                this.listEnerWish.push(this.listCarsModeleWish[key].ENERGIE);
              }
            }
            this.listCarsWish = this.listCarsWish.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
            this.listModeleWish = this.listModeleWish.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
            this.listEnerWish = this.listEnerWish.filter(function (elem, index, self) {
              return index === self.indexOf(elem);
            });
          }
        });
        this.datacatchingService.logos$.subscribe(condition=>{
          if(condition===true){
            if (this.model.logo !== undefined && this.model.logo !== null && this.logoForm) {
              this.tempLogoListtoDelete = [];
              this.exisitngLogoNames = [];
              this.globalLogoNames = [];
              this.exisitngLogoNames = this.model.logo.selectedLogo.map(l => l.name);
              this.exisitngLogoNames = [...new Set(this.exisitngLogoNames)];
              this.globalLogoNames = this.logosGlobaList.map(l => l.name);
              this.globalLogoNames = [...new Set(this.globalLogoNames)];
              this.tempLogoListtoDelete = this.exisitngLogoNames.filter(x => !this.globalLogoNames.includes(x)); 
              this.tempLogoListtoDelete = [...new Set(this.tempLogoListtoDelete)];
              for (let name of this.tempLogoListtoDelete) {
                for (let logo of this.model?.logo?.selectedLogo) {
                  if (logo.name === name) this?.model?.logo?.selectedLogo?.splice(logo, 1);
                }
              }
              if (this.tempLogoListtoDelete.length > 0) {
                console.log('logoList to delete', this.tempLogoListtoDelete); 
                this.datacatchingService.postApi(`${ENV.API_URL}remove_logo_for_existing_events`, { code_event: _id, list: this.tempLogoListtoDelete }, isTokenNotExpired, 'normal').subscribe(res => {
                }) 
              }
              let allLogos = this.logosGlobaList.concat(this?.model?.logo?.selectedLogo).reduce((acc, current) => {
                let x = acc.find(item => item.name === current.name);
                if(x){
                  x.checked =current.checked;
                }
                if (!x) {
                  acc.push(current);
                }
                return acc;
              }, []);
              this.logos.clear();
              if(allLogos.length>0){
                allLogos.map(
                  (logo: any) => {
                    const form = this.formBuilder.group({
                       checked: logo.checked,
                        name: logo.name,
                        value: logo.value
                    });
                    this.logos.push(form);
                  })
              }
              this.ref.detectChanges()
            }
          }
        })

       
        if (this.model.theme.bannerImage) {
          this.bannerEdit(this.model.theme.bannerImage)
        }

        this.isListdealers = false;
        this.isUnDealer = false;
        this.isFichierExcel = false;
        this.isFichierExcelOffline = false;
        this.isApiwired = false;
        this.isMultidealers = false;
        this.isNodealer = false;
        //set all the dealer config to modify
        for (let attr of this.model.attributes) {

          if (attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isListOfdealersSelected) {
            this.listDealers = attr.values.list;
            this.isListdealers = true;
          } else if (attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isOnedealerSelected) {
            this.oneDealer = attr.values.list;
            this.isUnDealer = true;
          } else if (attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isNodealerSelected) {
            this.isListdealers = false;
            this.isUnDealer = false;
            this.isFichierExcel = false;
            this.isFichierExcelOffline = false;
            this.isApiwired = false;
          } else if (attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isFileExcelSelected) {
            this.isFichierExcel = true;
          } else if (attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isApiwiredSelected) {
            this.isApiwired = true;
            this.isFichierExcelOffline = true;
            if(attr.values.affaireExcelFileName.length>0){
              this.datacatchingService.getApi(`${ENV.API_URL}verify/affaire/${attr.values.affaireExcelFileName}`,isTokenNotExpired,'logo').subscribe(res=>{
                if(res && res['success']){
                  this.affaireExcelFileName = attr.values.affaireExcelFileName;
                }
              })
            }
            
        
             this.ref.detectChanges();
          } else if ((attr.type === "wiredKeyLead" && attr.listForm && attr.listForm[0].dealerOfInterest === 'Multi dealers')) {
            this.isMultidealers = true;
            for (let attrChild of this.model.attributes) {
              if (attrChild.type === "dealerConfig" && attrChild.values !== undefined && attrChild.values.isMultidealers) {
                this.isDisplayVendeur = (attrChild.values.isDisplayVendeur !== undefined && attrChild.values.isDisplayVendeur !== null) ? attrChild.values.isDisplayVendeur : false;
                this.isDisplayCampagne = (attrChild.values.isDisplayCampagne !== undefined && attrChild.values.isDisplayCampagne !== null) ? attrChild.values.isDisplayCampagne : false;
              }
            }
          }
          else if ((attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isMultidealers)) {
            this.isMultidealers = true;
           
            this.isDisplayVendeur = (attr.values.isDisplayVendeur !== undefined && attr.values.isDisplayVendeur !== null) ? attr.values.isDisplayVendeur : false;
            this.isDisplayCampagne = (attr.values.isDisplayCampagne !== undefined && attr.values.isDisplayCampagne !== null) ? attr.values.isDisplayCampagne : false;

          }
        }

      });

      for (let attr of this.model.attributes) {

        if (attr.type === "button") {
          if (!attr.hide) {
            let btn = this.model.attributes.find(attribute => attribute.type === 'button');
            this.model.attributes = this.model.attributes.filter(attribute => attribute.type !== "button");
            this.model.attributes.unshift(btn);
          }
        }
      }

    } else {
      this.model.attributes.push(...this.leadsInputsList);
      this.isEdit = false;
    }
    if (master_id !== null && master_id !== undefined && master_id != "undefined") {
      let isTokenNotExpired =await this.datacatchingService.TokenValid();
      let url = `${ENV.API_URL}getCurrentEvent/${master_id}`;
      this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
        let data = res['result'];
        if (data) {
          this.model.attributes = data[0].event_obj.attributes;
        }
      });
    }
 this.isProcessing = false;
    // this.getMasterEvents();
  }
  getControls() {
    return (<UntypedFormArray>this.logoForm.get('selectedLogo')).controls;
  }

  addLogo(logo) {
    this.logos.push(logo);
  }

  async deleteLogo(logoName, i, e) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isActionLogo = true;
    this.ref.detectChanges();
    this.datacatchingService.deleteApi(`${ENV.API_URL}delete_logo/${logoName}`, isTokenNotExpired,'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      this.isActionLogo = false;
      if (res['code'] == 1 || res['code'] == 2) {
        this.logos.removeAt(i)
        this.ref.detectChanges();
        this.toastr.success("Le logo à bien été supprimé")
      }
      // if (res['code'] == 2) {
      //   this.toastr.error("Une erreur est survenu lors de la suppression de logo")
      // }

    })
  }

  addAccord() {
    let obj = {
      "type": "gdpr-",
      "label": "Matagal na nating alam na nakukuha",
      "required": true,
      "formControlName": "phoneMarketingOptin",
      "leadRequired": "Matagal",
      "name": "Matagal",
      "errorText": "Matagal",
      "hide": false,
      "isInput": true,
      "valuesGdpr": [
        {
          "label": "Oui",
          "name": "Matagal",
          "value": "Y",
          "InputType": "radio"
        },
        {
          "label": "Non",
          "name": "Matagal",
          "value": "N",
          "InputType": "radio"
        }
      ]
    };
    this.model.attributes.forEach(elt => {
      if (elt.type == "GDPR") {
        elt.values.push(obj)
      }

    });

  }
  onMlegaleChange(val, type) {
    if (val == "M-short") {
      this.model.attributes.forEach(elt => {
        elt.isShortLegales = true;

        elt.isPdf = false;
        if (elt.type == type) {

          for (let v of elt.values) {
            if (v.val == val) {
              // elt.label = "";
              v.isChecked = true;
            } else {
              v.isChecked = false;
            }
          }
        }
      });


    }
    if (val == "M-pdf") {
      this.model.attributes.forEach(elt => {
        elt.isShortLegales = false;

        elt.isPdf = true;
        if (elt.type == type) {
          for (let v of elt.values) {
            if (v.val == val) {
              // elt.label = "";
              v.isChecked = true;
            } else {
              v.isChecked = false;
            }
          }
        }
      });

    }
  }
  removeInput(index, type) {
    this.model.attributes.forEach(elt => {
      if (elt.type == type) {
        elt.values = elt.values.filter((val, i) => i !== index);
      }

    });
  }
  removeSelectedList(list, listExclude) {
    for (let el of list) {
      let index = listExclude.findIndex(e => e['id'] == el['id']);
      listExclude = listExclude.filter((el, i) => i !== index);
    }
  }
  setNormalFilters(filterObj) {
    this.filtersList = filterObj !== undefined && filterObj['normalFilters'] !== undefined && filterObj['normalFilters'] !== undefined ? filterObj['normalFilters'] : [];
  }
  setIndividualFilters(selectedFilterArr) {
    for (let obj of selectedFilterArr) {
      for (let option of obj['options']) {
        for (let selectedFilter of this.filtersList) {
          if (option.paramaterApi == selectedFilter.paramaterApi && option.label == selectedFilter.label && obj['brand'] == selectedFilter.brand) {
            option.checked = selectedFilter.checked;
            this.ref.detectChanges();
          }
        }
      }
    }
  }
  setModelFilters(arr, ngModelArr) {
    for (let el of arr) {
      let object = {
        id: el['id'],
        label: el['label']
      }
      ngModelArr.push(object);
    }
  }

  onLogoChanged(event: any) {
    this.selectedFile = <File>event.target.files[0];
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width <= 800) {
        this.islogoFileSelected = true;
        this.isValidLogoWidth = true;
        this.ref.detectChanges();
      } else {
        this.islogoFileSelected = false;
        this.isValidLogoWidth = false;
        this.ref.detectChanges();
      }

    }
  }

  async onSubmit() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isActionLogo = true;
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    this.islogoNewSubmit = true;
    this.isLogoSubmit = false;
    `${ENV.API_URL}upload_logo`
    this.datacatchingService.postApi(`${ENV.API_URL}upload_logo`, formData, isTokenNotExpired,'logo').subscribe(res => {
      let logoObj = {
        name: res['file_name'],
        checked: false
      };
      this.isActionLogo = false;
      this.datacatchingService.getApi(`${ENV.API_URL}logo/${res['file_name']}`, isTokenNotExpired, 'logo').subscribe(resLogo => {
        Object.assign(logoObj, { value: resLogo['image'] });
        this.datacatchingService.postApi(`${ENV.API_URL}add_logo_to_all_events`, logoObj, isTokenNotExpired,'normal').subscribe(res => {
        });
        this.islogoNewSubmit = false;
        this.isLogoSubmit = true;
        this.islogoFileSelected = false;
        this.fileInput.nativeElement.value = '';
        this.logos.push(this.formBuilder.group(logoObj));
        this.toastr.success("Le logo à bien été ajouté")
        this.ref.detectChanges();
      })
    });
  }

  bannerChanged(event): any {
    this.selectedBannerFile = <File>event.target.files[0];
    let img = new Image()
    img.src = window.URL.createObjectURL(event.target.files[0])
    img.onload = () => {
      if (img.width > 1200) {
        this.errorBanner = true;
        this.isValidBannerImg = false;
        this.ref.detectChanges();
      } else {
        this.isValidBannerImg = true;
        this.errorBanner = false;
        this.ref.detectChanges();
      }
    }
  }
 
  downloadPdf(base64String, fileName) {
    const linkSource = 'data:application/pdf;base64,' + base64String;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

  }
  

  async getPdf(fileName) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}legals/${fileName}`, isTokenNotExpired,'logo').subscribe(resPdf => {
      if (resPdf['access_token'] !== null && resPdf['access_token'] !== undefined) sessionStorage.setItem('access_token', resPdf['access_token'])
      const linkSource = 'data:application/pdf;base64,' + resPdf['pdf'];
      const downloadLink = document.createElement("a");
      const fileName = "sample.pdf";
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      // this.base64ToArrayBuffer(resPdf['pdf']);
    })
  }

  downLoadAffaireFile(affaireExcelFileName) {
    this.datacatchingService.getExcelAffaireFile(affaireExcelFileName).subscribe(file => {
      this.blobToFile(file, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", affaireExcelFileName);
    })
  
  }
  downLoadExcelModelFile() {
    const downloadLink = document.createElement("a");
    const fileName = "../../../assets/excel/template.xlsx";
    downloadLink.href = fileName;
    downloadLink.download = fileName;
    downloadLink.click();
    
  }

  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url; a.download = fileName; a.click();
    window.URL.revokeObjectURL(url);
  }
  async onChangeFileName($event, type) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    if (type == "mentions" && $event.target.value !== '' && $event.target.value !== null) {
      var d = new Date();
      var n = d.getTime();
      let fileObj = { oldName: this.originalPdfFileName, newName: $event.target.value + '.pdf' };
      this.datacatchingService.postApi(`${ENV.API_URL}rename`, fileObj, isTokenNotExpired, 'file').subscribe(res => {
        this.originalPdfFileName = res['result']['newName'];
        for (let attr of this.model.attributes) {
          if (attr.type == "mentions") {
            let nameOfFile = "";
            attr.label = res['result']['newName'];
            attr.pdf_file_path = this.originalPdfFileName;
            nameOfFile = res['result']['newName'];
            let file = nameOfFile.split('.');
            attr.fileNameWithOutPdf = file[0];
            this.fileNameToDownload = file[0];
            this.ref.detectChanges();
          }
        }
      })

    }

  }

  copyInputMessage(inputElement) {
    inputElement = inputElement.slice(0, -1);
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (inputElement));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.toastr.success("Le dealer locator l'url est copié");
  }
  async onBannerSubmit() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    const formData = new FormData();
    formData.append('file', this.selectedBannerFile);
    var image_resp = await this.datacatchingService.postApi(`${ENV.API_URL}bannerimage`, formData, isTokenNotExpired , 'file').toPromise();
    var baseImage = await this.datacatchingService.getApi(`${ENV.API_URL}bimage/${image_resp['file_name']}`, isTokenNotExpired, 'logo').toPromise();
    let objectURL = baseImage['image'];
    this.model.theme.bannerImage = image_resp['file_name'];
    this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
    this.ref.detectChanges();
  }


  resetList(id, list, listExclude) {
    list.forEach(activity => {
      if (activity['id'] == id) {
        listExclude.push(activity);
        list.push(activity);
        listExclude = [...new Set(listExclude)];
        list = [...new Set(list)];
      
      }
    });

    this.ref.detectChanges();
  }
  createLogoGroup(logo: Logo): UntypedFormGroup {
    return this.formBuilder.group({
      checked: logo.checked,
      name: logo.name,
      value: logo.value
    });
  }

  // on scroll top stick the filed selection area 
  @HostListener('window:scroll', ['$event']) onScroll(event) {
    let el = document.getElementById("field-id") as HTMLElement;
    if (window.scrollY > 80) {
      el.style.position = "sticky";
      el.style.top = window.scrollY + "px";
    }
  }
  
  onChangeDealerLocator(event) {
    let dealerName: string = event.target.value;
    if (dealerName === "Sans dealer") {
      this.isApiwired = false;
      this.isListdealers = false;
      this.isUnDealer = false;
      this.isFichierExcel = false;
      this.isFichierExcelOffline = false
      this.isNodealer = true;
      this.isMultidealers = false;
      let obj = {
        isNodealerSelected: true
      };
      this.setDealerConfig(obj);
    } else if (dealerName === "Un dealer") {
      this.isUnDealer = true;
      this.isApiwired = false;
      this.isListdealers = false;
      this.isFichierExcel = false;
      this.isFichierExcelOffline = false;
      this.isMultidealers = false;
      let obj = {
        isOnedealerSelected: true,
        list: this.oneDealer
      };
      this.setDealerConfig(obj);

    } else if (dealerName === "Fichier excel CP > RRF") {
      this.isFichierExcel = true;
      this.isListdealers = false;
      this.isApiwired = false;
      this.isUnDealer = false;
      this.isFichierExcelOffline = false;
      this.isMultidealers = false;
      let obj = {
        isFileExcelSelected: true
      };
      this.setDealerConfig(obj);
    } else if (dealerName === "API WIRED") {
      this.isApiwired = true;
      this.isFichierExcel = false;
      this.isListdealers = false;
      this.isUnDealer = false;
      this.isFichierExcelOffline = false;
      this.isMultidealers = false;
      let obj = {
        isApiwiredSelected: true
      };
      this.setDealerConfig(obj);
    }
    else if (dealerName === "Liste déroulante des dealers participants") {
      this.isApiwired = false;
      this.isFichierExcel = false;
      this.isUnDealer = false;
      this.isListdealers = true;
      this.isFichierExcelOffline = false;
      this.isMultidealers = false;
      let obj = {
        isListOfdealersSelected: true,
        list: this.listDealers
      };
      this.setDealerConfig(obj);
    }
    else if (dealerName === "Multi dealers") {
      this.isApiwired = false;
      this.isFichierExcel = false;
      this.isUnDealer = false;
      this.isListdealers = false;
      this.isFichierExcelOffline = false;
      this.isMultidealers = true;
      let obj = {
        isMultidealers: true,
        isDisplayVendeur: this.isDisplayVendeur,
        isDisplayCampagne : this.isDisplayCampagne
      };
      this.setDealerConfig(obj);
    }
  }

  onListDealersChange(e, formControlName) {
    this.listDealers[0].valueSelected = e.target.value;
    for (let attr of this.model.attributes) {
      if (attr.formControlName === formControlName) {
        attr.valueSelected = e.target.value;
      }
    }
  }

  // set the selected dealer config 
  setDealerConfig(Obj) {
    this.dealerConfigArr = [];
    this.dealerConfigArr.push(Obj);
  }

  onLeadChange($event) {

  }

  //set the modele form 
  async onEdit(id) {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    sessionStorage.setItem('masterId', JSON.stringify(id));
    $('#default').modal('toggle');
    this.toastr.success("Événement" + this.model.name + " sélectionner comme modèle", '', {
      timeOut: 5000
    });
    let url = `${ENV.API_URL}getCurrentEvent/${id}`;
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
      let data = res['result'];
      if (data) {
        this.model.attributes = data[0].event_obj.attributes;
       
        for (let attr of this.model.attributes) {
        
          if (attr.type == "mentions") {
            if (attr.pdf_file_path !== null) {
              this.originalPdfFileName = attr.label;
           
              this.datacatchingService.getApi(`${ENV.API_URL}legals/${attr.pdf_file_path}`, isTokenNotExpired, 'logo' ).subscribe(
                resPdf => {
                  this.base64String = resPdf['pdf'];
                  if (resPdf['access_token'] !== null && resPdf['access_token'] !== undefined) sessionStorage.setItem('access_token', resPdf['access_token'])
                  this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl('data:application/pdf;base64, ' + resPdf['pdf']);
                  this.pdf_file_path = attr.pdf_file_path;
                  this.base64String = attr.pdf_file_path;
                  this.fileNameToDownload = attr.fileNameWithOutPdf;
                  this.selectedLegalesFile = attr.pdf_file_path;
                }, err => {
                  console.log("error",err);
              })
            
            }

          }
          if (attr.type === 'config') {
            attr.values.forEach(val => {
              if (val.selectedDealer) {
                this.dealer = val.selectedDealer;
              }
              this.configForm.patchValue({
                optTempo: val.optTempo,
                notifCenterValue: "",
                dateTempo: val.dateTempo,
                redirection: val.redirection
              });
              this.configVal1 = val.selectedDealer;
            })
          }

          if (attr.type === 'wiredKeyLead') {
            for (let field of this.fieldModels) {
              if (field.type === 'LeadCompose') {
                if (attr.hasOwnProperty('leadsComposeList')) {
                  field.values = attr.leadsComposeList;
                } else {
                  attr.leadsComposeList = field.values
                }
              }
            }

            for (let element of attr.listForm) {
              this.attributeForm.setValue({
                platformBrand: element.platformBrand ? element.platformBrand : null,
                leadSource: element.leadSource !== undefined ? element.leadSource : null,
                origin: element.origin !== undefined ? element.origin : null,
                context: element.context !== undefined ? element.context : null,
                leadFormName: element.leadFormName !== undefined ? element.leadFormName : null,
                subTypeOfInterest: element.subTypeOfInterest !== undefined ? element.subTypeOfInterest : null,
                reminderResponsible: element.reminderResponsible !== undefined ? element.reminderResponsible : null,
                device: element.device !== undefined ? element.device : null,
                leadCountry: element.leadCountry !== undefined ? element.leadCountry : null,
                leadProvider: element.leadProvider !== undefined ? element.leadProvider : null,
                campaign: element.campaign !== undefined ? element.campaign : null,
                typeOfInterest: element.typeOfInterest !== undefined ? element.typeOfInterest : null,
                dealerOfInterest: element.dealerOfInterest !== undefined ? element.dealerOfInterest : null
              })
            }
          
            this.isListdealers = false;
            this.isUnDealer = false;
            this.isFichierExcel = false;
            this.isFichierExcelOffline = false;
            this.isApiwired = false;

            for (let attr of this.model.attributes) {
              if (attr.type == 'dealerConfig') {
                this.dealerConfigArr.push(attr.values);
              }

              if (attr.type === "dealerConfig" && attr.values.isListOfdealersSelected) {
                this.listDealers[0].values = attr.values.list[0].values;
                this.isListdealers = true;
               
              } else if (attr.type === "dealerConfig" && attr.values.isOnedealerSelected) {
                this.oneDealer = attr.values.list;
                this.isUnDealer = true;
              } else if (attr.type === "dealerConfig" && attr.values.isNodealerSelected) {
                this.isListdealers = false;
                this.isUnDealer = false;
                this.isFichierExcel = false;
                this.isFichierExcelOffline = false;
                this.isApiwired = false;

              } else if (attr.type === "dealerConfig" && attr.values.isFileExcelSelected) {
                this.isFichierExcel = true;
              } else if (attr.type === "dealerConfig" && attr.values.isApiwiredSelected) {
                this.isApiwired = true;
                this.isFichierExcelOffline = false;
              } else if (attr.type === "dealerConfig" && attr.values !== undefined && attr.values.isMultidealers) {
                this.isMultidealers = true;

              }
            }
          }
        }
            if (data !== undefined && data[0].event_obj !== undefined && data[0].event_obj.logo !== undefined && data[0].event_obj.logo.selectedLogo !== undefined) {
              console.log('logos', data[0].event_obj.logo.selectedLogo);
              if (this.logoForm) {
                this.logoForm.patchValue({ selectedLogo: data[0].event_obj.logo.selectedLogo });
              }
            
            }
        this.emailAndPhoneInEvent();
      }
    });
  }

  // add the selected fileds to the list  Mobile
  onClick(label) {
    this.fieldModels.filter(item => {
      if (item.label === label) {
        // this.model.attributes = item;
        // this.isMobile = true;
        let selectedInput = [item];

        Swal.fire({
          title: 'Voulez-vous ajouter <strong class="text-warning">' + item.label + '</strong>',
          text: ``,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non'
        }).then((result) => {
          if (result.value) {
            this.model = {
              name: this.isEdit ? this.model.name : "",
              description: this.isEdit ? this.model.description : "",
              theme: {
                bgColor: "ffffff",
                textColor: "555555",
                bannerImage: ""
              },
              attributes: this.model.attributes.concat(selectedInput),
            };
          
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire(
              '',
              ``,
              ''
            )
          }
        })

      }
    });
  }

  onMasterEvent(e): any {
    if (e.target.checked) {
      this.model.isMasterEvent = !this.model.isMasterEvent;
    }
    if (!e.target.checked) {
      this.model.isMasterEvent = !this.model.isMasterEvent;
    }
  }

  // cancel the event 
  onCancal() {
    // sessionStorage.clear();
    if ('id' in sessionStorage) sessionStorage.removeItem('id');
    if ('redirectToTemplate' in sessionStorage) sessionStorage.removeItem('redirectToTemplate');
    if ('masterId' in sessionStorage) sessionStorage.removeItem('masterId');
    this.attributeForm.reset();
    this.ref.detectChanges();
    this.model.name = "";
    this.model.description = "";
    this.resetWizard();
    this.isEdit = false;
    console.log("attributeFormValues on cancel", this.attributeForm.getRawValue())
    this.myTitle = this.isEdit ? 'Personnalisation' : 'Créer';
    this.ref.detectChanges();
    this.router.navigate(['/home']);

  }
  onClear() {
    if ('id' in sessionStorage) sessionStorage.removeItem('id');
    if ('redirectToTemplate' in sessionStorage) sessionStorage.removeItem('redirectToTemplate');
    if ('masterId' in sessionStorage) sessionStorage.removeItem('masterId');
    // this.resetApiDealerFilterArrays();
    this.model.name = "";
    this.model.description = "";
    this.model.attributes = [];
    this.attributeForm.reset();
    this.ref.detectChanges();
    this.resetWizard();
    console.log("attributeFormValues on clear", this.attributeForm.getRawValue())
    this.isHourMinute = false;
    this.isHours = false;
    this.router.navigate(['/home']);
  }
  
  createAttribute() {
    let obj = {}
    Object.keys(this.attributeForm.controls).forEach(key => {
      obj = { name: key, value: this.attributeForm.controls[key].value }
      this.leadList.push(obj);
    });
  }
  resetAndOrList(array) {
    for (let option of array) {
      option.checked = false;
    }
  }

  resetFilters(array) {
    for (let list of array) {
      for (let option of list['options']) {
        option.checked = false;

      }
    }
  }

  transfromDate(date) {
    date = date.split('/').join('-').replace(',', '');
    let onlyDate = date.split(" ");
    let formatChangedDate = onlyDate[0].split("-");
    let d = `${formatChangedDate[2]}-${formatChangedDate[1]}-${formatChangedDate[0]}T${onlyDate[1]}`;
    return d;
  }
  //Save Event 
  async saveEvent(model) {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    let user =  JSON.parse(sessionStorage.getItem("currentUser"))
    let userObj = { date_creation:  this.transfromDate(new Date().toLocaleString()),event_name: model.name,action:"Création de l'évènement",...user }
    console.log("actionObj", userObj);
    this.datacatchingService.postApi(`${ENV.API_URL}v2/history/action_event/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {
      console.log("action recorded");
    });
    let cts = new Date().toISOString();
    this.model = model;
    //get all the values obj from the form
    let arr = [this.configForm.getRawValue()];
    let logoValue = this.logoForm?.getRawValue() ?  this.logoForm?.getRawValue():"";
    let listleadForm = [this.attributeForm.getRawValue()];
    let infoEvent = [this.eventInfoForm.value]
    let tempConfigObj: Object = { type: 'config', values: arr };
    let tempDealerConfig: Object = { type: 'dealerConfig', values: this.dealerConfigArr[0] };
    let leadsList = this.fieldModels.filter(model => model.isLead);
    let tempAttribute: Object = { type: 'wiredKeyLead', listLead: this.leadList, listForm: listleadForm, leadsComposeList: leadsList };
    let hours = this.configForm.get('dateTempo').value !== null ? this.configForm.get('dateTempo').value : null;
    let hoursMinute = this.configForm.get('hourMinute').value !== null ? this.configForm.get('hourMinute').value : null;
    let dateHour = this.configForm.get('dateHour').value !== null ? this.configForm.get('dateHour').value : null;
    let tempoObject = {selectedTemplateItems:this.selectedTemplateItems,isNotifSmsOrEmail:this.isNotifSmsOrEmail,isNotificationChecked:this.isNotificationChecked, hours: hours, hoursMinute: hoursMinute, dateHour: dateHour,smsEmailOptions:this.smsEmailOptions,notifcenterOptions:this.notifcenterOptions, templates:this.notifEmailTemplateOptions };
    Object.assign(this.model, { ...tempoObject });
    let index = this.model.attributes.findIndex(el => el.type == 'dealerConfig');
    this.model.attributes.splice(index, 0);
    this.model.attributes.push(tempDealerConfig);
    this.model.attributes.push(tempConfigObj);
    this.model.attributes.push(tempAttribute);
    this.model.attributes.forEach((item, i) => {
      if (item.type === 'carsWish') {
        if (this.listCarsModeleWish.length)
          item.values = this.listCarsModeleWish;
      }
      else if (item.type === 'composeType') {
        if (this.listCarsModeleEner.length)
          item.values = this.listCarsModeleEner;
      }
    });
    this.model.eventInfo = infoEvent;
    this.model.logo = logoValue;
    this.submittedConfig = true;
    this.model.is_dealer_event = this.is_dealer_event;
    this.model.is_dealer_event_show_lead = this.is_dealer_event_show_lead;
    this.model.reseau = this.reseau;
    var isErrorTempo = document.getElementsByClassName('error-border');
    if ((this.isDateHour == true && isErrorTempo.length > 0) || (this.isDateHour && this.configForm.get('dateHour').value == null)) {
      this.toastr.error("Vuillez séléctionnez le tempo", '', {
        timeOut: 5000
      });
    } else {
      // console.log('model attr', model.event_obj.attributes);
      console.log('this.model attr', this.model.attributes);
      //check for the empty model and description
      if (this.configForm.controls['redirection'].valid) {
        this.isSaveorEdit = true;
        let url = `${ENV.API_URL}/v2/event/create`;
        this.datacatchingService.postApi(url, { event_obj: model, cts: cts, is_dealer_event: this.is_dealer_event, is_dealer_event_show_lead:this.is_dealer_event_show_lead, reseau: this.reseau }, isTokenNotExpired, 'normal').subscribe(res => {
          if (res['status'] === 200) {
            this.dealerUrl = "";
            this.extraFiltersUrl = "";
            // this.normalFiltersUrl = "";
            this.model.attributes = [];
            this.model.name = "";
            this.model.description = "";
            // this.resetApiDealerFilterArrays();
            this.model.isMasterEvent = !this.model.isMasterEvent;
            this.ref.detectChanges();
            this.attributeForm.reset();
            this.ref.detectChanges();
    
            if ("redirectToTemplate" in sessionStorage) sessionStorage.removeItem("redirectToTemplate");
            if ("masterId" in sessionStorage) sessionStorage.removeItem("masterId");
            if ("id" in sessionStorage) sessionStorage.removeItem("id");
            setTimeout(() => {
              this.router.navigate(['/forms']);
              this.isSaveorEdit = false;
              this.toastr.success("Événement créé avec succès :)", '', {
                timeOut: 5000
              });
            }, 2000);
          }
        });

        this.OnUploadFile();

      }
      return false;
    }
    
  }

  // update event
  async updateEvent(model, id) {
    
    let user = JSON.parse(sessionStorage.getItem("currentUser"))
    let userObj = { date_creation: this.transfromDate(new Date().toLocaleString()), event_name: model.name, action: "Mis à jour de l'évènement", ...user }
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.postApi(`${ENV.API_URL}v2/history/action_event/create`, userObj, isTokenNotExpired, 'normal').subscribe(res => {
      console.log("action recorded");
    })
    

    if (this.isEdit) {
      id = JSON.parse(sessionStorage.getItem('id'));
    }
 
    let arr = [this.configForm.getRawValue()];
    let logoValue = this.logoForm?.getRawValue() ?  this.logoForm?.getRawValue() :"";
    let listleadForm = [this.attributeForm.getRawValue()]
    if (this.eventInfoForm.value.dateEnd == '') {
      this.eventInfoForm.value.dateEnd = null;
    }
    if (this.eventInfoForm.value.dateStart == '') {
      this.eventInfoForm.value.dateStart = null;
    }
    let infoEvent = [this.eventInfoForm.value]
 
    
    model.attributes.forEach(item => {
      if (item.type === 'config') {
        item.values = arr;
      }

      else if (item.type === "wiredKeyLead") {
        item.listForm = listleadForm;
        item.listLead = this.leadList

        for (let model of this.fieldModels) {
          if (model.type === 'LeadCompose') {
            item.leadsComposeList = model.values;
          }
        }
      }

      else if (item.type === "dealerConfig" && this.isListdealers) {
        for (let attr of model.attributes) {
          if (attr.type == 'dealer') {
            this.listDealers[0].required = attr.required;

          }
        }
        item.values = {
          isListOfdealersSelected: true,
          list: this.listDealers
        };
      } else if (item.type === "dealerConfig" && this.isUnDealer) {

        item.values = {
          isOnedealerSelected: true,
          list: this.oneDealer
        }
      }
      else if (item.type === "dealerConfig" && this.isNodealer) {
        item.values = {
          isNodealerSelected: true,
          list: []
        }
      } else if (item.type === "dealerConfig" && this.isFichierExcel) {
        item.values = {
          isFileExcelSelected: true,
          list: []
        }
      } else if (item.type === "dealerConfig" && this.isApiwired) {
        item.values = {
          isApiwiredSelected: true,
          affaireExcelFileName: this.affaireExcelFileName ? this.affaireExcelFileName:"" ,
          list: this.dealerConfigArr
        }
      } else if (item.type === "dealerConfig" && this.isMultidealers) {
        item.values = {
          isMultidealers: true,
          isDisplayVendeur: this.isDisplayVendeur,
          isDisplayCampagne: this.isDisplayCampagne,
          list: []
        }
      }
      else if (item.type === "carsWish") {
        item.values = this.listCarsModeleWish;
      }
      else if (item.type === "composeType") {
        item.values = this.listCarsModeleEner;
      }
    });
    model.eventInfo = infoEvent;
    model.logo = logoValue;
    console.log('update event', model);
    let hours = this.configForm.get('dateTempo').value !== null ? this.configForm.get('dateTempo').value : null;
    let hoursMinute = this.configForm.get('hourMinute').value !== null ? this.configForm.get('hourMinute').value : null;
    let dateHour = this.configForm.get('dateHour').value !== null ? this.configForm.get('dateHour').value : null;
    let tempoObject = {selectedTemplateItems:this.selectedTemplateItems,isNotifSmsOrEmail:this.isNotifSmsOrEmail,isNotificationChecked:this.isNotificationChecked, hours: hours, hoursMinute: hoursMinute, dateHour: dateHour,smsEmailOptions:this.smsEmailOptions,notifcenterOptions:this.notifcenterOptions, templates:this.notifEmailTemplateOptions };
    model.isHours = this.isHours;
    model.isHourMinute = this.isHourMinute;
    model.isDateHour = this.isDateHour;
    model.is_dealer_event = this.is_dealer_event;
    model.is_dealer_event_show_lead = this.is_dealer_event_show_lead;
    model.reseau = this.reseau;

    Object.assign(model, { ...tempoObject });
    this.createFilterObjects();
    this.submittedConfig = true

    var isErrorTempo = document.getElementsByClassName('error-border');
    if ((this.isDateHour == true && isErrorTempo.length > 0) || (this.isDateHour && this.configForm.get('dateHour').value === '')) {
      this.invalidDateTimeTempo = true;
      this.toastr.error("Vuillez séléctionnez le tempo", '', {
        timeOut: 5000
      });
    } else {

      if (this.configForm.controls['redirection'].valid) {
        this.isSaveorEdit = true;
        console.log('update in model', model);
        let url = `${ENV.API_URL}v2/event/update/${id}`;
        let responseApi = await this.datacatchingService.updateApi(url, model, isTokenNotExpired).toPromise();
        if (responseApi) {
          if (responseApi['access_token']) {
            sessionStorage.setItem('access_token', responseApi['access_token']);
          }
          this.OnUploadFile();
          this.model.attributes = [];
       
          this.model.name = "";
          this.model.description = "";
          this.attributeForm.reset();
          this.ref.detectChanges();
          if ('id' in sessionStorage) sessionStorage.removeItem('id');
          // this.resetApiDealerFilterArrays();
          let redirectToTemplate = sessionStorage.getItem("redirectToTemplate");
          if (redirectToTemplate !== null && (redirectToTemplate !== undefined && JSON.parse(redirectToTemplate) === "true")){
            this.router.navigate(['/template']);
            this.isEdit = false;
            this.isSaveorEdit = false;
            if ("redirectToTemplate" in sessionStorage) sessionStorage.removeItem("redirectToTemplate");
            if ("masterId" in sessionStorage) sessionStorage.removeItem("masterId");
            if ("id" in sessionStorage) sessionStorage.removeItem("id");
          } else {
            this.router.navigate(['/forms']);
            this.isEdit = false;
            this.isSaveorEdit = false;
            if ("redirectToTemplate" in sessionStorage) sessionStorage.removeItem("redirectToTemplate");
            if ("masterId" in sessionStorage) sessionStorage.removeItem("masterId");
            if ("id" in sessionStorage) sessionStorage.removeItem("id");

          }
          sessionStorage.removeItem("id");
          this.toastr.success("Événement modifié avec succès", '', {
            timeOut: 5000
          });
        }
      }
    }

  }

  //
  movePosition(position) {
  }
  // sort the filed in the list 
  onChangePosition(selectedPosition, item) {
    this.model.attributes = this.model.attributes.filter(x => x !== item);
    this.model.attributes.splice(selectedPosition, 0, item);
    this.ref.detectChanges();
  }
  onDragStart(event: DragEvent) {
  }

  onDragEnd(event: DragEvent) {
  }

  onDraggableCopied(event: DragEvent) {
  }

  onDraggableLinked(event: DragEvent) {
  }

  onDragged(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      list.splice(index, 1);
    }
  }

  onDraggedChild(item: any, list: any[], effect: DropEffect) {
    if (effect === "move") {
      const index = list.indexOf(item);
      console.log('index onDraggedChild', index)
      list.splice(index, 1);
    }
  }

  onDragCanceled(event: DragEvent) {
  }

  onDragover(event: DragEvent) {
    // console.log(' onDragover', event)
  }

  onDrop(event: DndDropEvent, list?: any[]) {

    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      if (event.dropEffect === "copy")
        event.data.name = event.data.type + '-' + new Date().getTime();
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
     let alreadyInArr =[];
     if(event.data.label === "Traduction" || event.data.label=='Traduction regex' || event.data.label==='Charte'){
       alreadyInArr = this.model.attributes.filter(attr => attr.label === event.data.label  );
     }
      if (alreadyInArr.length === 0) {
        list.splice(index, 0, event.data);
      } else {
        this.toastr.info('Ce champs exists déja !')
      }
     
      this.emailAndPhoneInEvent();
    }
  }

  onDropChild(event: DndDropEvent, list?: any[]) {
    if (list && (event.dropEffect === "copy" || event.dropEffect === "move")) {
      if (event.dropEffect === "copy")
        event.data.name = event.data.type + '-' + new Date().getTime();
      let index = event.index;
      if (typeof index === "undefined") {
        index = list.length;
      }
      list.splice(index, 0, event.data);
    }
  }

  addValue(values) {
    values.push(this.value);
    this.value = { label: "", value: "" };
  }

  removeField(i) {
    this.model.attributes.forEach((model, k) => {
      if (model.type === 'carsWish') {
        if (k == i) {
          this.listCarsWish = [];
          this.listCarsModeleWish = [];
          this.listEnerWish = []
        }
      }
      else if (model.type === 'composeType') {
        if (k == i) {
          this.listModelInterest = [];
          this.listCarsInterest = [];
          this.listEner = [];
        }
      }
      else if (model.type === 'LeadCompose') {
        if (k == i) {
          this.model.attributes.splice(i, 1);
          this.attributeForm.reset();
        }
      } else if (model.isLead) {
       
      }
      if (k === i) {
        if (model.isLead) {
          this.attributeForm.removeControl(model.formControlName);
        }
       
        this.model.attributes.splice(i, 1);
      }
      this.emailAndPhoneInEvent();
      this.removePhoneEmailFiled();
    })
  }

  removePhoneEmailFiled() {
    let keys = this.model.attributes.filter(attr => attr.type).map(at => at.type);
    const values = ['phone', 'email'];
    this.isPhoneAndEmailExist = values.every(value => {
      return keys.includes(value);
    });
    if (!this.isPhoneAndEmailExist) {
      this.model.attributes = this.model.attributes.filter(attr => attr.label !== "Configuration du Phone et Email");
    }
  }
  initReport() {
    this.report = true;
    let input = {
      id: this.model._id
    }
  }

  toggleValue(item) {
    item.selected = !item.selected;
  }
  showPreviousStep(event?: Event) {
    this.ngWizardService.previous();
  }

  showNextStep(event?: Event, stepNumber?: any) {
    if (stepNumber == 2) {
      this.showNextStepAttribute();
    }
    if (this.model.name !== "" && stepNumber !== 2) {
      this.ngWizardService.next();
    }
    if (this.model.name == '' || this.model.name == null) {
      this.toastr.error("Le nom  est vide !", '', {
        timeOut: 5000
      });
    }
  }

  createFilterObjects() {
    Object.assign(this.model, { dealerUrl: this.dealerUrl });
  }

  showNextStepAttribute(event?: Event) {
    this.submittedAttr = true;
    this.allowNoValue();
    console.log("this.attributeForm", this.attributeForm);
    console.log('this.attributeForm.valid', this.attributeForm.valid);
    if (this.attributeForm && this.attributeForm.valid) {
      this.createAttribute();
      this.model.dealerUrl = "";
      this.createFilterObjects();
      this.ngWizardService.next();
      window.scrollTo(0, 0);
    } else {
      this.toastr.error("Les attributs lead sont inexistants ou mal remplis", '', {
        timeOut: 5000
      });
    }
  }

  allowNoValue() {
    if (this.attributeForm.controls.typeOfInterest.value === null) {
      this.attributeForm.controls.typeOfInterest.setValidators(null);
    } else {
      this.attributeForm.controls.typeOfInterest.setValidators(Validators.required);
    }
    this.attributeForm.controls.typeOfInterest.updateValueAndValidity({ emitEvent: false });
    if (this.attributeForm.controls.subTypeOfInterest.value === null) {
      this.attributeForm.controls.subTypeOfInterest.setValidators(null);
    } else {
      this.attributeForm.controls.subTypeOfInterest.setValidators(Validators.required);
    }
    this.attributeForm.controls.subTypeOfInterest.updateValueAndValidity({ emitEvent: false });
  }
  resetWizard(event?: Event) {
    this.ngWizardService.reset();
  }
  setTheme(theme: THEME) {
    this.ngWizardService.theme(theme);
  }
  stepChanged(args: StepChangedArgs) {
  }

  onchangeTempo(e) {
    if (e.target.defaultValue === "Temporisation d’intégration des leads" || !e.target.checked) {
      this.configForm.controls.dateTempo.setValue(null);
      this.configForm.controls.hourMinute.setValue(null);
      this.configForm.controls.dateHour.setValue(null);
      this.isHourMinute = false;
      this.isHours = false;
      this.isDateHour = false;
      for (let temp of this.temporisationTimesList) {
        if (temp.label == 'sélectionnez un choix') {
          temp.selected = true;
        } else {
          temp.selected = false;
        }
      }
    } 
    
    if(e.target.defaultValue === "Notification center" && e.target.checked ==true){
      this.isNotificationChecked=true
    } 
    
    if(e.target.defaultValue === "Notification center" && e.target.checked ==false){
      this.isNotificationChecked=false;
      this.isNotifSmsOrEmail=false
    }
  }

  onChangeNotif(e){
    if(this.configForm.get('notifCenterValue').value ==='Notification Email ou SMS'){
      this.isNotifSmsOrEmail=true
    } else{
      this.isNotifSmsOrEmail=false;
      this.configForm.get('smsorEMailOption').setValue(null)
    }
    for(let notifOption of notifcenterOptions){
      if(this.configForm.get('notifCenterValue').value ===notifOption.value){
        notifOption.checked = true;
      } else {
        notifOption.checked = false;
      }
    }

    console.log(' notifcenterOptions',  this.notifcenterOptions)
   
  }

  onChangeSmsOrEmail($event){
    for(let notifOption of smsEmailOptions){
      if(this.configForm.get('smsorEMailOption').value ===notifOption.value){
        notifOption.checked = true;
      } else {
        notifOption.checked = false;
      }
    }

    console.log('smsEmailOptions',  this.smsEmailOptions)
  }
  ChangeFile(e) {
    if (e.target.files[0]) {
      this.fileDealer = e.target.files[0];
    }
  }

  async OnUploadFile() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    if (this.fileDealer) {
      const fd = new FormData();
      fd.append('file', this.fileDealer, this.fileDealer.name);
      this.datacatchingService.postApi(`${ENV.API_URL}dealer`, fd, isTokenNotExpired, 'file')
        .subscribe(res => {
          if (res['status'] === 200) {
           
          } 
        })
    }
  }

  async UploadCars(e) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    const fd = new FormData();
    let tempCar: any = [];
    let tempMod: any = [];
    let tempCarSort: any = [];
    let tempEner: any = [];
    if (e.target.files[0]) {
      this.fileCarsWish = e.target.files[0];
      fd.append('file', this.fileCarsWish, this.fileCarsWish.name)
      this.datacatchingService.postApi(`${ENV.API_URL}carsWished`, fd, isTokenNotExpired, 'file').subscribe(res => {
        this.listCarsModeleWish = res['results'];
        if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
        let results = res['results'];
        for (let key of Object.keys(results)) {
          if (results[key].MARQUE !== undefined && results[key].MARQUE !== null && results[key].MARQUE != '') {
            tempCar.push(results[key].MARQUE);
          }
          if (results[key].MODELE !== undefined && results[key].MODELE !== null && results[key].MODELE != '') {
            tempMod.push(results[key].MODELE);
          }
          if (results[key].ENERGIE !== undefined && results[key].ENERGIE !== null && results[key].ENERGIE != '') {
            tempEner.push(results[key].ENERGIE);
          }
          if (results[key].MODELE_SEMI_CLAIRE !== undefined && results[key].ENERGIE !== null && results[key].ENERGIE != '') {
            tempEner.push(results[key].ENERGIE);
          }
          tempCarSort.push(results[key].MARQUE);
        }
        this.listCarsWish = [...new Set(tempCar.sort())];
        this.listModeleWish = [...new Set(tempMod.sort())];
        this.listBrandWishSort = [...new Set(tempCar.sort())];
        this.listModeleWish = [...new Set(tempMod.sort())];
        this.ref.detectChanges()
        if (tempEner !== undefined && tempEner !== null && tempEner.length > 0) {
          this.listEnerWish = [...new Set(tempEner.sort())];
        }
      })
    }
  }

  async UploadVehicule(e) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    const fd = new FormData();
    let tempCar: any = [];
    let tempMod: any = [];
    let tempEner: any = [];
    if (e.target.files[0]) {
      this.fileCars = e.target.files[0];
      fd.append('file', this.fileCars, this.fileCars.name);
      this.datacatchingService.postApi(`${ENV.API_URL}carsWished`, fd, isTokenNotExpired, 'file').subscribe((res): any => {
        this.listEner = [];
        let results = res['results'];
        this.listCarsModeleEner = results;
        console.log('****this.listCarsModeleEner*****', this.listCarsModeleEner)
        for (let key of Object.keys(results)) {
          if (results[key].MARQUE !== undefined && results[key].MARQUE !== null && results[key].MARQUE !== '') {
            tempCar.push(results[key].MARQUE);
          }
          if (results[key].MARQUE !== undefined && results[key].MARQUE !== null && results[key].MARQUE !== '') {
            tempCar.push(results[key].MARQUE);
          }
          if (results[key].MODELE !== undefined && results[key].MODELE !== null && results[key].MODELE !== '') {
            tempMod.push(results[key].MODELE);
          }
          if (results[key].ENERGIE !== undefined && results[key].ENERGIE !== null && results[key].ENERGIE != '') {
            tempEner.push(results[key].ENERGIE);
          }
        }
        this.listCarsInterest = [...new Set(tempCar.sort())];
        this.listModelInterest = [...new Set(tempMod.sort())];
        this.ref.detectChanges()
        console.log('listModelInterest', this.listCarsInterest)
        console.log('listModelInterest', this.listModelInterest)
        if (tempEner !== undefined && tempEner !== null && tempEner.length > 0) {
          this.listEner = [...new Set(tempEner.sort())];
        }
        console.log(" this.listEner", this.listEner)
      })
     
    }
  }
  async bannerEdit(file) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    if (file) {
      if (file !== null && file !== undefined) {
        let baseImage = await this.datacatchingService.getApi(`${ENV.API_URL}bimage/${file}`, isTokenNotExpired, 'logo').toPromise();
        if (baseImage !== undefined && baseImage !== null && baseImage['image'] !== "notFound") {
          let objectURL = baseImage['image'];
          this.model.theme.bannerImage = file;
          this.imgURL = this.sanitizer.bypassSecurityTrustResourceUrl(objectURL);
          this.isEditBannerImg = true;
          this.ref.detectChanges();
        } 
      }
    }
  }

  removeImage() {
    this.errorBanner = false;
    this.isValidBannerImg = false;
    this.isEditBannerImg = false;
    this.bannerInput.nativeElement.value = '';
    this.model.theme.bannerImage = "";
    this.imgURL = ""
    // element.value = "";
  }

  disabledDateEnd(e) {
    if (e.target.value) {
      this.isDisabledEnd = false;
      this.ref.detectChanges();
      this.mindateEnd = e.target.value;

    }
  }

  //
  composeTypeBrandSelect(e) {
    let brandValue = 'RENAULT';
    if (e.target.value && (e.target.value.toUpperCase() == 'RENAULT' || e.target.value.toUpperCase() == 'DACIA' || e.target.value.toUpperCase() == 'ALPINE')) {
      brandValue = e.target.value;
    }
    // this.attributeForm.controls['marque-lead'].setValue('RENAULT');
    for (let leadAttr of this.model.attributes) {
      if (leadAttr.type === 'marque-lead') {
        leadAttr.valueSelected = brandValue;
      }
    }
  }
  onChangeTempoType(event) {
    if (event.target.value == "hours") {
      this.isHours = true;
      this.isHourMinute = false;
      this.isDateHour = false;
      this.configForm.controls.hourMinute.setValue(null);
      this.configForm.controls.dateHour.setValue(null);
      for (let item of this.temporisationTimesList) {
        if (item.value == event.target.value) {
          item['selected'] = true;
        }
        if (item.value != event.target.value) {
          item['selected'] = false;
        }
      }
    }
    if (event.target.value == "hourMinutes") {
      this.isHours = false;
      this.isHourMinute = true;
      this.isDateHour = false;
      this.configForm.controls.dateTempo.setValue(null);
      this.configForm.controls.dateHour.setValue(null);
      for (let item of this.temporisationTimesList) {
        if (item.value == event.target.value) {
          item['selected'] = true;
        }
        if (item.value != event.target.value) {
          item['selected'] = false;
        }
      }
    }
    if (event.target.value == "dateHour") {
      this.isHours = false;
      this.isHourMinute = false;
      this.isDateHour = true;
      this.configForm.controls.hourMinute.setValue(null);
      this.configForm.controls.dateTempo.setValue(null);
      for (let item of this.temporisationTimesList) {
        if (item.value == event.target.value) {
          item['selected'] = true;
        }
        if (item.value != event.target.value) {
          item['selected'] = false;
        }
      }
    }
    Object.assign(this.model, { temporisationTimesList: this.temporisationTimesList }, { isHours: this.isHours }, { isHourMinute: this.isHourMinute }, { isDateHour: this.isDateHour });
  }

  onDateTimeTempChange(e) {
    if (e.target.value !== "") {
      let selectedDate = new Date(e.target.value).toISOString().substring(0, 16);
      //if event end date is less than selected date 
      if (this.eventInfoForm.get('dateEnd').value !== null && this.eventInfoForm.get('dateEnd').value !== '') {
        let eventEndDate = this.eventInfoForm.get('dateEnd').value;
        if (new Date(selectedDate).getTime() < new Date(eventEndDate).getTime()) {
          this.invalidDateTimeTempo = true;
        }
      }
      //if selected date is less than current time
      if (new Date(selectedDate).getTime() < new Date(new Date().toISOString().substring(0, 16)).getTime()) {
        this.invalidDateTimeTempo = true;
      } else {
        this.invalidDateTimeTempo = false;
      }
    } else {
      this.invalidDateTimeTempo = true;
    }
   
   
  }
  onEventEndDateChange(e) {
    if (e.target.value !== '' && e.target.value !== null) {
      let date = new Date(e.target.value);
      date.setDate(date.getDate() + 1);
      this.minDateTempo = new Date(date).toISOString().substring(0, 16);
      this.ref.detectChanges();
      let dateTempo = new Date(this.configForm.get('dateHour').value);
      if (this.isEdit) {
        if (dateTempo.getTime() < new Date(e.target.value).getTime()) {
          this.invalidDateTimeTempo = true;
        } else {
          this.invalidDateTimeTempo = false;
        }
      }
    }
    if (e.target.value == '') {
      this.minDateTempo = new Date().toISOString().substring(0, 16);
      
      let selectedDate = new Date(this.configForm.get('dateHour').value).toISOString().substring(0, 16);
      if (new Date(selectedDate).getTime() < new Date(new Date().toISOString().substring(0, 16)).getTime()) {
        this.invalidDateTimeTempo = true;
      } else {
        this.invalidDateTimeTempo = false;
      }
    }
  }

  emailAndPhoneInEvent() {
    let isAlreadyInArr = [];
    let keys = this.model.attributes.filter(attr => attr.type).map(at => at.type);
    const values = ['phone', 'email'];
    this.isPhoneAndEmailExist = values.every(value => {
      return keys.includes(value);
    });
    let index = keys.indexOf(values[1]);
    if (this.isPhoneAndEmailExist) {
      let obj = {
        "type": "radio",
        "icon": "fa-list-ul",
        "label": "Configuration du Phone et Email",
        "specific": false,
        "required": false,
        "description": "radio",
        "formControlName": "radio",
        "leadRequired": "",
        "errorText": "Merci de remplir ce champs",
        "hide": true,
        "valueSelected": "Personnalisé",
        "values": [
          {
            "label": "Personnalisé",
            "value": "Personnalisé"
          },
          {
            "label": "Standard",
            "value": "Standard"
          }
          
        ]
      }
      isAlreadyInArr = this.model.attributes.filter(attr => attr.label === "Configuration du Phone et Email");

      if (isAlreadyInArr.length === 0) {
        this.model.attributes.splice(index + 1, 0, obj);
        this.applyPersonaliserSettingstoEmailAndPhone(this.isPhoneAndEmailExist, obj);
      };
      if (isAlreadyInArr.length !== 0) {
        this.applyPersonaliserSettingstoEmailAndPhone(this.isPhoneAndEmailExist, isAlreadyInArr[0]);
        let objectEmailPhone = isAlreadyInArr[0];
        if (objectEmailPhone.valueSelected === "Personnalisé") {
        }
      }
    }
  }

  onEmailPhoneChange(selectedValue) {
    this.emailAndPhoneInEvent();
    
  }

  applyPersonaliserSettingstoEmailAndPhone(isPhoneEmailExist, configObj) {
    if (isPhoneEmailExist && configObj.valueSelected === 'Personnalisé') {
      for (let attr of this.model.attributes) {
        if (attr.type === "phone" || attr.type == 'email') {
          attr.hideRequired = true;
          attr.required = true;
        }
      }
    }

    if (isPhoneEmailExist && configObj.valueSelected === 'Standard') {
      for (let attr of this.model.attributes) {
        if (attr.type === "phone" || attr.type == 'email') {
          attr.hideRequired = false;
        }
      }
      
    }
  }
  onChangeVenduer($event) {
    this.isDisplayVendeur = $event.target.checked
  }
  onChangeCampagne($event) {
    this.isDisplayCampagne = $event.target.checked
  }
  onDealerUrlChange(url) {
    console.log("url", url);
  }
  
  async onMentionLegale(event) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isProcessingFile = true
    this.selectedLegalesFile = <File>event.target.files[0];

    const formData = new FormData();
    let fileName = this.model.name;
    fileName = fileName.replaceAll(" ", "-");
    var d = new Date();
    var n = d.getTime();
    let fName = this.selectedLegalesFile.name.split('.');
    this.originalPdfFileName = fName[0] + '-' + n + '.pdf';
    formData.append('file', this.selectedLegalesFile, fName[0] + '-' + n + '.pdf');
    this.datacatchingService.postApi(`${ENV.API_URL}legals`, formData, isTokenNotExpired, 'file').subscribe(res => {
      this.legalesFilesPath = res['file_name'];
      this.pdf_file_path = res['path'];
  
      for (let attr of this.model.attributes) {
        let nameOfFile = "";
        if (attr.type == "mentions") {
          attr.label = res['file_name'];
          attr.pdf_file_path = res['file_name'];
          nameOfFile = res['file_name'];
          let file = nameOfFile.split('.');
          attr.fileNameWithOutPdf = file[0];
          this.fileNameToDownload = file[0];
          this.isProcessingFile = false
          this.ref.detectChanges();
        }
      }
    })
  }
  async removeExcelFile(affaireExcelFileName) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isActionLogo = true;
    this.datacatchingService.deleteApi(`${ENV.API_URL}delete_affaire_excel_file/${affaireExcelFileName}`, isTokenNotExpired, 'normal').subscribe(res => {
      this.isActionLogo = false;
      if (res['success']) {
        this.affaireExcelFileName = "";
        for (let attr of this.model.attributes) {
          if (attr.type == "dealerConfig") {
            attr.values.affaire_Excel_FileName ="";
            this.ref.detectChanges();
          }
        }
      }
    })
    
  }
  async removeMentionLegals(fName) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isProcessingFile = true;
    this.datacatchingService.getApi(`${ENV.API_URL}verify/legals/${fName}`,isTokenNotExpired,'logo').subscribe(res=>{
      if(res && res['success']){
        this.datacatchingService.deleteApi(`${ENV.API_URL}delete_mention-legals/${fName}`, isTokenNotExpired, 'normal').subscribe(res => {
          if (res['success']) {
            for (let attr of this.model.attributes) {
              if (attr.type == "mentions") {
                attr.pdf_file_path = "";
                attr.fileNameWithOutPdf = '';
                this.isProcessingFile = false;
                this.ref.detectChanges();
              }
            }
          }
        })
      } else {
        //if file does not exist
        this.isProcessingFile = true;
        this.toastr.error(`${res.msg}. veuillez charger un nouveau fichier svp`);
        for (let attr of this.model.attributes) {
          if (attr.type == "mentions") {
            attr.pdf_file_path = "";
            attr.fileNameWithOutPdf = '';
            this.isProcessingFile = false;
            this.ref.detectChanges();
          }
        }
      }
    })
    
  }
  async onClickDownloadPdf(fileName) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}verify/legals/${fileName}`,isTokenNotExpired,'logo').subscribe(res=>{
      if(res && res['success']){
        this.datacatchingService.downloadFile(`${ENV.API_URL}legals/${fileName}`)
      } else {
        this.toastr.error(res.msg)
      }
    })
   
  }
  // call this method when you want the upload to begin
  async uploadAffaireFile(event) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.isActionLogo = true;
    this.excelfileToUpload = <File>event.target.files[0];
    console.log("this.excelfileToUpload", this.excelfileToUpload)
    let fileName = this.excelfileToUpload.name.replaceAll(" ", "-").split(".");
    const formData = new FormData();
    var timeStamp = new Date().getTime();
    formData.append('file', this.excelfileToUpload, `${fileName[0]}-${timeStamp }.xlsx` );
    this.datacatchingService.postApi(`${ENV.API_URL}codepostals`, formData, isTokenNotExpired, 'file').subscribe(res => { 
      this.isActionLogo = false;
      this.affaireExcelFileName = res['file_name'];
      for (let attr of this.model.attributes) {
        if (attr.type == "dealerConfig") {
          attr.values.affaire_Excel_FileName = res['file_name'] 
          // attr.values.affaire_Excel_FileName = res['file_name'];
          console.log('attr', attr);
            this.ref.detectChanges();
          }
        }

    })

   
  }
  getValue(value) {
    return decode(value);
  }
  updateValue(value, item, i) {
    for (let model of this.model.attributes) {
      if (model.formControlName === item.formControlName) {
        item.values[i].value = this.getValue(value);
        this.ref.detectChanges()
      }
    }
  }
  updateLabel(value, item, i) {
    for (let model of this.model.attributes) {
      if (model.formControlName === item.formControlName) {
        if (item.values[i].hasOwnProperty('label')) {
          item.values[i].label = this.getValue(value);
        }
        if (item.values[i].hasOwnProperty('name')) {
          item.values[i].name = this.getValue(value);
        }
        this.ref.detectChanges()
      }
    }
  }
  
  trackElement(index: number, element: any) {
    return element ? element[index] : null;
  }
  async getTranlstions() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/translations`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['translations'].length > 0) {
        console.log("res['translations']", res['translations'])
        this.translationsLanguages = res['translations'].map(obj => obj.language);
        this.fieldModels.map(f => {
          if (f.label == 'Traduction') {
            for (let l of this.translationsLanguages) {
              f.values.push({
                "label": l,
                "value": l
              })
            }
             
          }
        })
      }
    })
  }

  addCustomRegex(value, I) {
    this.listRegex[this.listRegex.length - 1].value = value;
  }
  checkRexEx(regex ) {
    let temp = this.listRegex.filter(re => re.value === regex);
    if (temp.length>0) {
      return true
    } 
    return false;
    
  }
  setRegexCountry(selectedRegexCountry) {
    let regexTranslation = this.regexTransaltions.filter(re => re.country == selectedRegexCountry);
    let result = []
    if (regexTranslation.length > 0) {
      Object.keys(regexTranslation[0].regex_obj).forEach(name => {
        result.push({ name, value: regexTranslation[0].regex_obj[name] })
      })
      this.listRegex = result;
    } 
  }
  onNetworkEventChanged(is_dealer_event) {
    console.log('change event', is_dealer_event);
    this.is_dealer_event = is_dealer_event;
    this.ref.detectChanges()
    if (is_dealer_event === false) {
      this.reseau = [];
      this.selectedItems = [];
    }
  }

  onDealerLeadEventChanged(val) {
    console.log('change event', val);
    this.is_dealer_event_show_lead = val;
    this.ref.detectChanges()
  }

  onItemSelect(item: any) {
    this.reseau = this.selectedItems
  }
  onSelectAll(items: any) {
    this.reseau = items;
    this.selectedItems = items;
  }
  onItemDeSelect($event) {
    this.reseau = this.selectedItems

  }

  onItemTemplateSelect(item:any){
console.log(this.selectedTemplateItems)
  }
  onSelectTemplateAll(item:any){

  }

  updateReseau() {
    console.log(this.selectedItems);
  }
  onEditEventReseau(code_event, reseau, eventName) {
    this.selectedItems = reseau;
  }
  onSelectNetworkEvent(code_event, eventName) {
  }
  onDeSelectAll($event) {
    this.reseau = [];
    this.selectedItems = [];
  }


} 

 
 





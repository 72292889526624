

<div *ngIf="model && !report" class="white-bg app-content content" style='padding-top: 6%;'>
   
    <div class="container" style="max-width: 1200px !important;position: relative !important;" *ngIf="!isProcessing">
        <ng-wizard [config]="isConfig" (stepChanged)="stepChanged($event)">
            <ng-wizard-step [title]="'1'">
                <h5 class="event-text pl-1" *ngIf="!isEdit"> Créer un évènement</h5>
                <h5 class="event-text pl-1" *ngIf="isEdit">Modifier un evènement</h5>
                <!-- style="margin:15% auto !important;padding:0 15px !important;" -->
                <div class="container col-8 mt-3 mb-3">

                    <label for="titre">Titre <sup style='color:#000'>*</sup></label>
                    <input class="form-control mb-2" type="text" id="titre" [(ngModel)]="model.name" />

                    <label for="description">Description</label>
                    <!-- <textarea class="form-control mb-2" cols="5" rows="3" id="description"></textarea> -->
                    <quill-editor id="description" name="description" [styles]="optionsWysywig"
                        [(ngModel)]="model.description"></quill-editor>

                    <label class="mt-1" for="titre">Image de la bannière <small>(format png/jpeg 1200x800 (inférieur ou égal))</small>
                    </label>
                    <div class="bannerInput pt-1 pb-1">
                            <input #bannerFile type="file" id="fileInput" placeholder="choisir un logo" (change)="bannerChanged($event)" />
                             
                        <div class="supp-div" (click)="removeImage()" *ngIf="isValidBannerImg && !isEditBannerImg" style="margin-top: 5px;margin-left: 10px;">
                            <span class="text-danger">
                                <i class="la la-trash"></i>
                            </span>
                        </div>
                        <button (click)="onBannerSubmit()" class="btn btn-secondary btn-sm ml-1"
                            [disabled]="!isValidBannerImg" style="padding: .6rem 1rem !important;">Soumettre</button>
                    </div>
                    <img *ngIf="imgURL" id="fileBanner" class="img-thumbnail mt-2" [src]="imgURL"> <span title='Supprimer' class="ml-3 cursor-pointer" *ngIf="isEditBannerImg && !isValidBannerImg" (click)="removeImage()">
                        <span class="text-danger">
                            <i class="la la-trash"></i>
                        </span>
                    </span>
                    <span class="text-danger" *ngIf="errorBanner">
                        L'image n'est pas conforme, Veuillez choisir une image au format 1200x800 (inférieur ou égal) !
                    </span>

                    <!-- <hr/> -->

                    <h5 class="sub-heading mt-2">Informations de l'évènement</h5>
                    <form [formGroup]="eventInfoForm" class="mb-2">
                        <div class="form-row  mt-2">
                            <div class="form-group col-6">
                                <div>
                                    <label>Date de début</label>
                                    <div class="input-group">
                                        <input type="date" class="form-control" placeholder="jj/mm/aaaa"
                                            (change)="disabledDateEnd($event)" [min]="minDate" name="dateStart"
                                            formControlName="dateStart">
                                        <!-- <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-6">
                                <div>
                                    <label>Date de fin</label>
                                    <div class="input-group">
                                        <input type="date" class="form-control" placeholder="jj/mm/aaaa"
                                            [disabled]="isDisabledEnd" [min]="mindateEnd" name="dateEnd" (change)="onEventEndDateChange($event)"
                                            formControlName="dateEnd">
                                        <!-- <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button"><i class="far fa-calendar-alt"></i></button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngFor="let info of eventInfo">
                            <div class="form-group">
                                <label for="inputType">{{info.label}}</label>
                                <select  class="form-control" formControlName="{{info.formControlName}}">
                                    <option selected disabled value="null">Choisissez une valeur</option>
                                    <option *ngFor="let v of info.value" [value]="v.value">{{v.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputsubPeri">Sous-périmètre</label>
                            <input type="text" class="form-control" formControlName="subPerimetre" id="inputsubPeri"
                                placeholder="">
                        </div>
                        <div class="form-group">
                            <label for="inputContext">Contexte</label>
                            <textarea class="form-control form-control-textarea" id="inputContext" rows="6" cols="100"
                                formControlName="context" style="min-height: 80px;resize: vertical;"></textarea>
                        </div>
                        <form [formGroup]="logoForm"  class="mb-2 logo-container" *ngIf="logoForm && !isProcessing">
                            <label>Choix du logo <span *ngIf="isActionLogo"  class="m-auto text-light ml-2">
                            <span class="loader ml-2"></span><span> Traitement en cours...</span> 
                            </span></label>
                            <div class="row mb-2" formArrayName="selectedLogo">
                                <div class="col-4 align-self-center d-flex " *ngFor="let control of logoForm?.get('selectedLogo')['controls'];let i=index">
                                    <span class="inline-form-group d-flex justify-content-center align-items-center mt-1 img-thumbnail p-1"  [formGroupName]="i">
                                        <label class="cust-check-bx justify-content-center align-items-center">
                                            <input type="checkbox" 
                                                formControlName="checked" [checked]="control.value.checked" (change)="isEdit ? onLogoChangeLogo(control.value.checked, control?.value?.event_url_id, control):null">
                                            <img [src]="control?.value?.value" alt="logo" class="pl-2 img-fluid">
                                            <span class="checkmark"></span>
                                        </label>
                                        <span class="m-auto pb-2">
                                        <i class="fas fa-trash ml-1 del-icon cursor-pointer" (click)="deleteLogo(control?.value?.name,i, isEdit ? control.value.file_url_id :control.value.id)"></i>
                                        </span>
                                    </span>
                                </div>
                               
                                
                            </div>
                            <div>
                                
                            </div>
                        <div>
                            <input #inputFile type="file"  id="fileInput" placeholder="choisir un logo" (change)="onLogoChanged($event)" />
                            <button (click)="onSubmit()" class="btn btn-secondary btn-sm ml-1" [disabled]="!islogoFileSelected">
                              <span *ngIf="isLogoSubmit">
                                Soumettre
                              </span > 
                            <span *ngIf="islogoNewSubmit">
                                En traitement....
                            </span>
                            </button>
                            </div>
                            <span class="text-danger" *ngIf="!isValidLogoWidth">
                                La logo n'est pas conforme, Veuillez choisir la logo au format 800x800 !
                            </span>
                        </form>
                        <h5 class="sub-heading">Informations du responsable de l'évènement</h5>
                        <div formGroupName="responsible">
                            <div class="form-row mt-2">
                                <div class="form-group col-6">
                                    <div>
                                        <label for="inputName">Nom</label>
                                        <input type="text" class="form-control" formControlName="lastname"
                                            id="inputName" placeholder="">
                                    </div>
                                </div>
                                <div class="form-group col-6">
                                    <div>
                                        <label for="inputFirstName">Prénom</label>
                                        <input type="text" class="form-control" formControlName="firstname"
                                            id="inputFirstName" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="inputEmail">Email</label>
                                <input type="text" class="form-control" formControlName="email" id="inputEmail"
                                    placeholder="">
                            </div>
                            <div class="form-group">
                                <label for="inputPhone">Téléphone</label>
                                <input type="text" class="form-control" formControlName="phone" id="inputPhone"
                                    placeholder="">
                            </div>
                            <div class="form-group">
                                <label for="inputFunction">Fonction</label>
                                <input type="text" class="form-control" formControlName="function" id="inputFunction"
                                    placeholder="">
                            </div>
                            <div class="form-group">
                                <label for="inputEntity">Entité</label>
                                <input type="text" class="form-control" formControlName="entity" id="inputEntity"
                                    placeholder="">
                            </div>
                        </div>
                    </form>

                    <div class="container pl-0">
                        <div class="row mt-2">
                            
                            <div class="wrapper col second-wrapper"
                                style="padding-left:0 !important;padding-right:0!important;">
                                <div class="icon">
                                    <div (click)="showNextStep($event)">
                                        <i class="la la-plus-circle" style="font-size: 3rem;"></i>
                                    </div>
                                </div>
                                <div class="text" (click)="showNextStep($event,1)">Configurer l’évènement</div>
                            </div>
                        </div>
                    </div>
                    <div class="btn-container pt-4">
                        <div class="btn-cancel">
                            <button (click)="onCancal()"> Annuler</button>
                        </div>
                    </div>
                </div>

            </ng-wizard-step>
            <!-- Création -->
            <ng-wizard-step [title]="2">
                <h5 class="event-text pl-1">Choissisez les évènements</h5>
                <!-- style="margin:15% auto 0 0 !important;padding:0 15px !important;" -->
                <div class="container mt-3 mb-3">
                    <div class="row">
                        <div class="col-md-4 col-xs-12 col-sm-12 p-1 pb-3" [ngClass]="{'col-md-12':isTablet}">
                            <div class="field-container mt-1" id="field-id" (scroll)="onScroll($event)">
                                <br>
                                <div class="fields" *ngFor="let item of fieldModels" id="desktop-drag-container"
                                    [ngClass]="{'d-none':item.specific}">
                                    <div *ngIf="item && !item.specific && !item.isLead"
                                        class="field pointer shadow-bg btn-primary" [dndDraggable]="item" [dndEffectAllowed]="'copy'"
                                        (dndEnd)="onDragEnd($event)">
                                        <i [ngClass]="item.icon" class="pull-left fa"></i>
                                        <h5>{{item.label}}</h5>
                                    </div>
                                </div>
                                <!-- mobile -->
                                <div class="fields" *ngFor="let item of fieldModels" id="mobile-drag-container">
                                    <div *ngIf="!item.isLead" class="field pointer shadow-bg btn-primary" (click)="onClick(item.label)">
                                        <i [ngClass]="item.icon" class="pull-left fa icon-mobile"></i>
                                        <h5>{{item.label}}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                
                        <div class="col-md-8 col-xs-12 col-sm-12 ml-sm-0 mb-lg-1 mb-sm-1 mb-xs-1" id="drop-container"
                            [ngClass]="{'col-md-12':isTablet}">
                            <div class="form-group" id="drop-area" style="margin-bottom: 0px !important; padding: 45px 20px 20px 20px;"
                                [style.backgroundColor]="model.theme.bgColor" [style.color]="model.theme.textColor">
                               
                                <p [hidden]="model.attributes.length" style="text-align: center;color: #ccc;"> Déposer
                                    dans
                                    la zone rectangle </p>
                
                                <section dndDropzone (dndDragover)="onDragover($event)" (dndDrop)="onDrop($event,model.attributes)"
                                    class="dndDropArea" [ngClass]="{'empty':model.attributes.length == 0}"
                                    [style.backgroundColor]="model.theme.bgColor" [style.color]="model.theme.textColor"
                                    id="desktop-drop-container">
                                    <div class="field" *ngFor="let item of model.attributes;let i= index;"
                                        (dndStart)="onDragStart($event)" (dndCanceled)="onDragCanceled($event)"
                                        (dndMoved)="onDragged(item, model.attributes, 'move')" [dndEffectAllowed]="'all'"
                                        [dndDraggable]="item">
                                        <div class="row form-group"
                                            *ngIf="!(item.type=='dealerConfig' || item.type=='config' || item.type=='wiredKeyLead' || item.type=='LeadCompose')">
                                            <div class="col-1" style="padding-left: 25px;"><i class="fa fa-ellipsis-v dndHandle"
                                                    dndHandle></i>
                                            </div>
                                            <div class="col-10">
                                                
                
                                                <div *ngIf="item.type=='text' || item.type=='libelle'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="text" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </div>
                                                <div *ngIf="item.type=='raisonsociale'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="text" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}" [(ngModel)]="item.value"
                                                        [required]="item.required" [ngModelOptions]="{standalone: true}"
                                                        autocomplete="off">
                                                </div>
                                                <div *ngIf="item.type=='siret'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="text" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}" [(ngModel)]="item.value"
                                                        [required]="item.required" [ngModelOptions]="{standalone: true}"
                                                        autocomplete="off">
                                                </div>
                                                <div *ngIf="item.type=='Question'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <select class="form-control" id="{{item.name}}">
                                                        <option value="Choisissez une réponse" disabled selected>
                                                            Choisissez
                                                            un réponse
                                                        </option>
                                                        <option *ngFor="let v of item.values" [value]="v.value">
                                                            {{v.label}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <div *ngIf="item.type=='email'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="email" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </div>
                                                <div *ngIf="item.type=='phone'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="text" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </div>
                                                <div *ngIf="item.type=='number'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="number" class="form-control" id="{{item.name}}" min="{{item.min}}"
                                                        max="{{item.max}}" placeholder="{{item.placeholder}}">
                                                </div>
                                                <div *ngIf="item.type=='GDPR'">
                
                                                    <div *ngFor="let it of item.values;let i=index;" class="form-group mt-1 mb-1">
                                                        <div class="row">
                                                            <div class="col-10">
                                                                <label class="cust-check-bx" class="flex">
                                                                    <span class="pr-2"> {{it.label}}<span *ngIf="it.required">*</span>
                                                                    </span>
                                                                    <span *ngFor="let gv of it.valuesGdpr">
                                                                        <input *ngIf="gv.type!=='text'" type="radio" class="ml-1"
                                                                            [name]="gv.name" [value]="gv.value">
                                                                        {{gv.label}}
                                                                    </span>
                                                                    <span class="checkmark"></span>
                                                                </label>
                
                                                            </div>
                                                            <div class="chevron col-2">
                                                                <i *ngIf="it.toggle" (click)="it.toggle=false"
                                                                    class="fa fa-chevron-up pull-right"></i>
                                                                <i *ngIf="!it.toggle" (click)="it.toggle=true"
                                                                    class="fa fa-chevron-down  pl-1 pull-right"></i>
                                                                <i *ngIf='it.isInput' (click)="removeInput(i,item.type)"
                                                                    class="fa fa-trash pull-right pl-1"></i>
                                                            </div>
                
                                                        </div>
                
                
                                                        <div *ngIf="it.toggle" class="toggle-Wrapper">
                                                            <div class="form-group" *ngIf='it.isInput'>
                                                                <label class="mr-2">Required</label>
                                                                <i *ngIf="it.required" (click)="it.required=false"
                                                                    class="fa fa-toggle-on red"></i>
                                                                <i *ngIf="!it.required" (click)="it.required=true"
                                                                    class="fa fa-toggle-off"></i>
                                                                <span class="pull-right ucfirst"> {{item.type}}</span>
                                                            </div>
                                                            <div class="form-group" *ngIf="it.isInput">
                                                                <label>Type</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.type"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Label</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.label"
                                                                    [ngModelOptions]="{standalone: true}" />
                
                                                            </div>
                
                                                            <div class="form-group" *ngIf="it.isInput">
                                                                <label>Famille wired</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.leadRequired"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group" *ngIf="it.isInput">
                                                                <label>Clé wired</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.formControlName"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group" *ngIf="it.regex && it.isInput">
                                                                <label>Regex</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.regex"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" [checked]="it.hide"
                                                                    (change)="it.hide = !it.hide" type="checkbox"  [(ngModel)]="it.hide"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                                <label class="form-check-label">Voulez-vous cachez cet
                                                                    item
                                                                    ?</label>
                                                            </div>
                                                            <div *ngIf="it.isInput" class="form-group">
                                                                <label>Error text</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.errorText"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                
                                                            <!-- radio || checkbox || autocomplete -->
                                                            <div *ngIf="it.isInput">
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Label</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Value</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label>Action</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngFor="let i of it.valuesGdpr; let valueIndex=index;" class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="i.name"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="i.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="it.valuesGdpr.splice(valueIndex,1)"><i
                                                                                    class="fa fa-times-circle  text-danger"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="value.name"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="value.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="addValue(it.valuesGdpr)"><i
                                                                                    class="fa fa-plus-circle text-success"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-1">
                                                        <button class="btn btn btn-outline-secondary btn-sm" (click)="addAccord()"><i
                                                                class="fas fa-plus mr-1"></i>Ajouter Accord</button>
                                                    </div>
                
                
                
                                                </div>
                                                <div *ngIf="item.type=='date'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="date" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </div>
                                                <div *ngIf="item.type=='datetime-local'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="datetime-local" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                </div>
                                                <div *ngIf="item.type=='textarea'" class="form-group">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <textarea class="form-control" id="{{item.name}}" placeholder="{{item.placeholder}}"
                                                        rows="6" style="min-height: 80px;resize: vertical;">
                                                                                              </textarea>
                                                </div>
                                                <div *ngIf="item.type=='file'">
                                                    <label>{{item.label}} </label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <input type="file" class="form-control" id="{{item.name}}"
                                                        placeholder="{{item.placeholder}}">
                                                    <small class="form-text text-danger"></small>
                                                </div>
                                                <div *ngIf="item.type=='paragraph'">
                                                    <quill-editor id="paragraph" name="paragraph" [styles]="optionsWysywig"
                                                        [(ngModel)]="item.text" [ngModelOptions]="{standalone: true}">
                                                    </quill-editor>
                                                </div>
                                                <div
                                                    *ngIf="item.type=='autocomplete'">
                                                    <label>{{item.label}}</label>
                                                    <label *ngIf="item.required" class="text-danger">*</label>
                                                    <select class="form-control" type="text" id="{{item.name}}" (change)="item.isTranslateRegexInput? setRegexCountry(item.valueSelected) : undefined"
                                                        [(ngModel)]="item.valueSelected" [ngModelOptions]="{standalone: true}">
                                                        <option value="">
                                                            Choisissez
                                                            un champ
                                                        </option>
                                                        <option *ngFor="let v of item.values " [ngValue]="v.value">
                                                            {{getValue(v.label)}}</option>
                                                    </select>
                                                </div>
                                                <div *ngIf="item.type=='button'" class="btn-cont mt-1 mb-1">
                                                    <input type="{{item.subtype}}" value="{{item.label}}" class="btn btn-primary" id="{{item.name}}">
                                                </div>
                                                <form [formGroup]="attributeForm" >
                                                    <div *ngIf="item.type=='demande-lead' || (item.type=='demande' && item.isLead) || (item.type=='marque' && item.isLead) || item.type=='marque-lead' || item.type=='leadCountry' || item.type=='source' || item.type=='subType' || item.type=='context' || item.type=='origin' || item.type=='campaign' || item.type=='device' || item.type=='leadProvider' || item.type=='formName' || item.type=='destination'">
                                                        <label>{{item.label}}</label>
                                                        <label *ngIf="item.required" class="text-danger">*</label>
                                                        <select class="form-control"  type="text" id="{{item.name}}" formControlName="{{item.formControlName}}" [(ngModel)]="item.valueSelected" >
                                                            <option value="null"  selected>
                                                                Choisissez une option
                                                            </option>
                                                            <option *ngFor="let v of item.values" id="{{v.value}}" value="{{v.value}}">
                                                                {{v.name || v.label }}</option>
                                                        </select>
                                                    </div>
                                                <div class="form-group" *ngIf="item.type=='dealer'">
                                                    <label for="dealersType">{{item.label}} <label *ngIf="item.required" class="text-danger">*</label> </label>
                                                    <select id="dealersType" (change)="onChangeDealerLocator($event)" name="{{item.formControlName}}"
                                                        class="form-control" formControlName="{{item.formControlName}}">
                                                        <option value="null" [selected]="isEdit===false" disabled selected>
                                                            Choisissez un
                                                            champ
                                                        </option>
                                                        <option *ngFor="let opt of  item.values" [value]="opt.value">
                                                            {{opt.name}}
                                                        </option>
                                                    </select>
                                                    <div *ngIf="isMultidealers" class="mt-1 ml-1">
                                                        <input type="checkbox" [checked]="isDisplayVendeur" (change)="onChangeVenduer($event)" name="vendeur" > Afficher le vendeur
                                                        <input type="checkbox" class="ml-3" [checked]="isDisplayCampagne" (change)="onChangeCampagne($event)" name="campagne"> Afficher le champ campagne
                                                        
                                                    </div>
                                                    <div *ngIf="isApiwired && !isMultidealers" class="m-2 mt-3">
                                                        <h6>Dealer Url</h6>
                                                    <div >
                                                        <textarea class="form-control" style="min-height: 80px;resize: vertical;"  [(ngModel)]="dealerUrl" [ngModelOptions]="{standalone: true}" name="story" rows="6" ></textarea>
                                                    </div>
                                                   
                                                        <div *ngIf="!affaireExcelFileName">
                                                            <label for="avatar">Choisissez un fichier</label> <br>
                                                            <input type="file" accept=".xlsx" id="customFile" name="myfile" *ngIf="!affaireExcelFileName"
                                                                (change)="uploadAffaireFile($event)"> <span *ngIf="isActionLogo"  class="m-auto text-light ml-2">
                                                                    <span class="loader ml-2"></span><span> Traitement en cours...</span>
                                                                </span>
                                                        </div>
                                                        
                                                        
                                                        <button *ngIf="affaireExcelFileName" class="btn btn-secondary btn-sm mt-1" (click)="downLoadAffaireFile(affaireExcelFileName)">
                                                            {{affaireExcelFileName}}
                                                        </button>
                                                       <span *ngIf="affaireExcelFileName">
                                                        <i  (click)="removeExcelFile(affaireExcelFileName)" class="fa fa-trash ml-1 mt-1 mr-1"></i>
                                                        <span *ngIf="isActionLogo" class="m-auto text-light ml-2">
                                                            <span class="loader ml-2"></span><span> Traitement en cours...</span>
                                                        </span>
                                                       </span>
                                                       
                                                            <a download="template.xlsx" class="btn btn-secondary btn-lg active mt-1" role="button" target="_blank" href="../../../assets/excel/template.xlsx" style=" padding: 8px 10px !important;">
                                                            Télécharger le modèle <img src="../../../assets/icons/download-file-icon.svg" width="22" height="10"/>
                                                            </a>
                                                    
                                                    </div>
                                                    <div *ngIf="!isApiwired && (isFichierExcel || isFichierExcelOffline) && !isMultidealers">
                                                        <label>Fichier</label>
                                                        <input type="file" name="file" class="form-control" (change)="ChangeFile($event)">
                                                    </div>
                                                    <div *ngIf="isListdealers && !isMultidealers">
                                                        <div class="row form-group pt-1 pb-1">
                                                            <div class="col-11">
                                                                <label>{{listDealers[0].label}}</label> 
                                                                <!-- <label *ngIf="listDealers[0].required || item.required"
                                                                    class="text-danger">*</label> -->
                                                                <select class="form-control" (change)="onListDealersChange($event,'dealerOfInterest')"  [ngModelOptions]="{standalone: true}" [(ngModel)]="listDealers[0].valueSelected">
                                                                    <option *ngFor="let v of listDealers[0].values" [value]="v.value">
                                                                        {{v.label}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <!-- <div class="col-1 pt-3">
                                                                <i *ngIf="listDealers[0]" (click)="listDealers[0]" class="fa fa-chevron-up pull-right"></i>
                                                                <i *ngIf="!listDealers[0]" (click)="listDealers[0]" class="fa fa-chevron-down pull-right"></i>
                                                            </div> -->
                                                        </div>
                                                        <div *ngIf="listDealers[0]" class="toggle-Wrapper m-1">
                                                            <!-- <div class="form-group">
                                                                <label class="mr-2">Required</label>
                                                                <i *ngIf="listDealers[0].required && item.required" (click)="listDealers[0].required=false;item.required=false"
                                                                    class="fa fa-toggle-on red"></i>
                                                                <i *ngIf="!listDealers[0].required && !item.required" (click)="listDealers[0].required=true;item.required=true" class="fa fa-toggle-off"></i>
                                                                <span class="pull-right ucfirst pl-1">{{listDealers[0].type}}</span>
                                                            </div> -->
                                                            <div class="form-group">
                                                                <label>Label</label>
                                                                <input class="form-control" type="text" [(ngModel)]="listDealers[0].label"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>placeholder</label>
                                                                <input class="form-control" type="text" [(ngModel)]="listDealers[0].placeholder" [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Name</label>
                                                                <input class="form-control" type="text" [(ngModel)]="listDealers[0].name"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                
                                                            <div>
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Label</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Value</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label>Action</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngFor="let i of listDealers[0].values; let valueIndex=index;" class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="i.label"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="i.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="listDealers[0].values.splice(valueIndex,1)"><i
                                                                                    class="fa fa-times-circle  text-danger" aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="value.label"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="value.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="addValue(listDealers[0].values)"><i class="fa fa-plus-circle text-success"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                    <div *ngIf="isUnDealer && !isMultidealers">
                                                        <div class="m-1 p-1" style="background: #dcdcdc2e">
                                                            <div *ngFor="let v of oneDealer" class="pb-1">
                                                                <label>{{v.label}}</label> <label *ngIf="v.required" class="text-danger">*</label>
                                                                <input type="text" class="form-control" id="{{v.name}}" [(ngModel)]="v.value"
                                                                    [ngModelOptions]="{standalone: true}" placeholder="{{v.placeholder}}">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                              </form>
                                                <div *ngIf="item.type==='composeType'">
                                                    <input name="filevehicule" type="file" (change)="UploadVehicule($event)" id='test-12'>
                                                    <div *ngIf="listCarsInterest.length && listModelInterest.length">
                                                        <div *ngFor='let type of item.detailValues'>
                                                            <div *ngFor='let val of type.detailMarque'>
                                                                <label>{{val.label}}</label> <label *ngIf="val.required"
                                                                    class="text-danger">*</label>
                                                                <select class="form-control" id="{{item.name}}" [(ngModel)]="val.valueSelected"  (change)="composeTypeBrandSelect($event)">
                                                                    <option value="Choisissez une marque" disabled selected>
                                                                        Choisissez une marque
                                                                    </option>
                                                                    <option *ngFor="let data of listCarsInterest" [value]="data" >
                                                                        {{data}}
                                                                    </option>
                                                                </select>
                                                                <div class="chevron">
                                                                    <i *ngIf="val.toggle" (click)="val.toggle=false"
                                                                        class="fa fa-chevron-up pull-right"></i>
                                                                    <i *ngIf="!val.toggle" (click)="val.toggle=true"
                                                                        class="fa fa-chevron-down pull-right"></i>
                                                                    <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                                </div>
                                                                <div *ngIf="val.toggle" class="toggle-Wrapper">
                                                                    <div class="form-group">
                                                                        <label class="mr-2">Required</label>
                                                                        <i *ngIf="val.required" (click)="val.required=false"
                                                                            class="fa fa-toggle-on red"></i>
                                                                        <i *ngIf="!val.required" (click)="val.required=true"
                                                                            class="fa fa-toggle-off"></i>
                                                                        <span class="pull-right ucfirst"> {{val.label}}</span>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Label</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.label"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Famille wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.leadRequired"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Clé wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.formControlName"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Placeholder</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.placeholder"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Regex</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.regex"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div *ngIf="val.regex" class="form-group">
                                                                        <label>Error text</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.errorText"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngFor='let val of type.detailModele'>
                                                                <label>{{val.label}}</label> <label *ngIf="val.required"
                                                                    class="text-danger">*</label>
                                                                <select class="form-control" id="{{item.name}}" [(ngModel)]="val.valueSelected">
                                                                    <option value="Choisissez un modele" disabled selected>
                                                                        Choisissez un modele
                                                                    </option>
                                                                    <option *ngFor="let data of listModelInterest" [value]="data">
                                                                        {{data}}
                                                                    </option>
                                                                </select>
                                                                <div class="chevron">
                                                                    <i *ngIf="val.toggle" (click)="val.toggle=false"
                                                                        class="fa fa-chevron-up pull-right"></i>
                                                                    <i *ngIf="!val.toggle" (click)="val.toggle=true"
                                                                        class="fa fa-chevron-down pull-right"></i>
                                                                    <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                                </div>
                                                                <div *ngIf="val.toggle" class="toggle-Wrapper" >
                                                                    <div class="form-group">
                                                                        <label class="mr-2">Required</label>
                                                                        <i *ngIf="val.required" (click)="val.required=false"
                                                                            class="fa fa-toggle-on red"></i>
                                                                        <i *ngIf="!val.required" (click)="val.required=true"
                                                                            class="fa fa-toggle-off"></i>
                                                                        <span class="pull-right ucfirst"> {{val.label}}</span>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Label</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.label"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Famille wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.leadRequired"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Clé wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.formControlName"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Placeholder</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.placeholder"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Regex</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.regex"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div *ngIf="val.regex" class="form-group">
                                                                        <label>Error text</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.errorText"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                </div>
                                                            </div> 
                                                            <div *ngIf='listEner !==null && listEner !==undefined &&  listEner.length > 0 '>
                                                            <div class="detailEnergie" *ngFor='let val of type.detailEnergie'>
                                                                <label *ngIf='listEner?.length > 0'>{{val.label}}</label> <label *ngIf="val.required"
                                                                    class="text-danger">*</label>
                                                                <select class="form-control" id="{{item.name}}" *ngIf='listEner?.length > 0' [(ngModel)]="val.valueSelected">
                                                                    <option value="Choisissez une energie" disabled selected>Choisissez
                                                                        une energie
                                                                    </option>
                                                                    <option *ngFor="let data of listEner" [value]="data">
                                                                        {{data}}
                                                                    </option>
                                                                </select>
                                                                <div class="chevron">
                                                                    <i *ngIf="val.toggle" (click)="val.toggle=false"
                                                                        class="fa fa-chevron-up pull-right"></i>
                                                                    <i *ngIf="!val.toggle" (click)="val.toggle=true"
                                                                        class="fa fa-chevron-down pull-right"></i>
                                                                    <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                                </div>
                                                                <div *ngIf="val.toggle" class="toggle-Wrapper">
                                                                    <div class="form-group">
                                                                        <label class="mr-2">Required</label>
                                                                        <i *ngIf="val.required" (click)="val.required=false"
                                                                            class="fa fa-toggle-on red"></i>
                                                                        <i *ngIf="!val.required" (click)="val.required=true"
                                                                            class="fa fa-toggle-off"></i>
                                                                        <span class="pull-right ucfirst"> {{val.label}}</span>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Label</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.label"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Famille wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.leadRequired"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Clé wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.formControlName"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Placeholder</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.placeholder"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Regex</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.regex"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div *ngIf="val.regex" class="form-group">
                                                                        <label>Error text</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.errorText"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                
                                                <div *ngIf="item.type==='carsWish'">
                                                    <input name="fileCarsWish" type="file" (change)="UploadCars($event)" id="test1">
                                                    <div *ngIf="listCarsWish.length && listModeleWish.length">
                                                        <div *ngFor='let type of item.detailValues'>
                                                            <div *ngFor='let val of type.detailMarque'>
                                                                <label>{{val.label}}</label> <label *ngIf="item.required"
                                                                    class="text-danger">*</label>
                                                                <select class="form-control" id="{{item.name}}" [(ngModel)]="val.valueSelected">
                                                                    <option value="Choisissez un marque" disabled selected>
                                                                        Choisissez un marque
                                                                    </option>
                                                                    <option *ngFor="let data of listCarsWish" [value]="data">
                                                                        {{data}}
                                                                    </option>
                                                                </select>
                                                                <div class="chevron">
                                                                    <i *ngIf="val.toggle" (click)="val.toggle=false"
                                                                        class="fa fa-chevron-up pull-right"></i>
                                                                    <i *ngIf="!val.toggle" (click)="val.toggle=true"
                                                                        class="fa fa-chevron-down pull-right"></i>
                                                                    <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                                </div>
                                                                <div *ngIf="val.toggle" class="toggle-Wrapper">
                                                                    <div class="form-group">
                                                                        <label class="mr-2">Required</label>
                                                                        <i *ngIf="val.required" (click)="val.required=false"
                                                                            class="fa fa-toggle-on red"></i>
                                                                        <i *ngIf="!val.required" (click)="val.required=true"
                                                                            class="fa fa-toggle-off"></i>
                                                                        <span class="pull-right ucfirst">{{item.type}}</span>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Label</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.label"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Famille wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.leadRequired"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Clé wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.formControlName"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Placeholder</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.placeholder"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Regex</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.regex"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div *ngIf="val.regex" class="form-group">
                                                                        <label>Error text</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.errorText"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngFor='let val of type.detailModele'>
                                                                <label>{{val.label}}</label> <label *ngIf="item.required"
                                                                    class="text-danger">*</label>
                                                                <select class="form-control" id="{{item.name}}" [(ngModel)]="val.valueSelected">
                                                                    <option value="Choisissez un modele" disabled selected>
                                                                        Choisissez un modele
                                                                    </option>
                                                                    <option *ngFor="let data of listModeleWish" [value]="data">{{data}}
                                                                    </option>
                                                                </select>
                                                                <div class="chevron">
                                                                    <i *ngIf="val.toggle" (click)="val.toggle=false"
                                                                        class="fa fa-chevron-up pull-right"></i>
                                                                    <i *ngIf="!val.toggle" (click)="val.toggle=true"
                                                                        class="fa fa-chevron-down pull-right"></i>
                                                                    <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                                </div>
                                                                <div *ngIf="val.toggle" class="toggle-Wrapper">
                                                                    <div class="form-group">
                                                                        <label class="mr-2">Required</label>
                                                                        <i *ngIf="val.required" (click)="val.required=false"
                                                                            class="fa fa-toggle-on red"></i>
                                                                        <i *ngIf="!val.required" (click)="val.required=true"
                                                                            class="fa fa-toggle-off"></i>
                                                                        <span class="pull-right ucfirst">{{item.type}}</span>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Label</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.label"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Famille wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.leadRequired"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Clé wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.formControlName"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Placeholder</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.placeholder"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Regex</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.regex"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div *ngIf="val.regex" class="form-group">
                                                                        <label>Error text</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.errorText"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf='listEnerWish && listEnerWish!==undefined && listEnerWish!==null && listEnerWish.length>0'>
                                                            <div class="detailEnergie" *ngFor='let val of type.detailEnergie'>
                                                                <label>{{val.label}}</label> <label *ngIf="item.required"
                                                                    class="text-danger">*</label>
                                                                <select class="form-control" id="{{item.name}}" [(ngModel)]="val.valueSelected">
                                                                    <option value="Choisissez une energie" disabled selected>Choisissez
                                                                        une energie
                                                                    </option>
                                                                    <option *ngFor="let data of listEnerWish" [value]="data">
                                                                        {{data}}
                                                                    </option>
                                                                </select>
                                                                <div class="chevron">
                                                                    <i *ngIf="val.toggle" (click)="val.toggle=false"
                                                                        class="fa fa-chevron-up pull-right"></i>
                                                                    <i *ngIf="!val.toggle" (click)="val.toggle=true"
                                                                        class="fa fa-chevron-down pull-right"></i>
                                                                    <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                                </div>
                                                                <div *ngIf="val.toggle" class="toggle-Wrapper">
                                                                    <div class="form-group">
                                                                        <label class="mr-2">Required</label>
                                                                        <i *ngIf="val.required" (click)="val.required=false"
                                                                            class="fa fa-toggle-on red"></i>
                                                                        <i *ngIf="!val.required" (click)="val.required=true"
                                                                            class="fa fa-toggle-off"></i>
                                                                        <span class="pull-right ucfirst">{{item.type}}</span>
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Label</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.label"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Famille wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.leadRequired"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Clé wired</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.formControlName"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Placeholder</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.placeholder"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div class="form-group">
                                                                        <label>Regex</label>
                                                                        <input class="form-control" type="text" [(ngModel)]="val.regex"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                    <div *ngIf="val.regex" class="form-group">
                                                                        <label>Error text</label>
                                                                        <input class="form-control" type="text"
                                                                            [(ngModel)]="val.errorText"
                                                                            [ngModelOptions]="{standalone: true}" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                
                                                        </div>
                                                    </div>
                                                </div>
                
                                                <div *ngIf="item.type=='CGU'">
                                                    <input type="checkbox" value="">
                                                    <label>{{item.text}}<a href="{{item.link}}"> le règlement du
                                                            parrainage
                                                            groupe renault</a> </label>
                                                </div>
                
                                                <div *ngIf="item.type=='mentions'">
                                                    <div class="d-flex mt-1 mb-1">
                                                        <div *ngFor="let val of item.values">
                                                            <div class="mr-3">
                                                                <input type="radio" [name]="val.name"
                                                                    (change)="onMlegaleChange(val.val,item.type)"
                                                                    [checked]="val.isChecked" />
                                                                {{val.label}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.isShortLegales">
                                                        <quill-editor id="legal_notice" name="legal_0notice" [styles]="optionsWysywig"
                                                            [(ngModel)]="item.text">
                                                        </quill-editor>
                                                    </div>
                                                    <div *ngIf="item.isPdf">
                                                        <input type="file" #legalesFile *ngIf="!item.pdf_file_path" [disabled]="isProcessingFile"
                                                            (change)="onMentionLegale($event)" accept=".pdf" />
                                                        <span *ngIf="item.fileNameWithOutPdf && item.pdf_file_path">
                                                            <button [disabled]="isProcessingFile" (click)="onClickDownloadPdf(item.pdf_file_path)" class="btn btn-secondary btn-sm"
                                                                style="border-color: #30b7d1 !important;background-color:#30b7d1">
                                                                {{item.pdf_file_path}}
                                                            </button>
                                                            <i *ngIf="!isProcessingFile" (click)="removeMentionLegals(item.pdf_file_path)"
                                                                class="fa fa-trash ml-1"></i>
                                                        </span>
                                                        <div  *ngIf="isProcessingFile" class="pt-1 text-dark">
                                                            <span class="loader mr-1"></span> <span> <strong>Traitement en cours veuillez patienter...</strong> </span>
                                                        </div>
                                                    </div>
                                                </div>
                
                                                <div *ngIf="item.type=='checkbox'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <span *ngFor="let v of item.values" class="inline-form-group">
                                                        <label class="cust-check-bx">
                                                            <input  type="checkbox" [value]="v.value" name="{{item.name}}"
                                                                (click)="toggleValue(v)">
                                                            {{v.label}}
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <!--Creation-->
                                                <div *ngIf="item.type=='Addresse'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <div *ngFor="let it of item.values;let i=index;" class="inline-form-group">
                                                        <label>{{it.label}}</label> <label *ngIf="item.required"
                                                            class="text-danger">*</label>
                                                        <input type="text" class="form-control" [attr.id]="['addresse' + i]"
                                                            placeholder="{{it.placeholder}}">
                                                        <div class="chevron">
                                                            <i *ngIf="it.toggle" (click)="it.toggle=false"
                                                                class="fa fa-chevron-up pull-right"></i>
                                                            <i *ngIf="!it.toggle" (click)="it.toggle=true"
                                                                class="fa fa-chevron-down pull-right"></i>
                                                            <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                        </div>
                                                        <div *ngIf="it.toggle" class="toggle-Wrapper">
                                                            <div class="form-group">
                                                                <label class="mr-2">Required</label>
                                                                <i *ngIf="it.required" (click)="it.required=false"
                                                                    class="fa fa-toggle-on red"></i>
                                                                <i *ngIf="!it.required" (click)="it.required=true"
                                                                    class="fa fa-toggle-off"></i>
                                                                <span class="pull-right ucfirst">{{item.type}}</span>
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Label</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.label"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Famille wired</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.leadRequired"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Clé wired</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.formControlName"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Placeholder</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.placeholder"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Regex</label>
                                                                <select class="form-control"  [(ngModel)]="it.regex"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                    <option value="Choisissez un champ" disabled selected>Choisissez un
                                                                        champ
                                                                    </option>
                                                                    <option *ngFor="let regex of listRegex" [ngValue]="regex.value">
                                                                        {{regex.name}}</option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="it.regex" class="form-group">
                                                                <label>Error text</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.errorText"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"  (change)="it.hide = !it.hide" [checked]='it.hide' [(ngModel)]="it.hide"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                                <label class="form-check-label">Voulez-vous cachez cet
                                                                    item
                                                                    ?</label>
                                                            </div>
                
                                                            <!-- radio || checkbox || autocomplete -->
                                                            <div *ngIf="it.type=='civilite'">
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Label</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Value</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label>Action</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngFor="let i of it.values; let valueIndex=index;" class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="i.name"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="i.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="it.values.splice(valueIndex,1)"><i
                                                                                    class="fa fa-times-circle  text-danger"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="value.name"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="value.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="addValue(it.values)"><i
                                                                                    class="fa fa-plus-circle text-success"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.type=='Contact'">
                                                    <label>{{item.label}}</label> <label *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <div *ngFor="let it of item.values" class="inline-form-group">
                                                        <div *ngIf="it.type === 'civilite' || it.type === 'typedeclient'">
                                                            <label>{{it.label}}</label> <label *ngIf="it.required"
                                                                class="text-danger">*</label>
                                                            <span *ngFor="let val of it.values" class="inline-form-group ml-1">
                                                                <label class="cust-check-bx gdpr-id">
                                                                    <input type="radio" [value]="val.value" name="{{it.name}}">
                                                                    {{val.label}}
                                                                    <span class="checkmark"></span>
                                                                </label>
                                                            </span>
                                                        </div>
                                                        <div *ngIf="!(it.type === 'civilite' || it.type === 'typedeclient')">
                                                            <label>{{it.label}}</label> <label *ngIf="it.required"
                                                                class="text-danger">*</label>
                                                            <input type="{{it.type}}" class="form-control mb-1" id="{{it.name}}"
                                                                placeholder="{{it.placeholder}}">
                                                        </div>
                                                        <div class="chevron">
                                                            <i *ngIf="it.toggle" (click)="it.toggle=false"
                                                                class="fa fa-chevron-up pull-right"></i>
                                                            <i *ngIf="!it.toggle" (click)="it.toggle=true"
                                                                class="fa fa-chevron-down pull-right"></i>
                                                            <!-- <i (click)="removeField(i)" class="fa fa-trash pull-right"></i> -->
                                                        </div>
                                                        <div *ngIf="it.toggle" class="toggle-Wrapper">
                                                            <div class="form-group">
                                                                <label class="mr-2">Required</label>
                                                                <i *ngIf="it.required" (click)="it.required=false"
                                                                    class="fa fa-toggle-on red"></i>
                                                                <i *ngIf="!it.required" (click)="it.required=true"
                                                                    class="fa fa-toggle-off"></i>
                                                                <span class="pull-right ucfirst">{{item.type}}</span>
                                                            </div>
                                                           
                                                            <div class="form-group">
                                                                <label>Label</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.label"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Famille wired</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.leadRequired"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Clé wired</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.formControlName"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Placeholder</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.placeholder"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-group">
                                                                <label>Regex</label>
                                                                <select class="form-control" type="text" [(ngModel)]="it.regex"
                                                                    [ngModelOptions]="{standalone: true}">
                                                                    <option value="Choisissez un champ" disabled selected>Choisissez un
                                                                        champ
                                                                    </option>
                                                                    <option *ngFor="let regex of listRegex" [ngValue]="regex.value">
                                                                        {{regex.name}}</option>
                                                                </select>
                                                            </div>
                                                          
                                                            <div *ngIf="it.regex" class="form-group">
                                                                <label>Error text</label>
                                                                <input class="form-control" type="text" [(ngModel)]="it.errorText"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                            </div>
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox" [checked]='it.hide'  (change)="it.hide = !it.hide" [(ngModel)]="it.hide"
                                                                    [ngModelOptions]="{standalone: true}" />
                                                                <label class="form-check-label">Voulez-vous cachez cet
                                                                    item
                                                                    ?</label>
                                                            </div>
                
                                                            <!-- radio || checkbox || autocomplete -->
                                                            <div *ngIf="it.type=='civilite'">
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Label</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <label>Value</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label>Action</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngFor="let i of it.values; let valueIndex=index;" class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text" [(ngModel)]="i.name"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="i.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="it.values.splice(valueIndex,1)"><i
                                                                                    class="fa fa-times-circle  text-danger"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="value.name"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-5">
                                                                        <div class="form-group">
                                                                            <input class="form-control" type="text"
                                                                                [(ngModel)]="value.value"
                                                                                [ngModelOptions]="{standalone: true}" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2">
                                                                        <div class="form-group">
                                                                            <label (click)="addValue(it.values)"><i
                                                                                    class="fa fa-plus-circle text-success"
                                                                                    aria-hidden="true"></i></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="item.type=='radio' || item.type=='civilite' || item.type=='typedeclient'">
                                                    <label for="bg-color">{{item.label}}</label> <label  *ngIf="item.required"
                                                        class="text-danger">*</label>
                                                    <span *ngFor="let v of item.values" class="inline-form-group ml-1">
                                                        <label class="cust-check-bx">
                                                            <input type="radio" style="accent-color:green"  [value]="v.value"  (change)="item.label ==='Configuration du Phone et Email' && onEmailPhoneChange(item.valueSelected)" name="{{item.name}}" [(ngModel)]="item.valueSelected" [checked]="item.valueSelected" >
                                                            {{v.label}}
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </span>
                                                </div>
                                               
                                            </div>
                                            <div class="col-1"
                                                style="padding-right: 0 !important;padding-left: 0 !important; display: flex;">
                                                <div
                                                    *ngIf="!( item.label=='Traduction' ||  item.label=='Traduction regex' || item.type === 'Addresse' || item.type === 'Contact' || item.type == 'LeadCompose' || item.type== 'GDPR' || item.type== 'composeType' || item.type== 'carsWish')">
                                                    <i *ngIf="item.toggle" (click)="item.toggle=false"
                                                        class="fa fa-chevron-up pull-right"></i>
                                                    <i *ngIf="!item.toggle" (click)="item.toggle=true"
                                                        class="fa fa-chevron-down pull-right"></i>
                                                </div>
                                                <i (click)="removeField(i)" class="fa fa-trash pull-right" *ngIf="((item.isLead &&  item.type=='formName') || (item.isLead && item.type=='destination')  || (item.isLead && item.type=='device') || !item.hasOwnProperty('isLead') ) "></i>
                                            </div>
                                        </div>
                                        <div *ngIf="item.toggle && !( item.type === 'Addresse' || item.type === 'Contact' || item.type == 'LeadCompose' || item.type== 'GDPR' || item.type== 'composeType' || item.type== 'carsWish')"
                                            class="toggle-Wrapper">
                                            <div class="form-group" [hidden]="item.hasOwnProperty('hideRequired') && item.hideRequired == true">
                                                <label class="mr-2">Required</label>
                                                <i *ngIf="item.required"  (click)="item.required=false" class="fa fa-toggle-on red test-phoe"></i>
                                                <i *ngIf="!item.required" (click)="item.required=true" class="fa fa-toggle-off"></i>
                                                <span class="pull-right ucfirst">{{item.type}}</span>
                                            </div>
                                            <div class="form-group">
                                                <label>type</label>
                                                <input class="form-control" type="text" [disabled]="item.type=='number' ? true : false" [(ngModel)]="item.type" [ngModelOptions]="{standalone: true}" />
                                            </div>
                                            <div class="form-group">
                                                <label>Label</label>
                                                <!-- (focusout)="onChangeFileName($event, item.type)" -->
                                                <input *ngIf='item.isPdf' class="form-control" type="text" [(ngModel)]="item.label" />
                                                <input *ngIf="!item.isPdf" class="form-control" type="text" [(ngModel)]="item.label" />
                                            </div>
                                            <div class="form-group">
                                                <label>Famille wired</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.leadRequired" />
                                            </div>
                                            <div class="form-group">
                                                <label>Clé wired</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.formControlName" />
                                            </div>
                                            <div class="form-group">
                                                <label>Placeholder</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.placeholder" />
                                            </div>
                                            <div class="form-group">
                                                <label>Regex</label>
                                                <select class="form-control" type="text"  [(ngModel)]="item.regex" 
                                                    [ngModelOptions]="{standalone: true}">
                                                    <option value="Choisissez un champ" disabled>Choisissez un
                                                        champ
                                                    </option>
                                                    <option *ngFor="let regex of listRegex" [ngValue]="regex.value">
                                                        {{ regex.name }}
                                                    </option>
                                                </select>
                                                
                                                <div class="form-group mt-2">
                                                    <label>Regex value</label>
                                                    <input disabled class="form-control" type="text" [(ngModel)]="item.regex" />
                                                </div>
                                                
                                            </div>
                                            <div  class="form-group">
                                                <label>Message d'erreur</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.errorText" />
                                            </div>
                                            <div *ngIf="item.type === 'CGU'" class="form-group">
                                                <label>Link</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.link" />
                                            </div>
                                            <div class="form-check" *ngIf="item.type !== 'GDPR'">
                                                <input class="form-check-input" type="checkbox"  [(ngModel)]="item.hide" />
                                                <label class="form-check-label">Voulez-vous cachez cet item ?</label>
                                            </div>
                                            <div *ngIf="item.type === 'CGU' || item.type === 'GDPR' ||  item.type === 'mentions' "
                                                class="form-group">
                                                <label>Text</label>
                                                <input class="form-control" type="text" [(ngModel)]="item.text" />
                                            </div>
                                            <div *ngIf="item.type === 'text' || item.type === 'phone' || item.type === 'email' || item.type === 'textarea'" class="form-group">
                                                <label>Normalisateur</label>
                                                <select class="form-control" type="text" [(ngModel)]="item.normalisateur"
                                                    [ngModelOptions]="{standalone: true}">
                                                    <option value="Choisissez un champ" disabled selected>Choisissez un
                                                        champ
                                                    </option>
                                                    <option *ngFor="let normal of listNormalizator" [ngValue]="normal.value">
                                                        {{normal.name}}</option>
                                                </select>
                                            </div>
                
                                            <div *ngIf="item.type=='number'" class="row">
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Min</label>
                                                        <input class="form-control" type="text" [(ngModel)]="item.min" />
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="form-group">
                                                        <label>Max</label>
                                                        <input class="form-control" type="text" [(ngModel)]="item.max" />
                                                    </div>
                                                </div>
                                            </div>
                
                                            <div
                                                *ngIf="item.type=='radio' || (item.type=='marque' && item.isLead) ||  item.type=='typedeclient' || item.type=='checkbox' || item.type=='rrf' || item.type=='autocomplete' || item.type=='demande' || item.type=='leadCountry' || item.type=='source' || item.type=='subType' || item.type=='context' || item.type=='origin' || item.type=='campaign' || item.type=='device' || item.type=='leadProvider' || item.type=='formName' || item.type=='destination' || item.type=='dealer'">
                                                <div class="row">
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <label>Label</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <label>Value</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="form-group">
                                                            <label>Action</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div dndDropzone (dndDragover)="onDragover($event)" (dndDrop)="onDropChild($event,item.values)" id="drop-area">
                                                    <div *ngFor="let i of item.values; let valueIndex=index;" class="row field-child"
                                                        (dndStart)="onDragStart($event)" (dndCanceled)="onDragCanceled($event)"
                                                        (dndMoved)="onDragged(i, item.values, 'move')" [dndEffectAllowed]="'all'" [dndDraggable]="i">
                                                        <div class="col-1" style="padding-left: 25px;"><i class="fa fa-ellipsis-v dndHandle" dndHandle></i>
                                                            </div>
                                                        <div class="col-5">
                                                            <div class="form-group" *ngIf="i.hasOwnProperty('label')">
                                                                <input class="form-control" type="text" (input)="updateLabel($event.target.value, item, valueIndex)"
                                                                    [ngModel]="getValue(i.label)" value="{{ getValue(i.label) }}" />
                                                            </div>
                                                            <div class="form-group" *ngIf="i.hasOwnProperty('name')">
                                                                <input class="form-control" type="text" (input)="updateLabel($event.target.value, item, valueIndex)"
                                                                    [ngModel]="getValue(i.name)" value="{{ getValue(i.name) }}" />
                                                            </div>
                                                        </div>
                                                        <div class="col-5">
                                                            <div class="form-group" *ngIf="item.type!=='GDPR' && !item.isLead">
                                                                <input class="form-control" type="text" (input)="updateValue($event.target.value, item, valueIndex)"
                                                                    [ngModel]="getValue(i.value)" value="{{ getValue(i.value) }}" />
                                                            </div>
                                                            <div class="form-group" *ngIf="item.type!=='GDPR' && item.isLead">
                                                                <input class="form-control" type="text" [(ngModel)]="i.value" />
                                                    
                                                            </div>
                                                            <div class="form-group" *ngIf="item.type=='GDPR'">
                                                                <span *ngFor="let v of i.valuesGdpr">
                                                                    <input class="form-control" type="text" [(ngModel)]="v.value" /> </span>
                                                            </div>
                                                        </div>
                                                       
                                                      
                                                        <div class="col-1">
                                                            <div class="form-group">
                                                                <label (click)="item.values.splice(valueIndex,1)"><i class="fa fa-times-circle  text-danger"
                                                                        aria-hidden="true"></i></label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="dndPlaceholder" dndPlaceholderRef>
                                                    </div>
                                                  

                                                </div>
                                              
                                                <div class="row">
                                                    <div  class="col-1">

                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <input class="form-control" type="text" [(ngModel)]="value.label" />
                                                        </div>
                                                    </div>
                                                    <div class="col-5">
                                                        <div class="form-group">
                                                            <input class="form-control" type="text" [(ngModel)]="value.value" />
                                                        </div>
                                                    </div>
                                                    <div class="col-1">
                                                        <div class="form-group">
                                                            <label (click)="addValue(item.values)"><i
                                                                    class="fa fa-plus-circle text-success"
                                                                    aria-hidden="true"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dndPlaceholder" dndPlaceholderRef>
                                    </div>
                                </section>
                
                            </div>
                             <div class="btn-container pt-4 p-1">
                                                <div class="btn-cancel">
                                                    <button (click)="onClear()"> Annuler</button>
                                                </div>
                                                <div class="btn-valider creation">
                                                    <button (click)="showNextStep($event,2)"  [disabled]="isProcessingFile"> Valider</button>
                                                </div>
                                </div>
                        </div> 
                
                    </div>
                </div> 
            </ng-wizard-step>
            <!-- Configuration -->
            <ng-wizard-step [title]="'3'">
                <!-- <h4 class="event-text"> <i class="ft-file-plus"></i> Création Evénèment</h4> -->
                <div class="container p-md-5 p-sm-0 mt-3">
                    <div class="col-8 mt-2 mb-3" style="margin: auto;">
                        <form [formGroup]="configForm">
                            <p class="mt-2 mb-2 pl-1"><input type="checkbox" (change)="onMasterEvent($event)"
                                    [checked]="model?.isMasterEvent"><span style='color:#000' class="pl-1">Sauvegarder
                                    formulaire comme modèle </span></p>
                            <div class="mb-2">
                                <label> <strong>Choisissez le type de redirection après la soumission du formulaire
                                        :</strong> </label>
                                <div class="form-check pl-1 ml-1 mt-1">
                                    <input class="form-check-input" type="radio" formControlName="redirection"
                                        id="exampleRadios1" value="redirection">
                                    <label class="form-check-label pl-1" for="redirection">
                                        Redirection vers la page d'accueil
                                    </label>
                                </div>
                                <div class="form-check ml-1" style='padding: 0 14px !important;'>
                                    <input class="form-check-input" type="radio" formControlName="redirection"
                                        id="exampleRadios2" value="reset">
                                    <label class="form-check-label pl-1" for="reset">
                                        Aucune redirection, le formulaire saisit se vide
                                    </label>
                                </div>
                                <span class="text-danger"
                                    *ngIf="configForm.controls['redirection'].errors?.required && submittedConfig">
                                    Vous devez choisir une des valeurs
                                </span>
                            </div>
                            <div class="form-group">
                                <label for="optionsId"> <strong>Options:</strong></label>
                                <div class="mt-1">
                                    <div *ngFor="let opt of listOptions" class="form-check ml-1">
                                        <input class="form-check-input" type="checkbox"
                                            formControlName="{{opt.formControlName}}" value="{{opt.value}}"
                                            id="{{opt.formControlName}}" (change)="onchangeTempo($event)">
                                        <label class="form-check-label pl-1">
                                            {{opt.value}}
                                        </label>
                                       
                                        <div *ngIf="configForm.get('optTempo').value && opt.value=='Temporisation d’intégration des leads'">
                                            <div class="m-1">
                                                <select id="temporisation" class="custom-select form-control" (change)="onChangeTempoType($event)">
                                                    <!-- <option selected>sélectionnez un choix</option> -->
                                                    <option *ngFor="let tempo of temporisationTimesList; let i = index" [selected]="tempo.selected == true" [value]="tempo.value">
                                                        {{tempo.label}}
                                                    </option>
                                                </select>
                                                <div class="form-group mt-2" *ngIf="isHours">
                                                    <label>Saisissez le nombre d'heures de temporisation</label>
                                                    <input type="number" class="form-control"  id="dateTempo" formControlName="dateTempo">
                                                </div>
                                                <div class="form-group mt-2" *ngIf="isHourMinute">
                                                    <label>Saisissez le nombre d'heures et Minutes de temporisation</label>
                                                    <input type="time"  class="form-control" id="hourMinute" formControlName="hourMinute">
                                                </div>
                                                <div class="form-group mt-2" *ngIf="isDateHour">
                                                    <label>Saisissez le date et l'heure temporisation</label>
                                                    
                                                    <input type="datetime-local" class="form-control" id="dateHour"  [ngClass]="{'error-border':invalidDateTimeTempo}" (change)="onDateTimeTempChange($event)" [min]="minDateTempo" id="hourMinute" [min] formControlName="dateHour">
                                                    <p class="text-danger" *ngIf="invalidDateTimeTempo">veuillez sélectionner une date supérieure à la date de fin de l'événement ou une date l'heure actuel</p>
                                                </div>
                                           </div>
                                        </div>

                                        <div *ngIf="isNotificationChecked && opt.value=='Notification center'">
                                            <div class="m-1">
                                                <select  class="custom-select form-control" formControlName="notifCenterValue" (change)="onChangeNotif($event)">
                                                    <option *ngFor="let notifOption of notifcenterOptions" [selected]="notifOption.checked == true" [value]="notifOption.value" >{{ notifOption.label}}</option>
                                                </select>
                                            </div>
                                            <div *ngIf="isNotifSmsOrEmail">
                                                <div class="m-1">
                                                    <label for="">Notification prioritaire</label>
                                                    <select  class="custom-select form-control" formControlName="smsorEMailOption" (change)="onChangeSmsOrEmail($event)">
                                                        <option *ngFor="let notifOption of smsEmailOptions" [value]="notifOption.value" >{{ notifOption.label}}</option>
                                                       </select>
                                                </div>
                                            </div>

                                            <div class="m-1">
                                                <label for="">Template</label>
                                                <ng-multiselect-dropdown
                                                    [placeholder]="'Sélectionner'"
                                                    [settings]="dropdownSettingsTemplate"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [data]="dropdownListTemplates"
                                                    [(ngModel)]="selectedTemplateItems"
                                                    (onSelect)="onItemTemplateSelect($event)"
                                                    (onSelectAll)="onSelectTemplateAll($event)"
                                                    >
                                                    </ng-multiselect-dropdown>
                                               
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="d-flex ml-1">
                           
                            <div> <strong>Okta</strong>
                                <ui-switch style="margin-left: 5px;"  size="small" checkedTextColor="#ffffff" [checked]="is_dealer_event" checkedBgColor="#42a5f5"  checkedLabel="Oui" (change)="onNetworkEventChanged($event)" uncheckedLabel="Non"></ui-switch>
                            </div>
                            <span *ngIf="is_dealer_event" class="ml-1">
                                <div *ngIf="reseau!==null && reseau.length>0" class="d-flex">
                                     <strong>Reseau : &nbsp; </strong>
                                    <div>
                                        <div *ngIf="reseau.length===1">
                                            <span style="padding-right: 2px;">{{reseau[0]?.reseau}}</span>
                                        </div>
                                        <div *ngIf="reseau.length===2">
                                            <div style="padding-right: 2px;">{{reseau[0]?.reseau}}{{"+" +reseau[1]?.reseau}} </div>
                                        </div>
                                    </div>
                                    <div style="padding-left: 5px;">
                                        <i class="la la-pencil" tooltipClass="tooltip-custom-class" placement="bottom"
                                            ngbTooltip="Modifier reseau" (click)="onEditEventReseau(model.code_event,reseau, model.event_name)"
                                            data-toggle="modal" data-target="#exampleModalCenter"></i>
                                    </div>
                                </div>
                                <span *ngIf="reseau===null || !reseau.length">
                                    <strong>Reseau : </strong>
                                    <i class="la la-plus" tooltipClass="tooltip-custom-class" placement="bottom" ngbTooltip="Ajouter reseau"
                                        (click)="onSelectNetworkEvent(model.code_event,model.event_name)" data-toggle="modal"
                                        data-target="#exampleModalCenter"></i>
                                </span>
                            </span>
                        </div>
                        <div class="d-flex ml-1 mt-1">
                            <div> <strong>Visibilité reporting des leads  ? </strong>
                                <ui-switch style="margin-left: 5px;"  size="small" checkedTextColor="#ffffff" [checked]="is_dealer_event_show_lead" checkedBgColor="#42a5f5"  checkedLabel="Oui" (change)="onDealerLeadEventChanged($event)" uncheckedLabel="Non"></ui-switch>
                            </div>
                        </div>

                        <div class="d-flex ml-1 mt-1">
                            <div> <strong>Visibilité Compteur nombre totale des leads ? </strong>
                                <ui-switch style="margin-left: 5px;"  size="small" checkedTextColor="#ffffff" [checked]="is_show_total_lead_counter" checkedBgColor="#42a5f5"  checkedLabel="Oui" (change)="onLeadEventCounterChanged($event)" uncheckedLabel="Non"></ui-switch>
                            </div>
                        </div>
                      
                    </div>
                   
                    <div class="col-8" style="margin: auto;">
                        <div class="btn-container pt-4">
                            <div class="btn-cancel">
                                <button (click)="onClear()"> Annuler</button>
                            </div>
                            <div class="btn-valider creation">
                                <button type="submit" (click)="saveEvent(model)" [hidden]="isEdit" 
                                    class="btn-save"><span *ngIf="isSaveorEdit">
                                    <span class="loader mr-1"></span>   En traitement....
                                    </span>
                                <span *ngIf="!isSaveorEdit">Sauvegarder</span></button>
                                <button type="submit" (click)="updateEvent(model,model._id)" [hidden]="!isEdit"
                                    class="btn-save"> <span *ngIf="isSaveorEdit">
                                    <span class="loader mr-1"></span>  En traitement....
                                    </span> <span *ngIf="!isSaveorEdit">Mise à jour</span> </button>
                            </div>
                        </div>
                    </div>
                </div>
            
            </ng-wizard-step>
        </ng-wizard>
   
    </div>
    <div *ngIf="isProcessing" class="text-top" style="position: absolute;top:20%;left:45%">
      
        <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
      </div>

</div>
<!-- Element sauvegarder comme modele -->
<div class="modal fade text-left" id="default" tabindex="-1" role="dialog" aria-labelledby="myModalLabel1"
    style="display: none;" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel1">Liste des templates</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div>
                        <div class="row mt-1 pt-1 pb-1 main-container m-1"
                            *ngFor="let model of masterEvents;let i=index">
                            <div class="col">
                                <span>{{(model.event_obj.name?.length>20)? (model.event_obj.name |
                                    slice:0:20)+'...':(model.event_obj.name)}}</span>
                            </div>

                            <!-- Modal -->
                            <div class="modal text-left" [attr.id]="['default'+i]" tabindex="-1" role="dialog"
                                aria-labelledby="myModalLabel1" style="display: none;" aria-hidden="true">
                                <div class="modal-dialog" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="myModalLabel1">
                                                <strong>{{model.event_obj.name}}</strong> événement details
                                            </h4>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">×</span>
                                            </button>
                                        </div>
                                        <div class="modal-body m-2">
                                            <div class="container col-md-7 col-xs-12 col-sm-12">

                                                <div class="row pt-md-5 m-1"
                                                    [style.backgroundColor]="model?.theme?.bgColor"
                                                    [style.color]="model?.theme?.textColor">
                                                    <div class="col-12 event-name">
                                                        <h2>{{model.event_obj.name}}</h2>
                                                    </div>
                                                    <div class="col-12 event-description">
                                                        <p>{{model.event_obj.description}}</p>
                                                    </div>
                                                    <form class="ftheme">
                                                        <div class="col-12">
                                                            <div *ngFor="let item of model.attributes"
                                                                class="form-group">
                                                                <div *ngIf="item.type=='text'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-secondary">*</label>
                                                                    <input type="text" class="form-control"
                                                                        id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [required]="item.required"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div *ngIf="item.type=='Addresse'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <div *ngFor="let v of item.values"
                                                                        class="inline-form-group">
                                                                        <div>
                                                                            <label>{{v.label}}</label> <label
                                                                                *ngIf="v.required"
                                                                                class="text-danger">*</label>
                                                                        </div>
                                                                        <input type="text" class="form-control"
                                                                            id="{{v.name}}"
                                                                            placeholder="{{v.placeholder}}">
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="item.type=='email'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <input type="email" class="form-control"
                                                                        id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div *ngIf="item.type=='phone'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <input type="email" class="form-control"
                                                                        id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div *ngIf="item.type=='number'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <input type="number" class="form-control"
                                                                        id="{{item.name}}" min="{{item.min}}"
                                                                        max="{{item.max}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div *ngIf="item.type=='date'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <input type="date" class="form-control"
                                                                        id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div *ngIf="item.type=='datetime-local'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <input type="datetime-local" class="form-control"
                                                                        id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                </div>
                                                                <div *ngIf="item.type=='Contact'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <div *ngFor="let v of item.values"
                                                                        class="inline-form-group">
                                                                        <div *ngIf="v.type === 'civilite'">
                                                                            <label>{{v.label}}</label> <label
                                                                                *ngIf="item.required"
                                                                                class="text-danger">*</label>
                                                                            <span *ngFor="let val of v.values"
                                                                                class="inline-form-group ml-1">
                                                                                <label class="cust-check-bx gdpr-id">
                                                                                    <input type="radio"
                                                                                        [value]="val.value"
                                                                                        name="{{v.name}}">
                                                                                    {{val.label}}
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </span>
                                                                        </div>
                                                                        <div *ngIf="v.type !== 'civilite'">
                                                                            <label>{{v.label}}</label> <label
                                                                                *ngIf="item.required"
                                                                                class="text-danger">*</label>
                                                                            <input type="{{v.type}}"
                                                                                class="form-control mb-1"
                                                                                id="{{v.name}}"
                                                                                placeholder="{{v.placeholder}}">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="item.type=='LeadCompose'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <div *ngFor="let it of item.values"
                                                                        class="inline-form-group">
                                                                        <label>{{it.label}}</label>
                                                                        <select class="form-control mb-1"
                                                                            id="{{it.name}}">
                                                                            <option value="Choisissez une réponse"
                                                                                disabled selected>Choisissez un réponse
                                                                            </option>
                                                                            <option *ngFor="let v of it.values"
                                                                                [value]="v.value">{{v.name}}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="item.type=='textarea'" class="form-group">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <textarea class="form-control" id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}" rows="6"
                                                                        autocomplete="off"
                                                                        style="min-height: 80px;resize: vertical;">
                                                                    </textarea>
                                                                </div>
                                                                <div *ngIf="item.type=='file'">
                                                                    <label>{{item.label}}</label>
                                                                    <label *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <input type="file" class="form-control"
                                                                        id="{{item.name}}"
                                                                        placeholder="{{item.placeholder}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}"
                                                                        autocomplete="off">
                                                                    <!-- <small class="form-text text-danger">(File can't be uploaded right
                                        now)</small> -->
                                                                </div>
                                                                <div *ngIf="item.type=='paragraph'">
                                                                    <p class="form-text">{{item.placeholder}}</p>
                                                                </div>
                                                                <div *ngIf="item.type=='autocomplete'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <select class="form-control" id="{{item.name}}"
                                                                        [(ngModel)]="item.value"
                                                                        [ngModelOptions]="{standalone: true}">
                                                                        <option *ngFor="let v of item.values"
                                                                            [value]="v.value">
                                                                            {{v.label}}</option>
                                                                    </select>
                                                                    <!-- <small class="form-text text-muted">{{item.description}}</small> -->
                                                                </div>
                                                                <div *ngIf="item.type=='checkbox'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <span *ngFor="let v of item.values"
                                                                        class="inline-form-group ml-1">
                                                                        <label class="cust-check-bx">
                                                                            <input type="checkbox" [value]="v.value"
                                                                                name="{{item.name}}"
                                                                                (click)="toggleValue(v)">
                                                                            {{v.label}}
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </span>
                                                                </div>
                                                                <div
                                                                    *ngIf="item.type=='radio' || item.type=='civilite'">
                                                                    <label>{{item.label}}</label> <label
                                                                        *ngIf="item.required"
                                                                        class="text-danger">*</label>
                                                                    <div *ngFor="let v of item.values"
                                                                        class="inline-form-group">
                                                                        <label class="cust-check-bx">
                                                                            <input type="radio" [value]="v.value"
                                                                                name="{{item.name}}"
                                                                                [(ngModel)]="item.value"
                                                                                [ngModelOptions]="{standalone: true}">
                                                                            {{v.label}}
                                                                            <span class="checkmark"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="item.type=='button'" class="btn-cont">
                                                                    <input type="{{item.subtype}}"
                                                                        value="{{item.label}}" class="btn btn-primary"
                                                                        id="{{item.name}}">
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn grey btn-outline-primary"
                                                data-dismiss="modal">
                                                <i class="la la-close"></i> Fermer</button>
                                            <button type="button" class="btn btn-outline-warning"><i
                                                    class="la la-pencil"></i> Modifier</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col" (click)="onEdit(model.event_id)">
                                <span class="text-success"> <i class="la la-check"></i></span> Sélectionner
                            </div>
                            <!-- <div class="col" (click)="onDelete(model.event_id,model.event_obj.name)">
                                <span class="text-danger">
                                    <i class="la la-trash"></i>
                                </span>
                                Supprimer
                            </div> -->
                        </div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal">Fermer</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content col-10" style="margin: 0 auto !important;">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle"> Sélectionner Reseau</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-multiselect-dropdown [placeholder]="'Sélectionner'" [settings]="dropdownSettings"
                    [data]="dropdownList" [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)"
                    (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"  (onDeSelectAll)="onDeSelectAll($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Fermer</button>
            </div>
        </div>
    </div>
</div>
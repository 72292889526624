<div class="app-conent content mt-5 pt-3 mb-2">
    <div class="row text-left pl-2 pr-2">
        <div class="col text-left pl-0 pr-0 ml-1">
            <a class="mb-1 home-link" routerLink="/home" style="color:#1f2532">
                <i class="fa fa-arrow-left" style='color:#777;padding-right:10px;'></i>Retour à l'accueil</a>
        </div>
        <div class="col">
            <p class="fold-unfold mb-1" (click)="isCollapsed=!isCollapsed" *ngIf="listLeads.length"> 
                <span *ngIf="isCollapsed"><i class="fa fa-chevron-up text-light "></i> Plier le chart graphique </span>
            <span *ngIf="!isCollapsed"><i class="fa fa-chevron-down text-light"></i> Déplier le chart graphique </span></p>
        </div>    
    </div>
    <div class="row p-2"  [hidden]="!isCollapsed" *ngIf="listLeads.length">
        
        <div class="col-6">
            <div id="chart1">
                <apx-chart class="box-shadow-graph" *ngIf="chartOptions1" [series]="chartOptions1?.series"
                    [chart]="chartOptions1?.chart" [dataLabels]="chartOptions1?.dataLabels"
                    [plotOptions]="chartOptions1?.plotOptions" [yaxis]="chartOptions1?.yaxis" [xaxis]="chartOptions1?.xaxis"
                    [fill]="chartOptions1?.fill" [title]="chartOptions1?.title"></apx-chart>
            </div>

        </div>
        <div class="col-6" style="padding-top:0!important">
            <div class="ps" style="position: relative; max-height: 310px;" [perfectScrollbar]="config">
                <div id="chart" style="position:relative" class="graphic-container">
                    <div *ngIf="isCollapsed" style="position: absolute;z-index: 10;left: 15%;color:#ddd; font-size:12px;">Nombre de
                        leads générés entre {{leadStartDate | date:'dd-MM-yyyy'}} et {{leadEndDate | date:'dd-MM-yyyy'}}
                        <div style="position: absolute;z-index:12;left: 106%;top: 0;">
                            <input *ngIf="chartOptions" type="text" placeholder="sélectionner la date" class="border-0 pr-1 bg-light"
                                bsDaterangepicker [minDate]="minDateLead" [maxDate]="maxDateLead"
                                (bsValueChange)="onValueChangeGraph($event)" [(ngModel)]="bsRangeGraphValue" placement="left"
                                [bsConfig]="{ adaptivePosition: true,dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default',showClearButton: true, clearPosition: 'top',showWeekNumbers: false,showPreviousMonth: true  }">
                            <i title="Effacer" *ngIf="bsRangeGraphValue"
                                style="position: absolute;left: 90%;top: 35%;cursor: pointer;color: #000;font-size: 1.2em !important;"
                                (click)="clearDateGraphRange()" class="la la-remove"></i>
                        </div>
                    </div>
                    <apx-chart class="box-shadow-graph graphic-container" *ngIf="chartOptions" [series]="chartOptions?.series"
                        [chart]="chartOptions?.chart" [dataLabels]="chartOptions?.dataLabels" [plotOptions]="chartOptions?.plotOptions"
                        [xaxis]="chartOptions?.xaxis"></apx-chart>
                </div>
            </div>
           
        </div>
    </div>
    <div class="col-5 text-right pl-0 pr-0 mr-1 d-flex ml-auto">
        <p class="remove-filter" (click)="clearFilter()" *ngIf="searchTerm.length"><i class="la la-remove"></i> </p>
        <input type="text" placeholder="sélectionner la plage de dates" class="form-control border-0" bsDaterangepicker
            (bsValueChange)="onValueChange($event)" [(ngModel)]="bsRangeValue"
            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-default',showClearButton: true, clearPosition: 'top',showWeekNumbers: false,showPreviousMonth: true }">
        <i title="Effacer" *ngIf="bsRangeValue" style=" margin-left: -20px;margin-top: 13px;cursor: pointer;"
            (click)="clearDateRange()" class="la la-remove"></i>
        <input type="text" class="form-control border-0 rounded-0 ml-1" [(ngModel)]="searchTerm"
            placeholder="Nom,Prénom,Email,Phone,lead id ..." />
        <button class="btn btn-primary box-shadow-0" (click)="filterEvents()">Recherche</button>
    </div> 

    <div class="text-center" *ngIf="!listLeads.length && !isLoading" class='p-4'>
     <h5 class="text-center">Il n'existe pas les leads avec temporisation</h5> 
    </div>
    <div class="table-wrapper m-1 mb-2" *ngIf="listLeads.length">
        <table class="table table-striped mb-0">
            <thead>
                <tr>
                    <th scope="col"> <input type="checkbox" name="all"  (change)="checkAll($event)" /> </th>
                    <!-- <th scope="col">lead SubmissionId </th> -->
                    <th scope="col">Date tempo </th>
                    <th style="cursor:pointer" scope="col"
                        (click)="field==='leadSubmissionTimestamp' && order==='ASC' ? sortBy('leadSubmissionTimestamp','DESC') : sortBy('leadSubmissionTimestamp','ASC')">
                        Date de création
                        <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                            [class.text-dark]="field==='leadSubmissionTimestamp' && order==='ASC'"
                            ></i>
                        <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                            
                            [class.text-dark]="field==='leadSubmissionTimestamp' && order==='DESC'"></i>
                    </th>
                    
                    <th style="cursor:pointer" scope="col" scope="col"
                        (click)="field==='event_name' && order==='ASC' ? sortBy('event_name','DESC') : sortBy('event_name','ASC')">
                        Nom de l'événement
                        <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                            [class.text-dark]="field==='event_name' && order==='ASC'"></i>
                        <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                            [class.text-dark]="field==='event_name' && order==='DESC'"></i>
                    </th>
                  
                    <th style="cursor:pointer" scope="col"
                        (click)="field==='lastName' && order==='ASC' ? sortBy('lastName','DESC') : sortBy('lastName','ASC')">
                        Nom
                        <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                            [class.text-dark]="field==='lastName' && order==='ASC'" ></i>
                        <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                             [class.text-dark]="field==='lastName' && order==='DESC'"></i>
                    </th>
                    <th style="cursor:pointer" scope="col"
                        (click)="field==='firstName' && order==='ASC' ? sortBy('firstName','DESC') : sortBy('firstName','ASC')">
                        Prénom <i style="cursor:pointer" class="fas fa-arrow-down font-size-xsmall text-light"
                            [class.text-dark]="field==='firstName' && order==='ASC'" ></i>
                        <i style="padding-left: 2px; cursor:pointer" class="fas fa-arrow-up font-size-xsmall text-light"
                             [class.text-dark]="field==='firstName' && order==='DESC'"></i>
                    </th>
                    <th scope="col"> Etat
                    </th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let lead of listLeads  | paginate: { itemsPerPage: itemsPerPage, currentPage: p,totalItems: total};let i=index;">
                    <td> <input type="checkbox" name="all" [checked]="lead.state" (change)="checkLead($event, lead.id)" /></td>
                    <td>
                        {{ changeDate(lead.creationDateTempo) | date: 'dd-MM-yyyy HH:mm' }}
                    </td>
                    <td>
                        <!-- {{ changeDate(lead.creationDate) | date: 'medium' }} -->
                        {{lead.creationDate}}
                    </td>
                   
                   
                    <ng-template #tipContent> {{lead.event_name}}</ng-template>
                    <td container="body" [ngbTooltip]="lead.event_name?.length>25 ? tipContent : ''">
                        {{(lead.event_name?.length>25)? (lead.event_name | slice:0:15)+'...':(lead.event_name)}}
                    </td>
                 
                    <td>
                        {{lead.lastName}}
                    </td>
                    <td>
                        {{lead.firstName}}
                    </td>
                    <td>
                        {{lead.status}}
                    </td>
                    <td>
                        <ng-template #tipContentDetails>Voir plus d'info du Lead</ng-template>
                        <button type="button" class="btn btn-success btn-sm mr-1" [ngbTooltip]="tipContentDetails"
                            (click)="openModalInfo(templateInfo,lead.id)">
                            <i class="fas fa-eye"></i>
                        </button>
                        <ng-template #tipContentEdit>Modifier l'info du Lead</ng-template>
                        <button type="button" class="btn btn-warning btn-sm mr-1" [ngbTooltip]="tipContentEdit"
                            (click)="openModalEditLead(templateEdit,lead.id)">
                            <i class="fas fa-pen"></i>
                        </button>
                        <ng-template #tipContentDelete>Supprimer le Lead</ng-template>
                        <button type="button" class="btn btn-danger btn-sm mr-1" [ngbTooltip]="tipContentDelete"
                            (click)="onDeleteLead(templateDelete,lead.id)">
                            <i class="fas fa-trash"></i>
                        </button>
                        <!-- <i class="fas fa-eye mr-1" style="color:#777;cursor: pointer;"  data-toggle="modal" data-target="#exampleModal" (click)="moreInfo(lead.id)"></i> -->
                    </td>
                </tr>
            </tbody>
           
        </table>
       
    </div>
    <button class="btn valider ml-1" *ngIf="isAllLeadsChecked || isMoreThanOneChecked"  style="padding: 0.4rem 1rem !important;" (click)="DeleteAllLeads()">Supprimer
        tous</button>
    <div class="text-center mt-0" *ngIf="listLeads.length && !isLoading">
        <div class="mt-0">
            <pagination-controls responsive="true" previousLabel="" nextLabel="" (pageChange)="pageChanged($event)">
            </pagination-controls>
        </div>
    </div>
</div>

<div *ngIf="isMoreinfo" class="modal fade" id="exampleModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header border-0 bg-primary text-white text-center">
                <h5 class="modal-title text-white text-center w-100" id="exampleModalLabel">Les détails du leads</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
           
                <ul class="list-group">
                   
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Date de création
                        <span class="badge badge-light badge-pill text-dark">
                            {{ changeDate(leadObj.date_creation) | date: 'medium' }}
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Date de tempo
                        <span class="badge badge-light badge-pill text-dark">
                            {{ changeDate(leadObj.date_tempo) | date: 'dd-MM-yyyy HH:mm'}}
                        </span>
                    </li>
                  
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Etat
                        <span class="badge badge-light badge-pill text-dark">
                            En cours
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Nom
                        <span class="badge badge-light badge-pill text-dark">
                            {{leadObj.form_obj.client.lastName}}
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Prénom
                        <span class="badge badge-light badge-pill text-dark">
                            {{leadObj.form_obj.client.firstName}}
                        </span>
                    </li>
                
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Email
                        <span class="badge badge-light badge-pill text-dark">
                            {{leadObj.form_obj.client.email}}
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Téléphone
                        <span class="badge badge-light badge-pill text-dark">
                            {{leadObj.form_obj.client.mobilePhone}}
                        </span>
                    </li>
                    <li class="list-group-item d-flex justify-content-between align-items-center">
                        Addresse
                        <span class="badge badge-light badge-pill text-dark">
                            {{leadObj.form_obj.client.address.addressLine}} {{leadObj.form_obj.client.address.postalCode}} {{leadObj.form_obj.client.address.city}}
                            
                        </span>
                    </li>
                
                </ul>
                
            <div class="modal-footer" style="justify-content: center;">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" style="padding: .375rem .75rem !important;">Fermer</button>
            </div>
        </div>
    </div>
</div>

<ng-template #templateInfo>
    <div class="modal-header border-0 text-white text-center bg-success">
        <h4 class="modal-title text-white ml-auto text-center">Les détails du leads</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
        <h5> <i class="fas fa-user"></i> Client Info</h5>
        <hr class="mb-0">
        <div class="row m-1">
            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                [ngClass]="{'hidden':  data.parentKey!=='client' && data.parentKey!=='dealer' }">
                <p> <span class="font-weight-normal ">{{data.label}}</span> <br> <strong
                        class="font-weight-500">{{data.value}}</strong></p>
            </div>
        </div>
        <h5 class="mt-2"> <i class="fas fa-info"></i> Lead Info </h5>
        <hr class="mb-0">
        <div class="row m-1">
            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                [ngClass]="{'hidden': data.parentKey!=='leadInfo' }">
                <p *ngIf="data.label!=='leadSubmissionTimestamp'"> <span
                        class="font-weight-normal">{{data.label}}</span> <br> <strong
                        class="font-weight-500">{{data.value}}</strong></p>
                <p *ngIf="data.label==='leadSubmissionTimestamp'"> <span
                        class="font-weight-normal">{{data.label}}</span> <br>
                    <strong class="font-weight-500">{{data.value | date:'medium'}}</strong>
                </p>
            </div>
        </div>
        <h5 class="mt-2" [ngClass]="{'hidden':checkKey('vehicle')}"> <i class="fas fa-car"></i> Véhicule Info </h5>
        <hr class="mb-0" [ngClass]="{'hidden':checkKey('vehicle')}">
        <div class="row m-1">
            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                [ngClass]="{'hidden': data.parentKey!=='vehicle' }">
                <p> <span class="font-weight-normal">{{data.label}}</span> <br>
                    <strong class="font-weight-500">{{data.value}}</strong>
                </p>


            </div>
        </div>

        <h5 class="mt-2" [ngClass]="{'hidden':checkKey('ownedVehicle')}"> <i class="fas fa-car"></i> Véhicule
            posséder Info </h5>
        <hr class="mb-0" [ngClass]="{'hidden':checkKey('ownedVehicle')}">
        <div class="row m-1">
            <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                [ngClass]="{'hidden': data.parentKey!=='ownedVehicle' }">
                <p> <span class="font-weight-normal">{{data.label}}</span> <br> <strong
                        class="font-weight-500">{{data.value}}</strong></p>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #templateEdit>
    <div class="modal-header bg-primary border-0 text-white text-center bg-warning">
        <h4 class="modal-title text-white ml-auto text-center">Modifier l'information du lead</h4>
        <button type="button" class="btn-close close pull-right text-white" aria-label="Close"
            (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
    </div>
    <div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
        <form [formGroup]="userForm">
            <h5> <i class="fas fa-user"></i> Client Info</h5>
            <hr class="mb-0">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || ( !data.parentKey.includes('client') && data.parentKey!=='dealer') }">
                    <label>{{data.label}}</label>
                    <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                        value="{{data.value}}" />
                </div>
            </div>
            <h5 class="mt-2"> <i class="fas fa-info"></i> Lead Info </h5>
            <hr class="mb-0">
            <div class="row m-1">
                <div class="col-xl-6 col-md-6">
                <label>Date tempo</label>
                <input class="form-control" [ngClass]="{'border-danger':minDateError }" type="datetime-local" [min]="minDateTempo" (change)="onDateTempoChange($event.target.value)" [value]="dateTempo">
                <p *ngIf="minDateError" class="text-danger">veuillez sélectionner une date supérieure ou égale de date et l'heure actuel</p>
                </div>
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || data.parentKey!=='leadInfo' }">
                    <label>{{data.label}}</label>
                    <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                        value="{{data.value}}" />
                      
                </div>
            </div>
            <h5 class="mt-2" [ngClass]="{'hidden':checkKey('vehicle')}"> <i class="fas fa-car"></i> Véhicule Info </h5>
            <hr class="mb-0" [ngClass]="{'hidden':checkKey('vehicle')}">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || data.parentKey!=='vehicle' }">
                    <label>{{data.label}}</label>
                    <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                        value="{{data.value}}" />
                </div>
            </div>

            <h5 class="mt-2" [ngClass]="{'hidden':checkKey('ownedVehicle')}"> <i class="fas fa-car"></i> Véhicule
                posséder Info </h5>
            <hr class="mb-0" [ngClass]="{'hidden':checkKey('ownedVehicle')}">
            <div class="row m-1">
                <div *ngFor="let data of formsInputs" class="col-xl-6 col-md-6"
                    [ngClass]="{'hidden':data.formControlName==='leadSubmissionTimestamp' || data.formControlName==='leadSubmissionId' || data.parentKey!=='ownedVehicle' }">
                    <label>{{data.label}}</label>
                    <input type="text" class="form-control" formControlName="{{data.formControlName}}"
                        value="{{data.value}}" />
                </div>
            </div>
        </form>
        <div class="d-flex justify-content-between ml-3 mr-3 mt-3">
            <div>
                <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()" style="padding: 0.4rem 1rem !important;">Annuler</button>
            </div>
            <div>
                <button [disabled]="minDateError ? true : false" class="btn  valider" (click)="onSubmit()" style="padding: 0.4rem 1rem !important;">Valider</button>
            </div>
         

        </div>


    </div>
</ng-template>

<ng-template #templateDelete>
<div class="modal-header border-0 text-white text-center bg-danger">
    <h4 class="modal-title text-white ml-auto text-center">Supprimer le lead</h4>
    <button type="button" class="btn-close close pull-right text-white" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
</div>
<div class="modal-body text-dark p-2 error-msg-body edit-lead-modal-body">
    <p class="text-center">Voulez-vous supprimer le Lead?</p>
    <div class="d-flex justify-content-between">
     
        <div>
            <button class="btn btn-light text-dark border-0" aria-label="Close" (click)="modalRef?.hide()"
                style="padding: 0.4rem 1rem !important;">Non</button>
        </div>
        <div>
            <button class="btn valider" (click)="onDelete()" style="padding: 0.4rem 1rem !important;">Oui</button>
        </div>
    </div>
</div>
</ng-template>
<div class="text-center" *ngIf="isLoading">
    <h5 class="text-center">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </h5>
</div>
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ENV } from 'src/app/core/env.config';
import { DatacatchingService } from 'src/app/services/datacatching.service';
@Component({
  selector: 'app-translation',
  templateUrl: './translation.component.html',
  styleUrls: ['./translation.component.css']
})
export class TranslationComponent {
  translationForm: FormGroup;
  modalRef?: BsModalRef;
  isEdit: boolean = false;
  languageId: string = '';
  config = {
    class: 'modal-lg',
    backdrop: true,
    ignoreBackdropClick: true
  };
  translations: any = [];
  idToEdit: string;
  translationObjInit = {
    "dr_rrf": "Direction régionale",
    "home_btn": "Accueil",
    "language": "",
    "label_ipn": "IPN Vendeur (facultatif)",
    "label_rrf": "Saisissez le code RRF de votre point de vente",
    "start_btn": "Démarrer",
    "nature_rrf": "Nature",
    "return_btn": "Retour",
    "search_btn": "Rechercher",
    "address_rrf": "Adresse",
    "country_rrf": "Pays",
    "close_btn": "Fermer",
    "label_hostesses":'Je suis hôte(esse)',
    "label_vendeur":'Je suis vendeur',
    "continue_btn":"Continuer",
    "loading_text":"Lancement du traitement",
    "choose_dealer": "Choisissez votre point de vente",
    "codeDealer_rrf": "Code RRF de l’affaire",
    "label_campaign": "Nom de votre campagne (facultatif)",
    "nameDealer_rrf": "Nom de l’affaire",
    "placeholder_cp": "Code postal",
    "restartBtn_rrf": "Recommencer",
    "errorMessage_cp": "Veuillez renseigner un code postal à 5 chiffres",
    "placeholder_ipn": "Veuillez saisir votre IPN",
    "placeholder_rrf": "Code à 8 chiffres",
    "telephone_valid": "Veuillez entrer un téléphone valide",
    "validateBtn_rrf": "Valider",
    "mandatory_message": "*Champs obligatoires",
    "placeholder_brand": "Choisissez une marque",
    "placeholder_model": "Choisissez un modèle",
    "errorMessage_brand": "Le champ marque doit être saisie",
    "errorMessage_model": "Le champ modèle doit être saisie",
    "placeholder_energy": "Choisissez une energie",
    "select_placeholder": " Choisissez une option",
    "errorMessage_dealer": "Ce champ est obligatoire",
    "errorMessage_energy": " Le champ energie doit être saisie",
    "placeholder_campaign": "Veuillez saisir le nom de votre campagne de collecte",
    "choose_affaire_dealer": "Sélectionnez votre point de vente",
    "errorMessage_campaign": "Le champ campagne doit être en minuscule alpha/num sans espace, autorisation du sépérateur \" - \" (les autres caractères spéciaux ne sont pas autorisés & nbsp;)",
    "placeholder_cp_dealer": "Veuillez sélectionner le dealer de votre choix",
    "errorMessage_regex_ipn": "Le champ vendeur n’autorise que 7 caractères alpha-numérique &nbsp",
    "errorMessageNoresult_cp": "Le code postal renseigné ne retourne pas de résultat",
    "errorMessageNotFound_rrf": "Le code RRF n'existe pas",
    "errorMessageMandatory_rrf": "Le code RRF obligatoire",
    "errorMessageMinLength_rrf": "Le code RRF doit de 8 chiffres minimum &nbsp",
    "errorMessageNotNumber_rrf": "Le code RRF doit de Numérique &nbsp",
    "errorMessageMandatory_dealer": "Le dealer est requis",
    "errorMessage_selection_dealer": "La sélection d'un point de vente est obligatoire",
    "errorMessage_dealer_config": "Veuillez vérifier la configuration de l'URL de dealer Locator",
    "error_event_expire": "L’évènement est expiré, merci de contacter l’administrateur de CATCHY",
    "error_contact_admin": "L’évènement est en pause, merci de contacter l’administrateur de CATCHY",
    "error_code_secret": "Le code secret est erroné, merci de contacter l’administrateur de CATCHY",
    "error_event_pause": "L’évènement est en pause, merci de contacter l’administrateur de CATCHY",
    "form_success": "Formulaire validé avec succès",
    "error_form_submit": "une erreur est survenue veuillez réessayer ultérieurement",
    "form_offline_submit_success": "Formulaire validé avec succès, vous êtes hors-ligne vos données seront soumises une fois en ligne !",
    "error_form_fields": 'Un ou plusieurs champs sont vides ou mal remplies',
    "event_not_started_error": 'L’évènement n’a pas commencé ou est terminé, ou le code secret est erroné!'
  };


  constructor(private formBuilder: FormBuilder, private modalService: BsModalService, private toastr: ToastrService, private datacatchingService: DatacatchingService) {
    this.translationForm = this.formBuilder.group({
      language: [null, Validators.required],
      //globalButtons
      home_btn: ["Accueil", Validators.required],
      start_btn: ["Démarrer", Validators.required],
      return_btn: ["Retour", Validators.required],
      close_btn: ["Fermer", Validators.required],
      loading_text: ["Lancement du traitement", Validators.required],
      mandatory_message: ["*Champs obligatoires", Validators.required],
      select_placeholder: [' Choisissez une option', Validators.required],
      telephone_valid: ['Veuillez entrer un téléphone valide', Validators.required],
      //RRf
      label_rrf: ["Saisissez le code RRF de votre point de vente", Validators.required],
      placeholder_rrf: ['Code à 8 chiffres', Validators.required],
      search_btn: ['Rechercher', Validators.required],
      errorMessageMinLength_rrf: ['Le code RRF doit de 8 chiffres minimum &nbsp', Validators.required],
      errorMessageMandatory_rrf: ['Le code RRF obligatoire', Validators.required],
      errorMessageNotNumber_rrf: ['Le code RRF doit de Numérique &nbsp', Validators.required],
      errorMessageNotFound_rrf: ["Le code RRF n'existe pas", Validators.required],
      country_rrf: ["Pays"],
      nameDealer_rrf: ["Nom de l’affaire", Validators.required],
      codeDealer_rrf: ["Code RRF de l’affaire", Validators.required],
      nature_rrf: ["Nature", Validators.required],
      address_rrf: ["Adresse", Validators.required],
      dr_rrf: ["Direction régionale", Validators.required],
      restartBtn_rrf: ["Recommencer", Validators.required],
      validateBtn_rrf: ["Valider", Validators.required],
      //Ipn vendeur
      label_ipn: ['IPN Vendeur (facultatif)', Validators.required],
      placeholder_ipn: ['Veuillez saisir votre IPN', Validators.required],
      errorMessage_regex_ipn: ['Le champ vendeur n’autorise que 7 caractères alpha-numérique &nbsp', Validators.required],
      //customise preassignedSeller code section
      label_hostesses:['Je suis hôte(esse)', Validators.required],
      label_vendeur:['Je suis vendeur', Validators.required],
      continue_btn:['Continuer', Validators.required],
      //Campaign
      label_campaign: ['Nom de votre campagne (facultatif)', Validators.required],
      placeholder_campaign: ['Veuillez saisir le nom de votre campagne de collecte', Validators.required],
      errorMessage_campaign: ['Le champ campagne doit être en minuscule alpha/num sans espace, autorisation du sépérateur " - " (les autres caractères spéciaux ne sont pas autorisés & nbsp;)', Validators.required],
      //cars interested
      placeholder_brand: ["Choisissez une marque", Validators.required],
      errorMessage_brand: ["Le champ marque doit être saisie", Validators.required],
      placeholder_model: ["Choisissez un modèle", Validators.required],
      errorMessage_model: ["Le champ modèle doit être saisie", Validators.required],
      placeholder_energy: ["Choisissez une energie", Validators.required],
      errorMessage_energy: [" Le champ energie doit être saisie", Validators.required],
      //input Api wired
      choose_dealer: ['Choisissez votre point de vente', Validators.required],
      placeholder_choose_dealer: ['Sélectionnez un affaire', Validators.required],
      error_google_places_input: ['Merci de sélectionnez ville/code postal', Validators.required],
      errorMessage_dealer: ["Ce champ est obligatoire", Validators.required],
      choose_affaire_dealer: ['Sélectionnez votre point de vente', Validators.required],
      errorMessageMandatory_dealer: ["Le dealer est requis", Validators.required],
      errorMessage_cp: ['Veuillez renseigner un code postal à 5 chiffres', Validators.required],
      errorMessageNoresult_cp: ["Le code postal renseigné ne retourne pas de résultat", Validators.required],
      placeholder_cp: ["Code postal", Validators.required],
      placeholder_cp_dealer: ["Veuillez sélectionner le dealer de votre choix", Validators.required],
      errorMessage_selection_dealer: ["La sélection d'un point de vente est obligatoire", Validators.required],
      errorMessage_dealer_config: ["Veuillez vérifier la configuration de l'URL de dealer Locator", Validators.required],
      //toaster messages
      error_event_expire: ["L’évènement est expiré, merci de contacter l’administrateur de CATCHY", Validators.required],
      error_contact_admin: ["L’évènement est en pause, merci de contacter l’administrateur de CATCHY", Validators.required],
      error_code_secret: ["Le code secret est erroné, merci de contacter l’administrateur de CATCHY", Validators.required],
      error_event_pause: ["L’évènement est en pause, merci de contacter l’administrateur de CATCHY", Validators.required],
      form_success: ["Formulaire validé avec succès", Validators.required],
      error_form_submit: ["une erreur est survenue veuillez réessayer ultérieurement", Validators.required],
      form_offline_submit_success: ["Formulaire validé avec succès, vous êtes hors-ligne vos données seront soumises une fois en ligne !", Validators.required],
      error_form_fields: ['Un ou plusieurs champs sont vides ou mal remplies', Validators.required],
      event_not_started_error: ['L’évènement n’a pas commencé ou est terminé, ou le code secret est erroné!', Validators.required]
    });

    this.getTranlstions();
  }
  async getTranlstions() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/translations`, isTokenNotExpired, 'normal').subscribe(res => {
      console.log('res tranlsation', res)
      this.translations = res['translations'];
    })
  }
  onCancel() {
    this.modalRef.hide();
    this.translationForm.reset();
    this.isEdit = false;
  }

  onAddLanguage(templateAdd, type, id) {
    if (id !== null && type == "edit") {
      this.isEdit = true;
      let translationObj = this.translations.filter(t => id === t.id);
      this.idToEdit = translationObj[0].id;
      console.log('')
      this.translationForm.patchValue({ ...translationObj[0].translation_obj });
    }

    if (type == "add") {
      this.translationForm.patchValue({ ...this.translationObjInit });
    }
    this.modalRef = this.modalService.show(templateAdd, this.config);

  }
  get fields() {
    return this.translationForm.controls
  }
  onDeleteLanguage(templateDelete, id) {
    this.languageId = id;
    console.log('id', id)
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });

  }

  async onSubmit() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.datacatchingService.postApi(`${ENV.API_URL}v2/translation`, this.translationForm.getRawValue(), isTokenNotExpired, 'normal').subscribe(res => {
      if (res['success']) {
        this.translations.push(res['translation'])
        this.modalRef.hide();
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    })

  }
  async onDelete() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/translation/${this.languageId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['success']) {
        this.modalRef.hide();
        this.translations = this.translations.filter(t => t.id !== this.languageId);
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message); 
      }
    })
  }
  async onUpdate() {
    let isTokenNotExpired =await this.datacatchingService.TokenValid();
    this.datacatchingService.updateApi(`${ENV.API_URL}v2/translation/${this.idToEdit}`, this.translationForm.getRawValue(), isTokenNotExpired).subscribe(res => {
      if (res['success']) {
        this.modalRef.hide();
        this.getTranlstions();
        this.toastr.success(res.message);
      } else {
        this.toastr.error(res.message);
      }
    })
    
  }
}

import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatacatchingService } from 'src/app/services/datacatching.service';
import { ENV } from '../../core/env.config';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { listLocales } from 'ngx-bootstrap/chronos';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexYAxis,
  ApexGrid,
  ApexTitleSubtitle,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";
import { DateAdapter, MAT_DATE_LOCALE,MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  fill: ApexFill;
  grid: ApexGrid;
  title: ApexTitleSubtitle,
  legend:ApexLegend
}
export type ChartOptions1 = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  title: ApexTitleSubtitle;
  grid: ApexGrid
};
@Component({
  selector: 'app-lead-timing',
  templateUrl: './lead-timing.component.html',
  styleUrls: ['./lead-timing.component.css']
})
export class LeadTimingComponent implements OnInit {
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @ViewChild("chart1") chart1: ChartComponent;
  public chartOptions1: Partial<ChartOptions1>;
  public sfdc_url = ENV.SF_URL;
  public listLeads: any = [];
  public listLeadsTemp: any = [];
  public p = 1;
  public searchTerm = "";
  public lead_id = 0;
  public isMoreinfo: boolean = false;
  public leadObj;
  public isLoading: boolean = false;
  public attributesList = [];
  public parentKeysList = [];
  public selectedLead;
  public formsInputs;
  public userForm: UntypedFormGroup = this.fb.group({});
  public formLablesList;
  public modalRef?: BsModalRef;
  public tempLeads: any = [];
  total: number;
  itemsPerPage: number;
  field: string = 'leadSubmissionTimestamp';
  order: string = 'DESC';
  dateValue = '';
  bsRangeValue;

  locale = 'fr';
  locales = listLocales();
  leadId;
  isAllLeadsChecked: boolean = false;
  dateTempo:string;
  minDateTempo;
  minDateError: boolean = false;
  isMoreThanOneChecked: boolean = false;
 checkedMoreList: any = [];
   minDate = this.transformDates(new Date(new Date().setMonth(new Date().getMonth() - 1)));
   maxDate = this.transformDates(new Date())
   leadTotalCount = 0;
   leadStartDate;
   leadEndDate;
   minDateLead: Date;
   maxDateLead: Date;
   isCollapsed = true;
   initalDates = [];
  config: PerfectScrollbarConfigInterface = {};
  bsRangeGraphValue;
  range = new FormGroup({
   start: new FormControl<Date | null>(new Date(new Date().setMonth(new Date().getMonth() - 1))),
   end: new FormControl<Date | null>(new Date()),
 });
   
  constructor(private toastr: ToastrService,private localeService: BsLocaleService, public fb: UntypedFormBuilder, private datacatchingService: DatacatchingService, private modalService: BsModalService, private datePipe: DatePipe) {
    this.localeService.use(this.locale);
    this.minDateTempo = new Date().toISOString().substring(0, 16);
    this.minDateLead = new Date();
    this.maxDateLead = new Date();
    this.minDateLead.setDate(this.minDateLead.getDate() - 30);
    this.maxDateLead.setDate(this.maxDateLead.getDate());
   }
  onDateTempoChange(date) {
    if (new Date(date).getTime() < new Date().getTime()) {
      this.minDateError = true;
    } else {
      this.minDateError = false;
      this.dateTempo = date;
    }
    
  }

  sortBy(field, orderType) {
    this.field = field;
    this.order = orderType;
    this.modifyLeads(this.p, this.field, this.order);

  }
  onDateChange($event) {
    this.dateValue = $event.target.value;
    this.modifyLeads(1, this.field, this.order);

  }

  pageChanged(pageNum) {
    this.p = pageNum;
    this.modifyLeads(pageNum, this.field, this.order);
  }
  filterEvents() {
    this.modifyLeads(1, this.field, this.order);
  }

  clearFilter() {
    this.searchTerm = '';
    this.modifyLeads(1, this.field, this.order);
  }
  clearDateRange() {
    this.bsRangeValue = '';
    this.minDate = '';
    this.maxDate = '';
    this.modifyLeads(1, this.field, this.order);
  }
  getDate(myDate) {
    return myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
  }

  onValueChange(value: Date): void {
    console.log('bsRangeValue;', this.bsRangeValue);
    if (this.bsRangeValue !== undefined && this.bsRangeValue[0] !== undefined && this.bsRangeValue[1] !== undefined) {
      this.minDate = this.getDate(new Date(this.bsRangeValue[0]));
      this.maxDate = this.getDate(new Date(this.bsRangeValue[1]));
      this.modifyLeads(1, this.field, this.order);
    }

  }
  transformDates(date) {
    return new Date(new Date(date).toUTCString()).toISOString().split("T")[0];
  }
  

 async  modifyLeads(page: number, field: string, order: string) { 
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.listLeads = [];
    this.isLoading = true;
    this.listLeadsTemp = [];
    
    let url = `${ENV.API_URL}v2/leads/lead_tempo?field=${field}&order=${order}&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8`;
    if (this.searchTerm !== '' && this.searchTerm !== null) {
      url = `${ENV.API_URL}v2/leads/lead_tempo?field=${field}&order=${order}&page=${page}&minDate=${this.minDate}&maxDate=${this.maxDate}&size=8&term=${this.searchTerm}`;
    }
    
   this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(leads => {
      this.listLeadsTemp = leads['leads'];
      this.tempLeads = leads['leads'];
      this.total = leads['totalItems'];
      this.itemsPerPage = leads['itemsPerPage'];
      this.p = leads['currentPage'];
      if (!this.listLeadsTemp) this.isLoading = false;

      if (this.listLeadsTemp && this.listLeadsTemp.length > 0) { 
        var i = 1;
        for (let lead of this.listLeadsTemp) {
          var leadObj = new Object();
          leadObj['number'] = JSON.stringify((page - 1) * 8 + i);
          leadObj['id'] = lead.id;
          leadObj['creationDate'] = lead?.form_obj.leadInfo?.leadSubmissionTimestamp ? this.datePipe.transform(lead.form_obj.leadInfo.leadSubmissionTimestamp, 'medium') : null;
          leadObj['creationDateTempo'] = lead.date_tempo ? lead.date_tempo : null;
          leadObj['lastName'] = lead.form_obj.client.lastName ? lead.form_obj.client.lastName : null;
          leadObj['firstName'] = lead.form_obj.client.firstName ? lead.form_obj.client.firstName : null;
          leadObj['mobilePhone'] = lead.form_obj.client.mobilePhone ? lead.form_obj.client.mobilePhone : null;
          leadObj['status'] = 'En cours';
          leadObj['event_name'] = lead.event_name ? lead.event_name : null;
          this.listLeads.push(leadObj);
          i++;
        }
      }
      this.isLoading = false;
    })

  }
  doCallsChartsByDate() {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    this.datacatchingService.getApi(`${ENV.API_URL}v2/leads-tempo/charts`, isTokenNotExpired, 'normal').subscribe(res => {
      this.initalDates = [];
      this.leadEndDate = res.results.todayDate;
      this.leadStartDate = res.results.dateMonthBefore;
      this.initalDates.push( res.results.dateMonthBefore);
      this.initalDates.push(res.results.todayDate);
      this.leadTotalCount = res && res.results && res.results.totalCount ? res.results.totalCount : 0;
      let dates = res.results.datesList.map(d => this.datePipe.transform(d, 'dd/MM'))
      this.leadStartDate = res.results.datesList[0];
      this.leadEndDate = res.results.datesList[res.results.datesList.length - 1]
      console.log('doCallsChartsByDate', res);
      this.chartOptions1 = {
        series: [
          {
            name: "leads",
            data: res && res.results && res.results.numberOfLeadsPerDate ? res.results.numberOfLeadsPerDate : []
          }
        ],
        chart: {
          height: 300,
          type: "bar",
          foreColor: '#fff',
          background: '#44a149',
          toolbar: {
            show: false,
          }
        },
        grid: {
          show: false
        },
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            },

            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#fff'
              }]
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "";
          },
        
          offsetY: -15,
          style: {
            fontSize: "11px",
            fontWeight: '400',
            colors: ["#fff"]
          },

        },

        xaxis: {
          labels: {
            rotate: 0,
            offsetY: 0,

            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 300,
              cssClass: 'apexcharts-xaxis-label',
            },
          },
          categories: dates ? dates : [],
          position: "bottom",
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: "gradient",
              gradient: {
                colorFrom: "#fff",
                colorTo: "#fff",
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5
              }
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        fill: {
          colors: ["#fff8ffcc", "#fff8ffcc"],
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: ["#fff8ffcc", "#fff8ffcc"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + '';
            }
          }
        },
        title: {
          text: `Nombre de leads générés entre ${this.datePipe.transform(res.results.datesList[0], 'dd-MM-yyyy')} et ${this.datePipe.transform(res.results.datesList[res.results.datesList.length - 1], 'dd-MM-yyyy')}`,
          margin: 20,
          align: "center",
          style: {
            color: "#fff8ffcc",
            fontSize: "12px",
            fontWeight: "400"
          }
        },
      }

    })
    
  }
  doCallsChartsForEvent(minDateGraph, maxDateGraph) {
    let isTokenNotExpired = this.datacatchingService.TokenValid();
    minDateGraph == "" ? this.leadStartDate = this.initalDates[0] :  this.leadStartDate = minDateGraph;
    maxDateGraph == "" ? this.leadEndDate = this.initalDates[1] : this.leadEndDate = maxDateGraph;
    let url = `${ENV.API_URL}v2/leads-tempo/charts/events-month?minDateGraph=${minDateGraph}&maxDateGraph=${maxDateGraph}`;
    this.datacatchingService.getApi(url, isTokenNotExpired, 'normal').subscribe(res => {
      console.log(' doCallsChartsForEvent', res);
      let data = res.results.eventsCountMonth.map(ev => ev.count.toString());
      let lables = res.results.eventsCountMonth.map(ev => ev.event_name);
      // second graph
      this.chartOptions = {
        series: [
          {
            name: "leads",
            data: data,
            color: '#fff'
          }
        ],

        chart: {
          type: "bar",
          height: 315,
          background: '#27a9b9',
          foreColor: '#fff',
          toolbar: {
            show: false,
          }
        },

        fill: {
          colors: ["#fff8ffcc", "#fff8ffcc"],
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.25,
            gradientToColors: ["#fff8ffcc", "#fff8ffcc"],
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100]
          }
        },

        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top", // top, center, bottomoffsetY: -20,
            },

            barHeight:  "25%",
            colors: {
              ranges: [{
                from: 0,
                to: 0,
                color: '#000'
              }]
            }
          }

        },
        dataLabels: {
          enabled: true,
          textAnchor: 'end',
          offsetX: 20,
          style: {
            fontSize: '11px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: '400',
            colors: ["#000"]
          }
        },
        xaxis: {
          categories: lables,
          range: 5,
          decimalsInFloat: 0,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            show: true,
            trim: true,
            style: {
              colors: [],
              fontSize: '0',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          tooltip: {
            enabled: true,
            offsetY: -35
          }
        },
        yaxis: {
          floating: true,
          decimalsInFloat: 0,
          axisBorder: {
            show: false
          },
          labels: {
            show: false,
            style: {
              colors: [],
              fontSize: '10px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },

          },
          tooltip: {
            enabled: true,

          }

        },
        grid: {
          show: false
        }

      }
    })
    
  }
  ngOnInit() {
    this.doCallsChartsByDate();
    this.doCallsChartsForEvent("", "");
    this.modifyLeads(1, this.field, this.order);
  }

  truncate(input) {
    return input.length > 15 ? `${input.substring(0, 15)}...` : input;
  } 
  onValueChangeGraph($event) {
    if (this.bsRangeGraphValue !== undefined && this.bsRangeGraphValue[0] !== undefined && this.bsRangeGraphValue[1] !== undefined) {
      let minDate = this.getDate(new Date(this.bsRangeGraphValue[0]));
      let maxDate = this.getDate(new Date(this.bsRangeGraphValue[1]));
      this.doCallsChartsForEvent(minDate, maxDate);
    }
    
  }


  moreInfo(id) {
    console.log('id', id);
    this.lead_id = id;
    let lead = this.listLeadsTemp.filter(l => l.id == id);
    console.log(lead);
    this.leadObj = lead[0];
    this.isMoreinfo = true;

  }
  checkAll(ev) {
    this.isAllLeadsChecked = ev.target.checked;
    this.isMoreThanOneChecked = ev.target.checked;
    for (let lead of this.listLeads) {
      lead.state = ev.target.checked;
      if (ev.target.checked) this.checkedMoreList.push(lead.id);
      if (!ev.target.checked) this.checkedMoreList = this.checkedMoreList.filter(i => i !== lead.id);
    }
  }
  checkLead(ev, id) {
    if (ev.target.checked) {
      this.checkedMoreList.push(id)
    }
    if (!ev.target.checked) {
      this.checkedMoreList = this.checkedMoreList.filter(i => i !== id);
    }
    if (this.checkedMoreList.length > 0) {
      this.isMoreThanOneChecked = true
    }
    if (this.checkedMoreList.length < 1) {
      this.isMoreThanOneChecked = false
    }
    for (let lead of this.listLeads) {
      if (lead.id === id) {
        lead.state = ev.target.checked;
      }
    }
  }



  
  async DeleteAllLeads() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let requestsList = [];
    let leadsList = this.listLeads.filter(lead => lead.hasOwnProperty('state') && lead.state === true);
    console.log("leadsList", leadsList);
 
    for (let lead of leadsList) {
      let url = `${ENV.API_URL}lead_tempo/delete/${lead.id}`;
      requestsList.push(this.datacatchingService.deleteApi(url, isTokenNotExpired, 'normal'));
    }
    forkJoin(requestsList).subscribe(responsList => {
      for (let res of responsList) {
        if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
        if (res['result']['rowCount'] === 1) { 
          this.listLeads = this.listLeads.filter(lead => lead.id_db !== res['id']);
        }
      }
      this.isAllLeadsChecked = false;
      this.isMoreThanOneChecked = false;
      this.checkedMoreList = [];
      this.modifyLeads(this.p, this.field, this.order);

      this.toastr.success("Les leads a été supprimé avec succès");
    });
  }


  gotoSalesforce() {

  }

  changeDate(d) {
    if (d != null && d != undefined) {
      let splittedDate = d.split("T").join(' ');
      let dt = splittedDate.split(" ");
      let justDate = dt[0];
      let justDateSplitted = justDate.split("-");
      let date1 = splittedDate.slice(0, splittedDate.lastIndexOf("."));
      return date1;
    }

  }

  changeFormateDate(d) {

    let formatedDate;
    let date = d.split('T');
    let removeZone = date[1].split(".");
    formatedDate = date[0] + " " + removeZone[0];
    return formatedDate;
  }

  async openModalInfo(templateEdit, id) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    let lead = this.tempLeads.filter(l => l.id == id);
 
    this.selectedLead = lead[0];
    this.leadObj = lead[0];
    let formGroupAttributes = {}
    this.parentKeysList = [];
    this.attributesList = [];
    this.formsInputs = [];
    this.formLablesList = [];
    let url = `${ENV.API_URL}v2/events/event_by_event_name`;
    let body = {
      eventName:this.selectedLead['event_name']
    }
    let res = await this.datacatchingService.postApi(url,body, isTokenNotExpired, 'normal').toPromise();
    let attributes = res['attributes'];
    console.log('attributes', attributes);
    if (attributes) {
      for (let attr of attributes) {
        if (attr.hasOwnProperty('formControlName') && attr.leadRequired !== null && attr.leadRequired !== '') {
          this.formLablesList.push({
            parentKey: attr.leadRequired, formControlLabel: attr.label, formControlName: attr.formControlName
          })
        }
      }
      if (this.formLablesList.length) {
        for (let [key, value] of Object.entries(this.leadObj.form_obj)) {
          let parentKey = key;
          this.parentKeysList.push(key);
          if (Object.keys(key).length !== 0) {
            for (let [childKey, childValue] of Object.entries(value)) {
              if (typeof childValue !== 'object') {
                Object.assign(formGroupAttributes, { [`${[key]}_${[childKey]}`]: childValue });
                this.attributesList.push({ [`${[key]} ${[childKey]}`]: childValue });
                let label = this.formLablesList.filter(label => label.formControlName == childKey);
                this.formsInputs.push({ formControlName: `${[childKey]}`, label: label.length ? label[0].formControlLabel : childKey, parentKey: parentKey, value: childValue })
              } else {
               
                if (childValue !== null && Object.keys(childValue).length > 0) {
                  console.log('child Value', childValue)
                  for (let [grandChildKey, grandChildValue] of Object.entries(childValue)) {
                    Object.assign(formGroupAttributes, { [`${[childKey]}_${[grandChildKey]}`]: grandChildValue });
                    this.attributesList.push({ [`${[childKey]} ${[grandChildKey]}`]: grandChildValue });
                  }
                }
              }
            }
          }
        }
        this.modalRef = this.modalService.show(templateEdit, { class: 'modal-lg', animated: false });
      }
    }
  }
  onDeleteLead(templateDelete, id) {
    this.leadId = id;
    this.modalRef = this.modalService.show(templateDelete, { class: 'modal-sm', animated: false });
  }
  async onDelete() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.datacatchingService.deleteApi(`${ENV.API_URL}v2/leads/lead_tempo/delete/${this.leadId}`, isTokenNotExpired, 'normal').subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['result']['rowCount'] === 1) { 
        this.modifyLeads(this.p, this.field, this.order);
        this.modalRef.hide();
        this.toastr.success("Lead a été supprimé avec succès");
      
      }
    })
    
  }

  async openModalEditLead(templateEdit, id) {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    this.userForm = this.fb.group({});
    let lead = this.tempLeads.filter(l => l.id == id);
    console.log("lead", lead);
    
    this.selectedLead = lead[0];
    console.log('lead', lead);
    this.dateTempo = new Date(lead[0].date_tempo).toISOString().slice(0, 16);
    this.leadObj = lead[0];
    let formGroupAttributes = {}
    this.parentKeysList = [];
    this.attributesList = [];
    this.formsInputs = [];
    this.formLablesList = [];
    let url = `${ENV.API_URL}v2/events/event_by_event_name`;
    let body = {
      eventName:this.selectedLead['event_name']
    }
    let res = await this.datacatchingService.postApi(url,body, isTokenNotExpired, 'normal').toPromise();
    let attributes = res['attributes'];
    if (attributes) {
      for (let attr of attributes) {
        if (attr.hasOwnProperty('formControlName') && attr.leadRequired !== null && attr.leadRequired !== '') {
          this.formLablesList.push({
            parentKey: attr.leadRequired, formControlLabel: attr.label, formControlName: attr.formControlName
          })
        }
      }
      if (this.formLablesList.length) {
        for (let [key, value] of Object.entries(this.leadObj.form_obj)) {
          let parentKey = key;
          this.parentKeysList.push(key);
          if (Object.keys(key).length !== 0) {
            for (let [childKey, childValue] of Object.entries(value)) {
              if (typeof childValue !== 'object') {
                Object.assign(formGroupAttributes, { [`${[key]}_${[childKey]}`]: childValue });
                this.attributesList.push({ [`${[key]} ${[childKey]}`]: childValue });
                let label = this.formLablesList.filter(label => label.formControlName == childKey);
                this.formsInputs.push({ formControlName: `${[childKey]}`, label: label.length ? label[0].formControlLabel : childKey, parentKey: parentKey, value: childValue })
                this.userForm.addControl(childKey, new UntypedFormControl(childValue));
              } else {
                if (childValue !== null && Object.keys(childValue).length > 0) {
                  for (let [grandChildKey, grandChildValue] of Object.entries(childValue)) {
                    Object.assign(formGroupAttributes, { [`${[childKey]}_${[grandChildKey]}`]: grandChildValue });
                    this.attributesList.push({ [`${[childKey]} ${[grandChildKey]}`]: grandChildValue });
                    this.userForm.addControl(grandChildKey, new UntypedFormControl(grandChildValue));
                    let label = this.formLablesList.filter(label => label.formControlName == grandChildKey);
                    this.formsInputs.push({ formControlName: `${[grandChildKey]}`, label: label.length ? label[0].formControlLabel : grandChildKey, parentKey: label[0].parentKey, value: grandChildValue })
                  }
                }
              }
            }
          }
        }
        this.userForm.patchValue(formGroupAttributes);
        this.modalRef = this.modalService.show(templateEdit, { class: 'modal-lg', animated: false });
      }
    }
  }
  async onSubmit() {
    let isTokenNotExpired = await this.datacatchingService.TokenValid();
    // this.isSendLead = true;
    let arr = [];
    let leadObj = {};
    for (let [key, value] of Object.entries(this.userForm.getRawValue())) {
      arr.push({ formControlName: `${[key]}`, value: value });
    }
    for (let v of arr) {
      for (let ip of this.formsInputs) {
        if (ip.formControlName === v.formControlName) {
          ip.value = v.value
        }
      }
    }
    for (let parentKey of this.parentKeysList) {
      let tempArr = [];
      tempArr = this.formsInputs.filter(fI => fI.parentKey === parentKey || fI.parentKey.includes(parentKey));
      if (tempArr.length) {
        let keyValues = []
        for (let val of tempArr) {
          if (!val.parentKey.includes(".")) {
            let arr = [val.formControlName, val.value];
            keyValues.push(arr);
          } else {
            let nestedKey = val.parentKey.split(".");
            let arr = [`${nestedKey[1]}.${val.formControlName}`, val.value];
            keyValues.push(arr);
          }
        }
        let obj = {};
        obj = Object.fromEntries(keyValues);
        for (const [key, value] of Object.entries(obj)) {
          let innerObj = {}
          if (key.includes(".")) {
            let splittedKey = key.split(".");
            innerObj[`${splittedKey[0]}`] = {
              [`${splittedKey[1]}`]: value
            }
            delete obj[key];
            Object.assign(obj, innerObj)
          }
        }
        leadObj[parentKey] = obj;
      }
    }
    console.log("dateTempo", this.dateTempo);
    let requestBody = {
      lead: leadObj,
      event_name: this.selectedLead['event_name'],
      cts: leadObj['leadInfo']['leadSubmissionTimestamp'],
      lead_from: 'app',
      id: this.selectedLead['id']
    }
    this.datacatchingService.updateApi(`${ENV.API_URL}v2/leads/lead_tempo/update`, { form_obj: leadObj, event_id: this.selectedLead['id'], dateTempo: `${this.dateTempo}:00.000Z` }, isTokenNotExpired).subscribe(res => {
      if (res['access_token'] !== null && res['access_token'] !== undefined) sessionStorage.setItem('access_token', res['access_token'])
      if (res['result']['rowCount'] === 1) {
        this.isAllLeadsChecked = false;
        this.modifyLeads(this.p, this.field, this.order);
        this.modalRef.hide();
        this.toastr.success("Lead a été mis à jour avec succès");
      }
    }) 
  }
  checkKey(key) {
    let list = this.formsInputs.filter(formInput => formInput.parentKey === key);
    return list.length ? false : true;
  }
 
  clearDateGraphRange() {
    this.bsRangeGraphValue = '';
    this.doCallsChartsForEvent("", "");
    
  }

}

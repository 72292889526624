import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA, } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsComponent } from './components/event/event.component';
import { NavComponent } from './components/nav/nav.component';
import { UserComponent } from './components/user/user.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DndModule } from 'ngx-drag-drop';
import { EventComponent } from './components/create-event/create-event.component';

import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard';
import { BaseComponent } from './components/base/base.component';
import { AgmCoreModule } from '@agm/core';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DatacatchingService } from './services/datacatching.service';
import { IndexeDBService } from './services/indexe-db.service';
import { LeadComponent } from './components/lead/lead.component';
import { ErrorComponent } from './components/error/error.component';
import { NotFoundComponent } from './components/not-found/not-found.component'
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { ModalComponent } from './components/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CallbackComponent } from './components/auth/callback/callback.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guard/auth.guard';
// import { FileService } from './services/file.service';
import { QuillModule } from 'ngx-quill';
import { DatePipe } from '@angular/common';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { SortDirective } from './directive/sort.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SearchPipe } from '../app/components/lead/search.pipe';
import { SearchTermPipe } from './components/event/seachTerm.pipe';
import { SearchTimingPipe } from '../app/components/lead-timing/search.timing.pipe';
import { LeadTimingComponent } from './components/lead-timing/lead-timing.component';
import { LeadErrorComponent } from './components/lead-error/lead-error.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
registerLocaleData(localeFr, 'fr');
import { defineLocale } from 'ngx-bootstrap/chronos';
import { frLocale } from 'ngx-bootstrap/locale';
import { TemplateComponent } from './components/template/template.component';
import { MailAlertComponent } from './components/mail-alert/mail-alert.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { HistoryComponent } from './components/history/history.component';
import { ActionEventHistoryComponent } from './components/action-event-history/action-event-history.component';
import { NgApexchartsModule } from "ng-apexcharts";
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslationComponent } from './components/translation/translation.component';
import { TranslationRegexComponent } from './components/translation-regex/translation-regex.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { UiSwitchModule } from 'ngx-ui-switch';
import { CharteComponent } from './components/charte/charte.component';
import { CountryComponent } from './components/country/country.component';
import { MaxLengthDirective } from './directives/max-length.directive';
import { NgxColorsModule } from 'ngx-colors';
defineLocale('fr', frLocale);

const ngWizardConfig: NgWizardConfig = {
  theme: THEME.default
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FormsComponent,
    NavComponent,
    UserComponent,
    EventComponent,
    BaseComponent,
    LeadComponent,
    ErrorComponent,
    NotFoundComponent,
    ModalComponent,
    CallbackComponent,
    SortDirective,
    SearchPipe,
    SearchTermPipe,
    LeadTimingComponent,
    SearchTimingPipe,
    LeadErrorComponent,
    TemplateComponent,
    MailAlertComponent,
    HistoryComponent,
    ActionEventHistoryComponent,
    TranslationComponent,
    TranslationRegexComponent,
    CharteComponent,
    CountryComponent,
    MaxLengthDirective
    // FileService
  ],
  imports: [
    BrowserModule,
    NgxPaginationModule,
    FilterPipeModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DndModule,
    NgxExtendedPdfViewerModule,
    BrowserAnimationsModule,
    PdfViewerModule,
    MatDatepickerModule,
    TypeaheadModule.forRoot(),
    ToastrModule.forRoot(),
    NgWizardModule.forRoot(ngWizardConfig),
    NgMultiSelectDropDownModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_YDRdNqIRICKSkZEtGKahd-r5MnZpVoc',
      libraries: ['places']
    }),
    MatAutocompleteModule,
    ScrollingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    NgbModule,
    QuillModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    NgApexchartsModule,
    PerfectScrollbarModule,
    UiSwitchModule,
    NgxColorsModule
    
  ],
  providers: [DatacatchingService, IndexeDBService, AuthService,
    AuthGuard, DatePipe, { provide: LOCALE_ID, useValue: "fr-FR" }, {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    // { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
  ],
  schemas: [
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
